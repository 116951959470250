import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

// @ts-ignore
describe('Desktop - Authenticated companies overview tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Opens admin companies overview page with grid parameters in URL on correct page with correct sorting', () => {
        cy.intercept('POST', '/api/Companies/GetCompaniesPagedList').as('pagedCompanies');
        cy.visit(`${Localizer.pageRoutesAdminCompaniesOverview}?pageNumber=4&sortColumn=vatId&sortDir=1`);

        cy.waitSuccess('@pagedCompanies');

        cy.get('th[data-sort-direction="1"]')
            .find('div > span')
            .should('have.text', Localizer.companiesDetailVatId);

        cy.get('.page-item.active')
            .find('a')
            .should('have.text', '4');
    });

    it('Opens admin companies overview page - Test filters', () => {
        executeWithIntercept(() => cy.visit(Localizer.pageRoutesAdminCompaniesOverview),
            [pageData().admin.companies.routes.getCompaniesPagedList as RouteData]);

        pageData().admin.companies.filters.showOnlyDeletedCheckBox().click();

        GridHelper.getGridVisibleRows(pageData().admin.companies.tableName()).should('have.length', 1)
        cy.get(".athenaeum-grid-emptyRow").should('have.text', Localizer.componentDropdownNoData)

        cy.get('.page-item.active')
            .find('a')
            .should('have.text', '1');
    });
});