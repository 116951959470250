import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {Button, ButtonType, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import styles from "./EasyPlus.module.scss";
import EasyPlusFeatureMarketing from "@/components/EasyPlusFeatureMarketing/EasyPlusFeatureMarketing";


interface IEasyPlusProps {
}

interface IEasyPlusState {
}

export default class EasyPlus extends AnonymousPage<IEasyPlusProps, IEasyPlusState> {
    state: IEasyPlusState = {};

    protected get title(): string {
        return "easy+";
    }

    private async redirectToEasyPlusPageAsync():Promise<void>{

    }

    public render(): React.ReactNode {
        return (
            <PageContainer hasWideHeader className={styles.easyPlus}>

                <PageHeader wideHeader
                            className="m-0 pt-0"
                            title={this.getTitle()}
                            wideHeaderBackgroundImage="/images/renta-kuva-5-scaled.jpg"
                />

                <PageRow>
                    <div className="col">
                        <div className={styles.headlineContainer}>
                            <h2>Revolutionize your construction projects with the power of telematics</h2>
                            <h3>By implementing telematics technology, you gain real-time visibility into your
                                fleet, equipment, adn workforce, enabling you to streamline operations, reduce cost, and increase productivity
                            </h3>
                            <Button label={"Order now"}
                                    type={ButtonType.Orange}
                                    onClick={async ()=> await this.redirectToEasyPlusPageAsync()}
                            />

                        </div>
                        <div className={"row"}>
                        </div>

                        <div className={styles.featuresMarketingContainer}>

                            <EasyPlusFeatureMarketing title={"Advanced Telematics"}
                                                      subtitle={"Improved equipment management"}
                                                      text={"Telematics can help monitor fuel consumption and identify areas where improvements can be made such as reducing idle time."}
                                                      icon={'/images/Renta-IOT-gateway-icon.svg'}/>

                            <EasyPlusFeatureMarketing title={"Advanced Telematics"}
                                                      subtitle={"Improved equipment management"}
                                                      text={"Telematics can help monitor fuel consumption and identify areas where improvements can be made such as reducing idle time."}
                                                      icon={'/images/Renta-IOT-gateway-icon.svg'}/>

                            <EasyPlusFeatureMarketing title={"Advanced Telematics"}
                                                      subtitle={"Improved equipment management"}
                                                      text={"Telematics can help monitor fuel consumption and identify areas where improvements can be made such as reducing idle time."}
                                                      icon={'/images/Renta-IOT-gateway-icon.svg'}/>

                            <EasyPlusFeatureMarketing title={"Advanced Telematics"}
                                                      subtitle={"Improved equipment management"}
                                                      text={"Telematics can help monitor fuel consumption and identify areas where improvements can be made such as reducing idle time."}
                                                      icon={'/images/Renta-IOT-gateway-icon.svg'}/>
                        </div>
                    </div>
                </PageRow>

            </PageContainer>
        );
    }


}