import { ApiProvider } from "@renta-apps/athenaeum-react-common";
import { ConstructionSiteInfo } from "@/models/server/ConstructionSiteInfo";
import { ConstructionSiteDetailsResponse } from "@/models/server/Responses/ConstructionSiteDetailsResponse";
import queryString from "query-string";
import {ConstructionSiteName} from "@/models/server/ConstructionSiteName";
import {ServiceType} from "@/models/Enums";
import {IEquipmentPageData, IEquipmentRequest, IResponse} from "@/pages/ConstructionSiteDetails/Equipments/types";
import CreateConstructionSiteServiceRequestRequest from "@/models/server/Requests/CreateConstructionSiteServiceRequestRequest";
import ReturnModel from "@/models/server/ReturnModel";
import InviteUserPostRequest from "@/models/server/InviteUserPostRequest";
import LogDocumentEvent from "@/models/server/LogDocumentEvent";

export const getConstructionSiteDetails = async (constructionSiteId: string): Promise<ConstructionSiteDetailsResponse> => {
    return ApiProvider.postAsync<ConstructionSiteDetailsResponse>("/api/ConstructionSites/GetConstructionSiteDetails", constructionSiteId, null);
};

export const getConstructionSiteInfos = async (contractId: string): Promise<ConstructionSiteInfo[]> => {
    return ApiProvider.postAsync<ConstructionSiteInfo[]>("/api/ConstructionSites/GetConstructionSiteInfos", contractId, null);
};

export const searchConstructionSiteNames = async (searchTerm: string, contractId: string, searchLimit: number = 50): Promise<ConstructionSiteName[]> => {
    const query = queryString.stringify({searchTerm, searchLimit, contractId});
    return ApiProvider.getAsync<ConstructionSiteName[]>(`/api/ConstructionSites/SearchConstructionSiteNames?${query}`, null);
};

export const getServiceRequestDefinitionId = async (serviceType: ServiceType): Promise<string> => {
    return ApiProvider.postAsync<string>(`/api/ConstructionSites/GetServiceDefinitionId`, serviceType, null);
};

export const getConstructionSiteEquipment = async (contractId: string | null): Promise<IEquipmentPageData> => {
    return ApiProvider.postAsync<IEquipmentPageData>("/api/ConstructionSites/Equipment", contractId, null);
};

export const getProductEquipment = async (request: IEquipmentRequest): Promise<IEquipmentPageData> => {
    return ApiProvider.postAsync<IEquipmentPageData>("/api/Product/Equipment", request, null);
};

export const createConstructionSiteServiceRequest = async (request: CreateConstructionSiteServiceRequestRequest): Promise<void> => {
    return ApiProvider.postAsync<void>("/api/Services/CreateConstructionSiteServiceRequest", request, null);
};

export const logDocumentEvent = async (request: LogDocumentEvent): Promise <void> => {
    return ApiProvider.postAsync<void>("/api/ConstructionSites/LogDocumentEvent", request, null);
}

export const postReturnProduct = async (returnModel: ReturnModel): Promise<void> => {
    return ApiProvider.postAsync<void>("/api/ConstructionSites/ReturnProduct", returnModel, null);
};

export const postInviteUser = async (inviteUserRequest: InviteUserPostRequest): Promise<IResponse> => {
    return ApiProvider.postAsync<IResponse>("/api/Users/InviteUser", inviteUserRequest, null);
};