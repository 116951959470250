import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import SaveProductInfoRequest from "@/models/server/SaveProductInfoRequest";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import DeleteProductInfoRequest from "@/models/server/DeleteProductInfoRequest";
import GetMachineModelDocumentRequest from "@/models/server/Requests/GetMachineModelDocumentRequest";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import FileApiModel from "@/models/server/FileApiModel";
import AddMachineModelDocumentsRequest from "@/models/server/Requests/AddMachineModelDocumentsRequest";
import IProductDetailsData, {IProductDetailsAvailability} from "@/models/server/ProductDetails";
import ProductModel from "@/models/server/ProductModel";

const productDetailsServices = {

    fetchProductDataAsync: async (productUrl: string): Promise<IProductDetailsData> => {
        return ApiProvider.postAsync(`/api/Product/ProductData`, productUrl);
    },

    fetchProductAvailabilityAsync: async (productUrl: string): Promise<IProductDetailsAvailability> => {
        return ApiProvider.postAsync(`/api/Product/GetProductAvailability`, productUrl);
    },

    saveProductAsync: async (product: ProductModel): Promise<ProductInfoModel> => {
        return ApiProvider.postAsync(`/api/Admin/SaveProduct`, product);
    },
    
    saveProductInfoAsync: async (productId: string, infoModel: ProductInfoModel): Promise<ProductInfoModel> => {
        const productInfoRequest: SaveProductInfoRequest = {
            productId: productId,
            productInfo: infoModel
        };
        return ApiProvider.postAsync(`/api/Admin/SaveProductInfo`, productInfoRequest);
    },

    deleteProductInfoAsync: async (productId: string, infoModel: ProductInfoModel): Promise<any> => {
        const deleteProductInfoRequest: DeleteProductInfoRequest = {
            productId: productId,
            productInfo: infoModel
        };
        return ApiProvider.postAsync(`/api/Admin/DeleteProductInfo`, deleteProductInfoRequest);
    },

    fetchDocumentAsync: async (productId: string, fileApiModel: FileApiModel, productInfoModelId: string): Promise<FileModel> => {
        const request = new GetMachineModelDocumentRequest();
        request.productId = productId;
        request.productInfoId = productInfoModelId;
        request.documentId = fileApiModel.documentId;

        return ApiProvider.postAsync(`/api/Product/GetDocument`, request);
    },
    
    fetchProductModelDocumentAsync: async (productId: string, fileApiModel: FileApiModel, productInfoModelId: string): Promise<FileModel> => {
        const request = new GetMachineModelDocumentRequest();
        request.productId = productId;
        request.productInfoId = productInfoModelId;
        request.fileId = fileApiModel.id;

        return ApiProvider.postAsync(`/api/Product/GetProductModelDocument`, request);
    },

    uploadMachineModelDocumentAsync: async (productId: string, productInfoId: string, fileReference: string, fileName: string): Promise<FileApiModel> => {
        const request = new AddMachineModelDocumentsRequest();

        request.productId = productId;
        request.ProductInfoId = productInfoId;
        request.fileReference = fileReference;
        request.fileName = fileName;
        
        return ApiProvider.postAsync(`/api/Product/UploadMachineModelDocument`, request);
    },

    deleteProductModelDocumentAsync: async (fileId: string): Promise<any> => {
        return ApiProvider.postAsync(`/api/Product/DeleteProductModelDocument`, fileId);
    },

}

export default productDetailsServices;


