// Add export to fix compilation error TS1208
import Localizer from "@/localization/Localizer";

export {};


describe('Desktop - My account tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session('api_login', () => cy.login(), {});
    });

    it('Open my account and verify page data', () => {
        cy.visit(Localizer.pageRoutesMyAccount);

        cy.get('h1').should('contain', Localizer.myAccountTitle);
    });

});