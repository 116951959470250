import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "@/localization/Localizer";

describe('Desktop - Authenticated construction site details feature flags tests', () => {
    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.visit('/');
    });

    it('Should display all tabs when all flags are enabled', () => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCo2Enabled);

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
            );
        });

        pageData().common.waitTopNavRender();

        pageData().constructionSite.equipment.tab();
        pageData().constructionSite.invoices.tab();
        pageData().constructionSite.users.tab();
        pageData().constructionSite.serviceRequests.tab();
        pageData().constructionSite.environmentControl.tab();
        pageData().constructionSite.emissions.tab();
    });

    it('Should not display environmental and emissions tabs when flags disabled', () => {
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagCo2Enabled);
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagEnvironmentControl);

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
            );
        });
        pageData().common.waitTopNavRender();
        pageData().constructionSite.equipment.tab();
        pageData().constructionSite.invoices.tab();
        pageData().constructionSite.users.tab();
        pageData().constructionSite.serviceRequests.tab();
        pageData().constructionSite.environmentControl.tab().should('not.exist');
        pageData().constructionSite.emissions.tab().should('not.exist');

        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagCo2Enabled);
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagEnvironmentControl);
        cy.reload();
        pageData().common.waitTopNavRender();
    });
});