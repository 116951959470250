import Localizer from "@/localization/Localizer";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Subscribe to alerts tests - admin user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('should display all checkboxes unchecked', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // Click on checkboxes
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .click();

        // Check if the bottom sheet is now visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('be.visible');

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('be.visible');

        // Alert checkboxes
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .should('exist')
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .should('exist')
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .should('exist')
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Notification checkboxes
        pageData().fleetMonitoring.modals.subscribeToAlerts.smsCheckbox()
            .should('exist')
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.emailCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

    });

});

describe('Desktop - Fleet Monitoring - Subscribe to alerts tests - company user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    it('should display subscribe to alerts modal correctly when more than one device is selected', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // Click on checkboxes
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .click();

        // Check if the bottom sheet is now visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('be.visible');

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('be.visible');

        // Modal title
        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .find('h5')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalTitle.toUpperCase());

        // Header texts
        cy.get('span')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextPieceOfEquipment);
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextSelectedEquipment);
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalSubHeaderText);

        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalFuelLow);
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalBatteryLow);
        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalOffHours);
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalWorkHours);
        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalIdle);
        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Alert settings
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalGlobalAlertSettings);
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalChangingSettings);

        // Notification checkboxes
        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveSms);
        pageData().fleetMonitoring.modals.subscribeToAlerts.smsCheckbox()
            .should('exist')
            .verifyDataValue('true');

        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveEmail);
        pageData().fleetMonitoring.modals.subscribeToAlerts.emailCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Action buttons
        cy.get("#subscribeToAlertsModalActionButtons")
            .find('button')
            .should('have.length', 2);

        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .should('contain.text', Localizer.componentModalCancel);

        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .should('contain.text', Localizer.componentModalSaveChanges);

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');
    });

    it('should display subscribe to alerts modal correctly when cog button is clicked', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        DeviceGridHelper.getDeviceGridAngleIcon(8)
            .click();

        // Click on the cog icon
        DeviceGridHelper.getDeviceGridGearIcon(8)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('be.visible');

        // Modal title
        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .find('h5')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalTitle.toUpperCase());

        // Header texts
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextPieceOfEquipment);
        cy.get('span')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalHeaderTextSelectedEquipment);
        cy.get('span')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalSubHeaderText);

        // Data table
        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalFuelLow);
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalBatteryLow);
        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('div')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalOffHours);
        cy.get('span')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalWorkHours);
        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .should('not.exist');

        cy.get('div')
            .should('not.contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalIdle);
        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .should('not.exist');

        // Alert settings
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalGlobalAlertSettings);
        cy.get('span')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalChangingSettings);

        // Notification checkboxes
        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveSms);
        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveEmail);

        // Action buttons
        cy.get("#subscribeToAlertsModalActionButtons")
            .find('button')
            .should('have.length', 2);

        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .should('contain.text', Localizer.componentModalCancel);

        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .should('contain.text', Localizer.componentModalSaveChanges);

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');
    });

    it('should display battery alert as only checked when HINATTAVA is open', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(3)
            .click();

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');
    });

    it('should display fuel and battery alerts as checked when DIESELKUUKULKIJA is open', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(5)
            .click();

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');

    });

    it('should display fuel and battery alerts as partially checked when SÄHKÖTRUKKI and AKKUKUUKULKIJA are open', () => {

        // SÄHKÖTRUKKI and AKKUKUUKULKIJA support both alarm types, but only one them have them active.
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(1)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(6)
            .click();

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('partial');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('partial');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');
    });

    it('should display fuel as checked and battery alerts as partially checked when SÄHKÖTRUKKI and 96840 are open', () => {

        // SÄHKÖTRUKKI support both alarm types, but 96840 only battery.
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(1)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(10)
            .click();

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('partial');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');

        // Close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.cancelButton()
            .trigger('click');
    });

    it('should save new battery alarm for TELESKOOPPIMASTONOSTIN', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Alarm/CreateDeviceBatteryLevelLessThanAlarm', { fixture: 'createDeviceBatteryLevelLessThanAlarm.json' }).as('createAlarmRequest');

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(9)
            .click();

        // Open the modal
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // All alarms are not set.
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('false');

        // Check the battery alarm.
        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted request to be made
        cy.waitSuccess('@createAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('true');
    });

    it('should delete battery alarm and save new fuel alarm for HINATTAVA', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Alarm/CreateDeviceFuelLevelLessThanAlarm', { fixture: 'createDeviceFuelLevelLessThanAlarm.json' }).as('createAlarmRequest');

        cy.intercept('POST', '/api/Alarm/DeleteAlarm', { fixture: 'deleteAlarm.json' }).as('deleteAlarmRequest');

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(3)
            .click();

        // Open the modal
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // Fuel is unchecked, so check it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .click();

        // Battery is checked, so uncheck it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@createAlarmRequest');
        cy.waitSuccess('@deleteAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .verifyDataValue('false');
    });

    it('should create a theft alarm and then delete it for KAIVINKONE', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Alarm/CreateDeviceTheftAlarm', { fixture: 'createDeviceTheftAlarm.json' }).as('createAlarmRequest');

        cy.intercept('POST', '/api/Alarm/DeleteAlarm', { fixture: 'deleteAlarm.json' }).as('deleteAlarmRequest');

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        // Open the modal
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // The theft alarm is unchecked, so check it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@createAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // The theft alarm is checked, so uncheck it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('true');

        // Wait for Chrome, Electron has no issue.
        cy.wait(100);

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@deleteAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .verifyDataValue('false');
    });

    it('should create an idle alarm and then delete it for Volvo SD135B', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Alarm/CreateDeviceUnusedFor3DaysAlarm', { fixture: 'createDeviceIdleAlarm.json' }).as('createAlarmRequest');

        cy.intercept('POST', '/api/Alarm/DeleteAlarm', { fixture: 'deleteAlarm.json' }).as('deleteAlarmRequest');

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        // Open the modal
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // The theft alarm is unchecked, so check it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@createAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        // The theft alarm is checked, so uncheck it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('true');

        // Wait for Chrome, Electron has no issue.
        //cy.wait(150);

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .click();

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@deleteAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Open the modal again
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .verifyDataValue('false');
    });
});

describe.only('Desktop - Fleet Monitoring - Subscribe to alerts tests - company user - no easy plus', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session_no_plus'], () => {
            cy.loginAndSelectCompanyRole("uuno.turhapuro@weare.fi", "tmi jukka wilska");
        });

    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    it.only('should show easy plus info modal when easy plus disabled for user', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.visit('/fleet-monitoring');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        // Open the modal by clicking the adjust alerts button
        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().common.easyPlusInfoModal()
            .should('be.visible');

        // close the modal by clicking outside
        cy.get('body').click(5, 5);

        // Click chevron to see the gear icon
        DeviceGridHelper.getDeviceGridAngleIcon(1)
            .click();

        // Open the modal by clicking the gear icon
        DeviceGridHelper.getDeviceGridGearIcon(1).click();

        pageData().common.easyPlusInfoModal()
            .should('be.visible');
    });
});

describe('Desktop - Fleet Monitoring - Subscribe to alerts tests - construction site user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['construction_site_role_session'], () => {
            cy.loginAndSelectCompanyRole("cs.user@domain.fi", "ConstructionSite1");
        });
    });

    it('should display Email as a prefered notification when open the modal', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // Click on checkboxes
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .click();

        // Check if the bottom sheet is now visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('be.visible');

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts)
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('be.visible');

        // Notification checkboxes
        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveSms);
        pageData().fleetMonitoring.modals.subscribeToAlerts.smsCheckbox()
            .should('exist')
            .verifyDataValue('false');

        cy.get('label')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalReceiveEmail);
        pageData().fleetMonitoring.modals.subscribeToAlerts.emailCheckbox()
            .should('exist')
            .verifyDataValue('true');

        // Invalid phone number text.
        cy.get('div')
            .should('contain.text', Localizer.fleetMonitoringPageSubscribeToAlertsModalInvalidPhoneNumber);
        pageData().fleetMonitoring.modals.subscribeToAlerts.smsCheckbox()
            .children('div')
            .eq(0)
            .should('have.attr', 'disabled');
    });
});