import Localizer from "../../../localization/Localizer";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";

describe('Desktop - Authenticated admin user edit products', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        onRedirect(() =>
            pageData().rent.visitWithIntercept()
        );

    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    const searchTerm: string = 'nostin';

    it("Search products and sets edit mode for searched products", () => {
        const productId = '83a700be-2e3b-45cc-ac8a-570a551760d4';

        cy.assertBreadCrumbLength(2);

        executeWithIntercept(() => pageData().rent.searchInput().type(`${searchTerm}{enter}`),
            [pageData().rent.routes.categoriesData as RouteData]);

        pageData().rent.productLink(productId);

        // Open edit mode
        pageData().rent
            .editButton()
            .click();

        pageData().rent.editProducts.relatedProductsEdit(productId);

        pageData().rent
            .editButton()
            .click();

        pageData().rent.productLink(productId);
    });

    it('Navigates to product in sub categories and edits product accessories + validates their order has changed', () => {
        const productId = '2de6cbf1-d427-a83f-9e0b-fd9574f967bf';

        pageData().rent.firstCategoryItem();

        executeWithIntercept(() => cy.get('a').contains('Työkoneet').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        pageData().rent.firstCategoryItem(); //Wait for render

        executeWithIntercept(() => cy.get('a').contains('Pyöräkuormaajat').click(),
            [pageData().rent.routes.categoriesData as RouteData]);


        EditProductAccessories(productId, '20175, 27815,27384');

        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.productLink(productId)
                    .click(),
                [pageData().rent.routes.productsData as RouteData]);
        });

        AssertAccessoryOrderIsEqual('20175,27815,27384');

        onRedirect(() =>
            executeWithIntercept(() => cy.go('back'),
                [pageData().rent.routes.categoriesData as RouteData])
        );

        // Edit related product order to be different
        EditProductAccessories(productId, '27384,20175, 27815');

        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.productLink(productId)
                    .click(),
                [pageData().rent.routes.productsData as RouteData]);
        });

        // Check that order has been changed correctly
        AssertAccessoryOrderIsEqual('27384,20175,27815');
    });

    it('Can change product priority ', () => {
        pageData().rent.visitWithIntercept('akkukayttoiset-nivelpuominostimet');

        pageData().common.waitTopNavRender();

        cy.get(".items-list > div").should('have.length.at.least', 1);

        // Open edit mode
        pageData().rent.editButton()
            .click();

        AssertFirstProductTextInputValue("Ajettava nivelpuominostin, lavakorkeus 10-13 m, akku");
        MoveFirstProductDown();
        AssertFirstProductTextInputValue("Ajettava nivelpuominostin, lavakorkeus 13-15 m, akku");
        MoveFirstProductDown();
        AssertFirstProductTextInputValue("Ajettava nivelpuominostin, lavakorkeus 10-13 m, akku");

    });

    it('Add attached product and check if attached products component is displayed ', () => {
        const productId: string = '83a700be-2e3b-45cc-ac8a-570a551760d4';

        pageData().rent.visitWithIntercept('akkukayttoiset-nivelpuominostimet');

        pageData().common.waitTopNavRender();

        cy.get(".items-list > div").should('have.length.at.least', 1);

        EditAttachedProducts(productId);

        cy.get(`#product_${productId}`).get('#attached_products_container');

        onRedirect(() => {
            executeWithIntercept(() => pageData().rent.productLink(productId).click(),
                [pageData().rent.routes.productsData as RouteData]);
        });

        cy.get('#attached_products_container');
    });

    it('Edit product and validates that product could not be edited', () => {
        const productId: string = '83a700be-2e3b-45cc-ac8a-570a551760d4';
        const rentalObjectNumber = Date.now().getHashCode().toString();
        
        pageData().rent.visitWithIntercept('akkukayttoiset-nivelpuominostimet');

        // Wait until first product is rendered before clicking edit
        cy.get(".items-list > div").should('have.length.at.least', 1);

        pageData().rent.editButton().click();
        
        pageData().rent.editProducts.inputField(productId, 1)
            .clear()
            .type(rentalObjectNumber);

        executeWithIntercept(() => pageData().rent.editProducts.saveButton(productId).click(),
            [pageData().rent.routes.saveProduct as RouteData]);

        cy.get('.alert-warning').should('contain', Localizer.get(Localizer.productManagementFailedAlertInvalidRentalObjectNumber, rentalObjectNumber));
    });

    function EditAttachedProducts(productId: string) {
        // Open edit mode
        pageData().rent.editButton()
            .click();

        pageData().rent.editProducts.addAttachedProductButton(productId)
            .click();

        pageData().rent.editProducts.attachedProductNumberInput(productId, 0)
            .clear()
            .type('20175');

        executeWithIntercept(() => pageData().rent.editProducts.saveButton(productId).click(),
            [
                pageData().rent.routes.saveProduct as RouteData,
                pageData().rent.routes.getAttachedProducts(productId) as RouteData,
            ]);

        pageData().common.alertSuccess();

        // Close edit mode
        pageData().rent.editButton()
            .click();
    }

    function MoveFirstProductDown(): void {
        executeWithIntercept(() => cy.get('.move-down').first().click(),
            [pageData().rent.routes.changeProductPriority as RouteData]);
    }

    function AssertFirstProductTextInputValue(text: string): void {
        cy.get(".items-list > div").find('input').first().should('have.value', text);
    }

    function EditProductAccessories(productId: string, accessories: string) {
        // Open edit mode
        pageData().rent.editButton()
            .click();

        pageData().rent.editProducts.relatedProductsEdit(productId)
            .clear()
            .type(accessories);

        executeWithIntercept(() => pageData().rent.editProducts.saveButton(productId).click(),
            [pageData().rent.routes.saveProduct as RouteData]);

        pageData().common.alertSuccess();

        // Close edit mode
        pageData().rent.editButton()
            .click();
    }

    function AssertAccessoryOrderIsEqual(accessoryString: string) {
        cy.get('#product_accessories_container')
            .find('.swiper-slide')
            .find('#product_card_root')
            .then(($result: any) => {
                let accessories: string[] = [];
                let length: number = $result.length;

                for (let i = 0; i < length; i++) {
                    accessories.push($result[i].dataset.cy);
                }

                let joinedString: string = accessories.join(',');

                expect(accessoryString).to.equal(joinedString);
            });
    }
});