import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {OrderStatus} from "@/models/Enums";
import AdminDeposLiteModel from "@/models/server/AdminDeposLiteModel";
import {Dropdown, DropdownOrderBy, JustifyContent, SelectListItem, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import styles from "@/pages/AdminOrders/AdminOrdersGridToolBar/AdminOrdersGridToolBar.module.scss"
import Localizer from "@/localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";

interface IOrdersGridToolbarProps {
    isReturnsGridToolbar?: boolean,
    locations: AdminDeposLiteModel[],
    currentLocation: AdminDeposLiteModel | null,
    onChangeState: (item: SelectListItem) => Promise<void>,
    onChangeLocation: (item: AdminDeposLiteModel) => Promise<void>,
    orderState: OrderStatus | null,
}

interface IOrdersGridToolbarState {
    locations: AdminDeposLiteModel[],
    currentLocation: AdminDeposLiteModel | null,
}

export default class AdminOrdersGridToolbar extends BaseComponent<IOrdersGridToolbarProps, IOrdersGridToolbarState> {

    public state: IOrdersGridToolbarState = {
        locations: [],
        currentLocation: null,
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbarContainer}>
                
                <ToolbarRow justify={this.mobile ? JustifyContent.Center : JustifyContent.Start} className={styles.toolBarRow}>
                    <Dropdown inline
                              minWidth={250}
                              className={styles.companyDropdown}
                              orderBy={DropdownOrderBy.None}
                              label={this.props.isReturnsGridToolbar ? Localizer.ordersStatus : Localizer.ordersPageOrderStatus}
                              selectedItem={this.props.orderState}
                              items={EnumProvider.getOrderStatusItems()}
                              onChange={async (sender, item) => await this.props.onChangeState(item as SelectListItem)}
                    />
                    <Dropdown inline
                              minWidth={250}
                              className={styles.companyDropdown}
                              orderBy={DropdownOrderBy.Name}
                              label={this.props.isReturnsGridToolbar ? Localizer.shoppingCartPageLocation : Localizer.orderLocationText}
                              selectedItem={this.state.currentLocation ?? this.props.currentLocation}
                              items={this.props.locations}
                              onChange={async (sender, item) => await this.props.onChangeLocation(item!)}
                    />
                </ToolbarRow>
                
            </ToolbarContainer>
        );
    }

}