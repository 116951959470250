import Localizer from "@/localization/Localizer";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";

describe('Desktop - Shopping Cart - Elements tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should render the shopping cart with related products', () => {

        cy.intercept('POST', '/api/ShoppingCart/GetShoppingCart', { fixture: 'getShoppingCart.json' });

        redirectToShoppingCart();

        cy.get('h3')
            .should('contain', Localizer.productDetailsAccessories);

        cy.get('.swiper-slide')
            .should('have.length', 7);

    });

    function redirectToShoppingCart(): void {
        onRedirect(() => executeWithIntercept(() => cy.visit("/ShoppingCart"),
            [
                pageData().common.routes.depots as RouteData,
                pageData().constructionSites.routes.sitesForContract as RouteData
            ]));
    }
});