import Localizer from "@/localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";

describe('Desktop - Fleet Monitoring - Grid tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should change URL when fleet monitoring button is clicked and render empty grid', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        cy.url()
            .should('include', Localizer.pageRoutesFleetMonitoring);

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // The grid is empty with a message
        DeviceGridHelper.getDeviceGrid()
            .should('contain', Localizer.fleetMonitoringPageGridEmpty);
    });

    it('should render grid correctly when there are devices', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');

        // The grid has a header row and 11 data rows.
        DeviceGridHelper.getDeviceGrid()
            .children('div')
            .should('have.length',11);

        // Check header titles.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 0, 1)
            .should('contain', Localizer.fleetMonitoringPageGridDeviceName);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 0, 2)
            .should('contain', Localizer.fleetMonitoringPageGridId);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 1, 0)
            .should('contain', Localizer.fleetMonitoringPageGridAlerts);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 1, 1)
            .should('contain', Localizer.fleetMonitoringPageGridIdle);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 2, 0)
            .should('contain', Localizer.fleetMonitoringPageGridBattery);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(0, 2, 1)
            .should('contain', Localizer.fleetMonitoringPageGridFluid);

        // Check few values in the grid.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(1, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .should('not.exist');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(2, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .find('i.fas.fa-house')
            .should('exist');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(5, 0, 0)
            .find('div[data-cy="tooltipWrapper"]')
            .find('i.fas.fa-truck')
            .should('exist');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(4, 2, 0)
            .should('contain', 0);

        DeviceGridHelper.getDeviceGridContentByRowAndCell(5, 2, 1)
            .should('contain', '-');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(6, 3, 0)
            .should('contain', '●○○');

        // Check if device names are capitalized correctly.
        DeviceGridHelper.getDeviceGridContentByRowAndCell(1, 0, 1)
            .should('contain', 'Sähkötrukki Hyundai');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(7, 0, 1)
            .should('contain', 'Akkukuukulkija 13,0M Manitou 150 AETJ-C');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(8, 0, 1)
            .should('contain', 'Maantiivistäjä Wacker Dpu 6555 HE 495KG');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(10, 0, 1)
            .should('contain', '96840 Genie Z-60FE');
    });

    it('should display device bottom sheet when a checkbox is clicked', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Check if the bottom sheet is initially hidden
        DeviceGridHelper.getDeviceBottomSheet()
            .should('not.be.visible');

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        // Check if the bottom sheet is now visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('be.visible');

        DeviceGridHelper.getDeviceBottomSheet()
            .children('div')
            .should('have.length', 1)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetForSelected);

        DeviceGridHelper.getDeviceBottomSheet()
            .find('button')
            .should('have.length', 1);

        DeviceGridHelper.getDeviceBottomSheet()
            .children('button')
            .eq(0)
            .should('contain.text', Localizer.fleetMonitoringPageBottomSheetAdjustAlerts);

        // DeviceGridHelper.getDeviceBottomSheet()
        //     .children('button')
        //     .eq(1)
        //     .should('contain.text', Localizer.fleetMonitoringPageBottomSheetRequestReturn);

        // Click on a checkbox
        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        // Click on a checkbox and see if the bottom sheet is not visible
        DeviceGridHelper.getDeviceBottomSheet()
            .should('not.be.visible');
    });

    it('should select all/none of equipment with a single click on the header row', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Verify that the header checkbox is not in the checked state.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('false');

        // Click on all row checkboxes.
        DeviceGridHelper.getDeviceGridCheckbox(1)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(2)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(3)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(5)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(6)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(7)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(9)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(10)
            .click();

        // Verify that the header checkbox got checked state.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('true');

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(0)
            .verifyDataValue('false');

        // Check the header checkbox and verify if the one unchecked checkbox got checked.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .verifyDataValue('true');

        // Uncheck the header checkbox and verify if all row checkboxes got unchecked.
        DeviceGridHelper.getDeviceGridCheckbox(0)
            .click();

        DeviceGridHelper.getDeviceGridCheckbox(1)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(2)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(3)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(4)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(5)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(6)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(7)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(8)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(9)
            .verifyDataValue('false');

        DeviceGridHelper.getDeviceGridCheckbox(10)
            .verifyDataValue('false');
    });

    it('should expand a row and display full additional data', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', { fixture: 'getDeviceDetails.json' });

        cy.visit('/fleet-monitoring');

        const testedRow = 3;
        const date = new Date(2019, 10, 4);

        DeviceGridHelper.getDeviceGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericConstructionSite);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Lager');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericCompany);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', 'Stoltz Entreprenør AS');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridRentalStartDate);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', date.toLocaleDateString());

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridActivityHours);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.get(Localizer.fleetMonitoringPageGridActivityHoursValue, 172));

    });

    it('should expand a row and display empty additional data', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', { fixture: 'getDeviceDetailsEmpty.json' });

        cy.visit('/fleet-monitoring');

        const testedRow = 4;

        DeviceGridHelper.getDeviceGridAngleIcon(testedRow)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericConstructionSite);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '-');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.genericCompany);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '-');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridRentalStartDate);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', '-');

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .should('contain.text', Localizer.fleetMonitoringPageGridActivityHours);

        DeviceGridHelper.getDeviceGridExpandedContentByRow(testedRow)
            .find('span:contains("-")')
            .should('have.length', 4);

    });

    it('should expand all/none rows with a single click on the header row', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.visit('/fleet-monitoring');

        // Verify that the header arrow is directed down (some or none rows are expanded).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .should('not.have.class', 'rotated');

        // Click on all arrows (expand all rows).
        DeviceGridHelper.getDeviceGridAngleIcon(1)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(2)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(3)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(4)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(5)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(6)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(7)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(8)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(9)
            .click();

        DeviceGridHelper.getDeviceGridAngleIcon(10)
            .click();

        // Verify that the header arrow is directed up now (all rows are expanded).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .invoke('attr', 'class')
            .then((classList) => {
                expect(classList).to.contain('rotated');
            });

        // Click the header arrow and verify if all arrows are down too (all rows are closed).
        DeviceGridHelper.getDeviceGridAngleIcon(0)
            .click();

    });

});