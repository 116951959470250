import Localizer from "../../../localization/Localizer";

// @ts-ignore
describe('Desktop - Admin easy plus management page tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Open the page and it renders correctly', () => {
        cy.visit('/easyplus-management')

        
        cy.get('#table_easy_plus_management').should('exist');
        cy.get('#table_easy_plus_management tbody tr').should('exist');
        cy.get('button span').should('contain', Localizer.genericDownloadCsv);
    });
});