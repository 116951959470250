import React, {useEffect, useState} from "react";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import CategoryModel from "@/models/server/CategoryModel";
import {BreadCrumbHelper} from "@/helpers/BreadCrumbHelper";

interface ICatalogBreadCrumbProps {
    pricingTool: boolean;
    currentCategory: CategoryModel | null;
    isFavorites: boolean;
    isRentaFuture: boolean;
    onBreadCrumbClick(sender: BreadCrumbItem): Promise<void>;
    onBreadCrumbChange?(items: BreadCrumbItem[]): void;
}

export const CatalogBreadCrumb = ({pricingTool, isFavorites, isRentaFuture, currentCategory, onBreadCrumbClick, onBreadCrumbChange}: ICatalogBreadCrumbProps) => {

    const [items, setItems] = useState<BreadCrumbItem[]>([]);

    useEffect(() => {
        const newItems = BreadCrumbHelper.convertCategoryToBreadCrumbItems(currentCategory, isFavorites, isRentaFuture, pricingTool);

        setItems(newItems);
        if (onBreadCrumbChange) {
            onBreadCrumbChange(newItems);
        }
    }, [currentCategory, isFavorites, isRentaFuture, pricingTool]);

    return (
        <BreadCrumb key={"BreadCrumbs"}
                    items={items}
                    onItemClick={async (sender, index) => await onBreadCrumbClick(sender)}
        />
    );
};
