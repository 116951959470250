import React, {useContext, useState, useEffect} from "react";

import {Link} from "@renta-apps/athenaeum-react-components";
import {Box, Text, ValueIndicator} from "@renta-apps/renta-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import ImageProvider from "@/providers/ImageProvider";

import styles from "../DeviceDetails.module.scss";
import Localizer from "@/localization/Localizer";
import {getDeviceFuelLevelAsync} from "@/services/FleetService";
import FluidLevelModel from "@/models/server/FluidLevelModel";

interface IDeviceDetailsHeaderProps {

}

const DeviceDetailsHeader: React.FC<IDeviceDetailsHeaderProps> = (props: IDeviceDetailsHeaderProps) => {

    const context = useContext(DeviceDetailsContext);

    const [deviceFuelLevel, setDeviceFuelLevel] = useState<FluidLevelModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadDeviceFuelLevel = async (serialNumber: string, rentaId: string, rentalObjectId: string): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await getDeviceFuelLevelAsync(serialNumber, rentaId, rentalObjectId);
            if (response)
                setDeviceFuelLevel(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (context.serialNumber && context.rentaId && context.rentalObjectId)
            loadDeviceFuelLevel(context.serialNumber, context.rentaId, context.rentalObjectId);
    }, []);

    return (
        <div className={styles.deviceDetailsHeader}>
            <div className={styles.titleArea}>
                {context.deviceDetails && (
                    <>
                        <Box className={styles.container} display="flex" flexDirection="column" data-cy={"deviceDetails"}>
                            <Text tag="h3" data-cy={"productGroupName"}>{context.deviceDetails.productGroupName}</Text>
                            <Text tag="h2" data-cy={"deviceName"}>{context.deviceDetails.name}</Text>

                            <Box display="flex" flexDirection="column" gap="4" mb="16">
                                <Box>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericRentaId}:`}
                                    </Text>
                                    <span data-cy={"rentaId"}>{context.rentaId}</span>
                                </Box>
                                <Box>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericConstructionSite}:`}
                                    </Text>
                                    <span data-cy={"constructionSiteName"} >
                                        <Link route={PageDefinitions.constructionSite.route({ params: { id: context.deviceDetails.constructionSiteId || "" } })}>
                                            {context.deviceDetails.constructionSiteName}
                                        </Link>
                                    </span>
                                </Box>
                                <Box>
                                    <Text tag="label" className={styles.label}>
                                        {`${Localizer.genericCompany}:`}
                                    </Text>
                                    <span data-cy={"customerName"}>
                                        <Link route={PageDefinitions.contractDetails.route({ params: { id: context.deviceDetails.customerId || "" } })}>
                                            {context.deviceDetails.customerName}
                                        </Link>
                                    </span>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="row">
                                {/*
                                    TODO: decide when to show/hide each indicator
                                */}
                                {
                                    deviceFuelLevel?.containerState?.fillPercentage && (
                                        <div className={styles.mesurementValue} data-cy={"fillPercentage"}>
                                            <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridFluid}</Text>
                                            <ValueIndicator size="large" type="fluid" value={deviceFuelLevel?.containerState?.fillPercentage || null} data-cy={"fillPercentage"} />
                                        </div>
                                    )
                                }
                                {
                                    !deviceFuelLevel?.containerState?.fillPercentage && (
                                        <div className={styles.mesurementValue} data-cy={"fluidLevel"}>
                                            <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridFuel}</Text>
                                            <ValueIndicator size="large" type="fuel" value={context.deviceDetails.fluidLevel || null} />
                                        </div>
                                    )
                                }

                                <div className={styles.mesurementValue} data-cy={"batteryLevel"}>
                                    <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridBattery}</Text>
                                    <ValueIndicator size="large" type="battery" value={context.deviceDetails.batteryLevel || null} />
                                </div>

                                <div className={styles.mesurementValue} data-cy={"alertsCount"}>
                                    <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridAlerts}</Text>
                                    <ValueIndicator size="large" type="alerts" value={context.deviceDetails.alertsCount || null} />
                                </div>

                                <div className={styles.mesurementValue} data-cy={"gpsSignalQuality"}>
                                    <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridGps}</Text>
                                    <ValueIndicator size="large" type="gps" value={context.deviceDetails.gpsSignalQuality || null} />
                                </div>

                                <div className={styles.mesurementValue} data-cy={"idleDays"}>
                                    <Text tag="span" className={styles.mesurementValueLabel}>{Localizer.fleetMonitoringPageGridIdle}</Text>
                                    <ValueIndicator size="large" type="idle" value={context.deviceDetails.idleDays || null} />
                                </div>
                            </Box>
                        </Box>
                        <Box p={32}>
                            {(!!context.deviceDetails?.imageReference) && (
                                <img src={ImageProvider.getImageAddress(context.deviceDetails.imageReference)}
                                     alt={context.deviceDetails.name || ""}
                                     className={styles.deviceImage}
                                     onError={({currentTarget}: any) => {
                                         currentTarget.onerror = null;
                                         currentTarget.src = "/images/placeholder.jpg";
                                     }}
                                />
                            )}
                            {(!context.deviceDetails?.imageReference) && (
                                <div className={styles.imagePlaceholder}></div>
                            )}
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeviceDetailsHeader;