import React, {useEffect, useState} from "react";
import styles from "./DeviceDetails.module.scss";
import {Spinner} from "@renta-apps/athenaeum-react-components";
import {DeviceDetailedModel} from "@/models/server/DeviceDetailedModel";
import {getDeviceExtendedDetailsAsync} from "@/services/FleetService";
import DeviceDetailsHeader from "@/pages/DeviceDetailsPage/components/DeviceDetailsHeader";

import DeviceDetailsLocation from "@/pages/DeviceDetailsPage/components/DeviceDetailsLocation";
import DeviceDetailsAlerts from "@/pages/DeviceDetailsPage/components/DeviceDetailsAlerts";
import DeviceDetailsDocuments from "@/pages/DeviceDetailsPage/components/DeviceDetailsDocuments";
import DeviceDetailsUsage from "@/pages/DeviceDetailsPage/components/DeviceDetailsUsage";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import { IDeletedAlarm, INewAlarm } from "../FleetMonitoring/FleetMonitoringContainer";

interface IDeviceDetailsProps {
    serialNumber: string;
    rentaId: string;
    rentalObjectId: string;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
}

const DeviceDetails: React.FC<IDeviceDetailsProps> = (props: IDeviceDetailsProps) => {

    const [deviceDetails, setDeviceDetails] = useState<DeviceDetailedModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadDeviceDetails = async (serialNumber: string, rentaId: string, rentalObjectId: string): Promise<void> => {
        try {
            setIsLoading(true);
            // Admin can see device alerts from every organization and construction site. Other users have a specific role, so their search is narrowed.
            const constructionSiteId = (props.userRoleIsAdmin || !props.userRoleConstructionSiteId) ? undefined : props.userRoleConstructionSiteId;
            const contractId = (props.userRoleIsAdmin || !props.userRoleContractId) ? undefined : props.userRoleContractId;

            const response = await getDeviceExtendedDetailsAsync(constructionSiteId, contractId, serialNumber, rentaId, rentalObjectId);
            if (response)
                setDeviceDetails(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    useEffect(() => {
        loadDeviceDetails(props.serialNumber, props.rentaId, props.rentalObjectId);
    }, []);

    const removeDeletedAlarms = (deletedAlarms: IDeletedAlarm[]) => {
        setDeviceDetails(prevDeviceDetails => {
            if (!prevDeviceDetails) return prevDeviceDetails;

            const updatedDeviceDetailedModel = {
                ...prevDeviceDetails,
                configuredAlerts: prevDeviceDetails.configuredAlerts.filter(alert =>
                    !deletedAlarms.some(deleted => deleted.alarmId === alert.id)
                )
            };

            return updatedDeviceDetailedModel;
        });
    };

    const saveNewAlarms = (newAlarms: INewAlarm[]) => {
        setDeviceDetails(prevDeviceDetails => {
            if (!prevDeviceDetails) return prevDeviceDetails;

            const updatedDeviceDetailedModel = {
                ...prevDeviceDetails,
                configuredAlerts: [
                    ...prevDeviceDetails.configuredAlerts,
                    ...newAlarms.map(({ configuredAlert }) => configuredAlert)
                ]
            };

            return updatedDeviceDetailedModel;
        });
    };

    return (
        <DeviceDetailsContext.Provider value={{
            serialNumber: props.serialNumber,
            rentaId: props.rentaId,
            rentalObjectId: props.rentalObjectId,
            userRoleConstructionSiteId: props.userRoleConstructionSiteId,
            userRoleContractId: props.userRoleContractId,
            userRoleIsAdmin: props.userRoleIsAdmin,
            deviceDetails: deviceDetails,
            removeDeletedAlarms: (deletedAlarms: IDeletedAlarm[]) => removeDeletedAlarms(deletedAlarms),
            saveNewAlarms: (newAlarms: INewAlarm[]) => saveNewAlarms(newAlarms),
        }}>
            <div className={styles.deviceDetailsPageContainer}>

                <DeviceDetailsHeader />

                <div id="container" className={styles.container}>
                    {isLoading ? (
                        <div style={{ width: '100%' }}>
                            <div className={styles.spinnerContainer}>
                                <Spinner />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={styles.sideBySide}>
                                <DeviceDetailsLocation />

                                <DeviceDetailsAlerts />
                            </div>

                            <DeviceDetailsUsage />

                            <DeviceDetailsDocuments />
                        </>
                    )}
                </div>
            </div>
        </DeviceDetailsContext.Provider>
    );
};

export default DeviceDetails;