import React, {useEffect, useContext} from "react";

import {Box, Text} from "@renta-apps/renta-react-components";
import {AddressHelper, GoogleMap} from "@renta-apps/athenaeum-react-components";

import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";

import styles from "../DeviceDetails.module.scss";
import Localizer from "@/localization/Localizer";

interface IDeviceDetailsLocationProps {

}

const DeviceDetailsLocation: React.FC<IDeviceDetailsLocationProps> = (props: IDeviceDetailsLocationProps) => {

    const context = useContext(DeviceDetailsContext);
    
    useEffect(() => {

    }, []);
    
    return (
        <div className={`${styles.deviceDetailsLocation} ${styles.moduleWrapper}`}>
            <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                {Localizer.deviceDetailsLocationTitle}
            </Text>
            
            <Box className={styles.moduleContainer} >
                <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"deviceLocation"}>
                    {(AddressHelper.isGoogleApiRegistered) && (context.deviceDetails?.location) ? (
                        <GoogleMap autoCloseInfoWindows
                            height={252}
                            initialCenter={{
                                lat: context.deviceDetails?.location.latitude ?? 0,
                                lng: context.deviceDetails?.location.longitude ?? 0
                            }}
                            initialZoom={16}
                            markers={[{
                                position: {
                                    lat: context.deviceDetails?.location.latitude ?? 0,
                                    lng: context.deviceDetails?.location.longitude ?? 0
                                }
                            }]}
                        />
                    ) : (
                        <Box p={16}>
                            <Text tag="span" weight="bold">{Localizer.deviceDetailsLocationNoLocation}</Text>
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default DeviceDetailsLocation;