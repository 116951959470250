import Localizer from "../../../../localization/Localizer";

// @ts-ignore
describe('Desktop - Authenticated construction site equipment map tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        cy.intercept('POST', '/api/Companies/GetContract?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409').as('contractData');

        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`);

        cy.waitSuccess('@contractData');
    });

    // it('Opens construction site details page and navigate to Map on site which has map markers', () => {
    //     cy.intercept(
    //         {
    //             method: 'POST',
    //             path: '/api/ConstructionSites/ListConstructionSiteMachinesLocations'
    //         },
    //         {
    //             fixture: 'constructionSiteEquipmentMap.json'
    //         }
    //     ).as('machineLocations2');
    //
    //
    //     cy.get('#tab_productMapTab').click();
    //
    //     cy.waitSuccess('@machineLocations2');
    //
    //     cy.wait(300);
    //
    //     // Map should be loaded at this point
    //     cy.get('.athenaeum-google-map-googleMap');
    //
    //     // Click one of the markers on the map
    //     cy.get('div[title="0000001"]').click({force: true});
    //
    //     // Clicked marker should have link to open track device details page
    //     cy.get('a[href*="rentalObjectId=0000001"]');
    //
    //     // Check that edit button exists
    //     cy.get("#location-edit").click();
    //
    //     // Check that autocomplete field has appeared
    //     cy.get('input[class*="ConstructionSiteEquipmentMap"]').type("Äyritie 12");
    //
    //     // Check that autocomplete has provided a match
    //     cy.get('span[class*="pac-matched"]').contains('Äyritie 12');
    //
    //     // Cancel the edit
    //     cy.get("#location-edit-cancel").click();
    // });
});