import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import Localizer from "@/localization/Localizer";
import {AddressHelper, Button, ButtonType, ConfirmationDialog, GoogleMap, Icon, IconSize, IGoogleMapMarker, ImageInput, NumberInput, PageContainer, PageHeader, TextInput} from "@renta-apps/athenaeum-react-components";
import {FileModel, GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {AlertModel, BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ImageProvider from "@/providers/ImageProvider";
import Cloner from "@/helpers/Cloner";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaEasyController from "@/pages/RentaEasyController";
import DepotModel from "@/models/server/DepotModel";

import styles from './Depots.module.scss';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/models/BreadCrumbItem";
import CreatePreSignedUrlRequest from "@/models/server/Requests/CreatePreSignedUrlRequest";
import LocationRequest from "@/models/server/Requests/LocationRequest";

interface IDepotsData {
    depots: DepotModel[];
}

export interface IDepotsParams extends BasePageParameters {
    /**
     * Name of the currently selected Depot.
     */
    selectedDepot?: string;
}

interface IDepotsState extends IDepotsData {
    filterText: string;
    alertModel: AlertModel | null;
    selectedDepotModel: DepotModel | null;
    depotForEditing: DepotModel | null;
}

export default class Depots extends AnonymousPage<IDepotsParams, IDepotsState> {

    // Fields

    public state: IDepotsState = {
        depots: [],
        filterText: '',
        alertModel: null,
        selectedDepotModel: null,
        depotForEditing: null
    };

    private readonly _confirmationRef: React.RefObject<ConfirmationDialog> = React.createRef();

    // Properties

    private get filteredDepots(): DepotModel[] {
        return this.state
            .depots
            .filter(
                depot =>
                    depot.name?.toLowerCase().includes(this.state.filterText.toLowerCase()));
    }

    private get depotForEditing(): DepotModel {
        return this.state.depotForEditing!;
    }

    private get selectedDepotModel(): DepotModel | null {
        return this.state.selectedDepotModel;
    }

    private get editing(): boolean {
        return (!!this.depotForEditing);
    }

    private get selectedDepot(): DepotModel {
        return this.state.selectedDepotModel!;
    }

    private getDepotGeoLocation(depotModel: DepotModel): GeoLocation {
        const geoLocation: GeoLocation = new GeoLocation(depotModel.coordLat, depotModel.coordLng);
        geoLocation.formattedAddress = this.selectedDepot.address!;
        return geoLocation;
    }

    private get isValid(): boolean {
        return (!!this.depotForEditing.name && this.depotForEditing.externalId > 0);
    }

    private get markers(): IGoogleMapMarker[] {
        if (!this.selectedDepot) {
            return [];
        }

        const location = this.getDepotGeoLocation(this.selectedDepot);
        return [
            {
                position: {
                    lat: location.lat,
                    lng: location.lon
                }
            }
        ];
    }

    private get imagePath(): string {
        return this.createFullImagePath(this.selectedDepot.imageReference);
    }

    protected get title(): string {
        return this.state.selectedDepotModel ? `${Localizer.adminPageLocations} - ${this.state.selectedDepotModel.name}` : Localizer.adminPageLocations;
    }

    private get description(): string {
        return ""; // TODO: decide what to put here
    }

    private get keywords(): string {
        const keywords = [];
        if (this.state.selectedDepotModel) {
            keywords.push(this.state.selectedDepotModel.name ?? "");
            keywords.push(this.state.selectedDepotModel.address ?? "");
            keywords.push(this.state.selectedDepotModel.email ?? "");
            keywords.push(this.state.selectedDepotModel.phonenumber ?? "");
        }
        return keywords.join(", ");
    }

    protected get breadCrumbs(): BreadCrumbItem[] {
        const item = new BreadCrumbItem(this.title);
        item.page = "";
        return [item, new BreadCrumbItem("")];
    }

    protected get deleteModalTitle(): string {
        if (this.selectedDepot) {
            return Localizer.get(Localizer.confirmationDeleteLocation, this.selectedDepot.name);
        }
        return Localizer.confirmationDeleteLocationTitle;
    }

    static call(phoneNumber: string) {
        window.open(`tel:${phoneNumber}`, '_self');
    }

    // Methods

    static email(email: string) {
        window.open(`mailto:${email}`, '_self');
    }

    static navigate(navigationLink: string) {
        if (!navigationLink.startsWith("https://")) {
            navigationLink = "https://" + navigationLink;
        }

        window.open(navigationLink, '_self');
    }

    private async openDeleteLocationConfirmAsync(): Promise<void> {
        if (this._confirmationRef.current) {
            await this._confirmationRef.current.openAsync();
        }
    }

    private async setNavigationLinkAsync(value: string): Promise<void> {
        this.depotForEditing.navigationLink = value;
    }

    private async setAddressAsync(value: string): Promise<void> {
        this.depotForEditing.address = value;
    }

    private async setNameAsync(value: string): Promise<void> {
        this.depotForEditing.name = value;
    }

    private async setOrderEmailsAsync(value: string): Promise<void> {
        this.depotForEditing.orderEmails = value;
    }

    private async setCompanyAccessRequestForwardingEmailsAsync(value: string): Promise<void> {
        this.depotForEditing.companyAccessRequestForwardingEmails = value;
    }

    private async setOpeningHoursAsync(value: string): Promise<void> {
        this.depotForEditing.openingHours = value;
    }

    private async setPhoneNumberAsync(value: string): Promise<void> {
        this.depotForEditing.phonenumber = value;
    }

    private async setEmailAsync(value: string): Promise<void> {
        this.depotForEditing.email = value;
    }

    private async setManagerEmailAsync(value: string): Promise<void> {
        this.depotForEditing.managerEmail = value;
    }

    private async setManagerTelephoneAsync(value: string): Promise<void> {
        this.depotForEditing.managerTelephone = value;
    }

    private async setManagerNameAsync(value: string): Promise<void> {
        this.depotForEditing.managerName = value;
    }

    private async setReviewLinkAsync(value: string): Promise<void> {
        this.depotForEditing.reviewLink = value;
    }

    private async setExternalIdAsync(value: number): Promise<void> {
        this.depotForEditing.externalId = value;
    }

    private async setCoordLatAsync(value: number): Promise<void> {
        this.depotForEditing.coordLat = value;
    }

    private async setCoordLngAsync(value: number): Promise<void> {
        this.depotForEditing.coordLng = value;
    }

    private createFullImagePath(imageReference: string): string {
        return `${ImageProvider.imageBasePath}/${imageReference.toLowerCase()}?v=${this.selectedDepot.version}`;
    }

    private async saveDepotAsync(): Promise<void> {
        if (this.isValid) {
            // image deleted
            if (!this.depotForEditing.newImage) {
                const success = await RentaEasyController.deleteImageAsync(this.selectedDepot.imageReference);
                if (!success) {
                    return;
                }
                // new image selected
            }
            else if (this.depotForEditing.newImage.src !== this.imagePath) {
                const uploadRequest: CreatePreSignedUrlRequest = {
                    depotName: this.depotForEditing.name,
                    contentType: this.depotForEditing.newImage.type,
                };
                const uploadResult = await RentaEasyController.uploadImageAsync(this.depotForEditing.newImage.src, uploadRequest);
                if (uploadResult) {
                    // image already uploaded, do not send full image to the backend
                    const newFileModel = new FileModel(this.createFullImagePath(uploadResult.fileName));
                    this.depotForEditing.newImage = ImageProvider.convertCLImageToEasyImage(newFileModel);
                }
                else {
                    return;
                }
                // image did not change, do not do anything
            }
            else {
                this.depotForEditing.newImage = null;
            }
            await this.postAsync("/api/Locations/SaveDepot", this.depotForEditing);

            await this.setState({
                selectedDepotModel: this.depotForEditing,
                depotForEditing: null,
            });

            await this.initializeAsync();
        }
    }

    private async setImageAsync(files: FileModel[]): Promise<Promise<any>> {
        if ((files?.length ?? 0) <= 0) {
            this.depotForEditing.newImage = null;
        }
        else {
            this.depotForEditing.newImage = ImageProvider.convertCLImageToEasyImage(files[0]);
        }

        await this.reRenderAsync();
    }

    private async deleteLocationAsync(): Promise<void> {
        if (!this.depotForEditing) {
            return;
        }

        const identifier: string | null = this.depotForEditing.id;

        if (!identifier || identifier === '') {
            return;
        }

        await this.postAsync("/api/Locations/DeleteDepot", identifier);

        await this.setState({
            selectedDepotModel: null,
            depotForEditing: null,
        });

        await this.initializeAsync();
    }

    private async openEditModeAsync(createdNew: boolean): Promise<void> {

        const depotForEditing: DepotModel = (createdNew)
            ? new DepotModel()
            : Cloner.clone(this.selectedDepot);

        //Let's add old image path to the depot to be sent to the BE
        //BE knows not to delete picture unless newImage is null
        const oldImage = new FileModel(this.imagePath);
        depotForEditing.newImage = ImageProvider.convertCLImageToEasyImage(oldImage);

        await this.setState({
            depotForEditing: depotForEditing
        });
    }

    private async changeDepotAsync(depotModel: DepotModel) {

        await this.setState({
            selectedDepotModel: depotModel
        });

        if (this.typedParameters) {
            this.typedParameters.selectedDepot = depotModel.name!;
        }

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(PageDefinitions.depots.route({
            params: {
                selectedDepot: depotModel.name!
            }
        }));
    }

    private findClosestDepot(depots: DepotModel[]): DepotModel {
        return depots.reduce((min: DepotModel, depot: DepotModel) => {
            if (depot.distance !== null) {
                return (min.distance === null || depot.distance < min.distance) ? depot : min;
            }
            return min;
        }, depots[0]);
    }

    // Simple hash function to convert UUID to a numerical value
    private hashUUID(uuid: string) {
        let hash = 0;
        for (let i = 0; i < uuid.length; i++) {
            const char = uuid.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    // Method to generate a unique color based on the hashed UUID
    private getColorForGroup(uuid: string) {
        if (!uuid) {
            return '#ffffff';
        }
        const hash = this.hashUUID(uuid);
        // Generate a color based on the hash
        const color: string = `hsl(${hash % 360}, 60%, 70%)`;
        return color;
    }

    private getDepotStyle(depotGroupId: string | null): React.CSSProperties | undefined {
        if (!depotGroupId) {
            return {};
        }

        return {
            backgroundColor: this.getColorForGroup(depotGroupId)
        };
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const request: LocationRequest = {
            latitude: null,
            longitude: null,
        };

        const depots: DepotModel[] = await RentaEasyController.getRentalOfficesByLocationAsync(request, this);

        const closestDepot = this.findClosestDepot(depots);

        const depotName: string | null = this.typedParameters?.selectedDepot
            ?? this.state.selectedDepotModel?.name
            ?? this.userContext.user?.favoriteDepotName
            ?? closestDepot.name;

        const selectedDepot: DepotModel = depots
                .find(
                    depot =>
                        depot.name === depotName)
            ?? depots[0];

        await this.changeDepotAsync(selectedDepot);

        await this.setState({
            depots: depots,
        });

    }

    public renderDepotData(depotModel: DepotModel): React.ReactNode {
        return (
            <div className={this.css("d-flex flex-column gap-4")}>
                <div className={this.css(styles.mainDataListContainer, "row")}>
                    <div className={this.css(styles.mainDataList, "col-12 col-sm-6 col-lg-7")}>
                        {
                            (this.isAdminWithAdminRole) && (!this.editing) &&
                            (
                                <>
                                    <Button label={Localizer.catalogEditProductEditButton}
                                            id={"depot_edit"}
                                            type={ButtonType.Orange}
                                            onClick={async () => await this.openEditModeAsync(false)}
                                    />

                                    <Button label={Localizer.locationsCreateNew}
                                            id={"depot_create"}
                                            type={ButtonType.Orange}
                                            onClick={async () => await this.openEditModeAsync(true)}
                                    />
                                </>
                            )
                        }

                        {
                            (this.editing)
                                ?
                                (
                                    <>
                                        <Button type={ButtonType.Orange}
                                                label={Localizer.genericSave}
                                                onClick={async () => await this.saveDepotAsync()}
                                        />

                                        <Button label={Localizer.formDelete}
                                                type={ButtonType.Warning}
                                                onClick={async () => await this.openDeleteLocationConfirmAsync()}
                                        />

                                        <Button label={Localizer.formCancel}
                                                onClick={async () => {
                                                    await this.setState({depotForEditing: null});
                                                }}
                                        />

                                        <TextInput required
                                                   className={styles.paddingLeft}
                                                   value={this.depotForEditing.name!}
                                                   label={Localizer.genericName}
                                                   onChange={async (sender, value) => await this.setNameAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.locationsOrderEmails}
                                                   value={this.depotForEditing.orderEmails!}
                                                   onChange={async (sender, value) => await this.setOrderEmailsAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.locationsCompanyAccessRequestEmails}
                                                   value={this.depotForEditing.companyAccessRequestForwardingEmails!}
                                                   onChange={async (sender, value) => await this.setCompanyAccessRequestForwardingEmailsAsync(value)}
                                        />

                                        <NumberInput className={styles.paddingLeft}
                                                     label={Localizer.locationsLatitude}
                                                     step={0.000001}
                                                     value={this.depotForEditing.coordLat}
                                                     onChange={async (sender, value) => await this.setCoordLatAsync(value)}
                                        />

                                        <NumberInput className={styles.paddingLeft}
                                                     step={0.000001}
                                                     label={Localizer.locationsLongitude}
                                                     value={this.depotForEditing.coordLng}
                                                     onChange={async (sender, value) => await this.setCoordLngAsync(value)}
                                        />

                                        <NumberInput required
                                                     className={styles.paddingLeft}
                                                     label={Localizer.locationsERPNumber}
                                                     value={this.depotForEditing.externalId}
                                                     onChange={async (sender, value) => await this.setExternalIdAsync(value)}
                                        />
                                    </>
                                )
                                :
                                (
                                    <h2 data-cy={"office_title"}>{depotModel.name}</h2>
                                )
                        }

                        {
                            (this.editing)
                                ?
                                (
                                    <div>
                                        <h5>{Localizer.contactInfoText}</h5>

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.genericAddress}
                                                   value={this.depotForEditing.address!}
                                                   onChange={async (sender, value) => await this.setAddressAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.genericPhoneNumber}
                                                   value={this.depotForEditing.phonenumber!}
                                                   onChange={async (sender, value) => await this.setPhoneNumberAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.genericEmail}
                                                   value={this.depotForEditing.email!}
                                                   onChange={async (sender, value) => await this.setEmailAsync(value)}

                                        />
                                    </div>
                                )
                                :
                                (depotModel.address || depotModel.phonenumber || depotModel.email) && (
                                    <div>
                                        <h5>{Localizer.contactInfoText}</h5>

                                        {depotModel.address && (
                                            <div>
                                                <Icon className={styles.iconSize}
                                                      name="map-marker-alt"
                                                />
                                                <span className={styles.paddingLeft}>{depotModel.address}</span>
                                            </div>
                                        )}

                                        {depotModel.phonenumber && (
                                            <div className={styles.contactLink}
                                                 onClick={async () => Depots.call(depotModel.phonenumber!)}
                                            >
                                                <Icon className={styles.iconSize}
                                                      name="phone"
                                                />

                                                <span className={styles.paddingLeft}>{this.selectedDepot.phonenumber}</span>
                                            </div>
                                        )}

                                        {depotModel.email && (
                                            <div className={styles.contactLink}
                                                 onClick={async () => Depots.email(depotModel.email!)}
                                            >
                                                <Icon className={styles.iconSize}
                                                      name="envelope"
                                                />

                                                <span className={styles.paddingLeft}>{this.selectedDepot.email}</span>
                                            </div>
                                        )}
                                    </div>
                                )
                        }

                        {
                            (this.editing)
                                ?
                                (
                                    <div>
                                        <h5>{Localizer.visitingHoursText}</h5>
                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.visitingHoursText}
                                                   value={this.depotForEditing.openingHours!}
                                                   onChange={async (sender, value) => await this.setOpeningHoursAsync(value)}

                                        />
                                    </div>
                                )
                                :
                                (this.selectedDepot.openingHours) && (
                                    <div>
                                        <h5>{Localizer.visitingHoursText}</h5>
                                        <div>
                                            <Icon className={styles.iconSize} name="clock"/>
                                            <span className={styles.paddingLeft}>{this.selectedDepot.openingHours}</span>
                                        </div>
                                    </div>
                                )
                        }

                        {
                            (this.editing)
                                ?
                                (
                                    <div>
                                        <h5>{Localizer.officesContactPerson}</h5>

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.managerNameText}
                                                   value={this.depotForEditing.managerName!}
                                                   onChange={async (sender, value) => await this.setManagerNameAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.locationsContactPhone}
                                                   value={this.depotForEditing.managerTelephone!}
                                                   onChange={async (sender, value) => await this.setManagerTelephoneAsync(value)}
                                        />

                                        <TextInput className={styles.paddingLeft}
                                                   label={Localizer.genericManagerEmail}
                                                   value={this.depotForEditing.managerEmail!}
                                                   onChange={async (sender, value) => await this.setManagerEmailAsync(value)}
                                        />
                                    </div>
                                )
                                :
                                (this.selectedDepot.managerName || this.selectedDepot.managerTelephone || this.selectedDepot.managerEmail) && (
                                    <div>
                                        <h5>{Localizer.officesContactPerson}</h5>

                                        {this.selectedDepot.managerName && (
                                            <span>{this.selectedDepot.managerName}</span>
                                        )}

                                        {this.selectedDepot.managerTelephone && (
                                            <div className={styles.contactLink}
                                                 onClick={async () => Depots.call(this.selectedDepot.managerTelephone!)}
                                            >
                                                <Icon className={styles.iconSize}
                                                      name="phone"
                                                />

                                                <span className={styles.paddingLeft}>{this.selectedDepot.managerTelephone}</span>
                                            </div>
                                        )}

                                        {this.selectedDepot.managerEmail && (
                                            <div className={styles.contactLink}
                                                 onClick={async () => Depots.email(this.selectedDepot.managerEmail!)}
                                            >
                                                <Icon className={styles.iconSize}
                                                      name="envelope"
                                                />

                                                <span className={styles.paddingLeft}>{this.selectedDepot.managerEmail}</span>
                                            </div>
                                        )}
                                    </div>
                                )
                        }
                    </div>
                    <div className="col-12 col-sm-6 col-lg-5">
                        <div className={styles.imageWrapper}>
                            {
                                (this.editing)
                                    ?
                                    (
                                        <ImageInput convertImage={async (file) => file}
                                                    fileTypes={RentaEasyConstants.depotFileTypes}
                                                    maxImageRequestSizeInBytes={RentaEasyConstants.maxDepotUploadSizeinBytes}
                                                    pictures={(this.depotForEditing.newImage)
                                                        ? [ImageProvider.convertEasyImageToCLImage(this.depotForEditing.newImage!)]
                                                        : null}
                                                    selectionToolbar={{deleteButton: true, uploadButton: true, takePictureButton: true}}
                                                    onChange={async (_, files) => await this.setImageAsync(files)}
                                        />
                                    )
                                    :
                                    (
                                        <img src={this.imagePath}
                                             alt={this.selectedDepot.address!}
                                             onError={({currentTarget}: any) => {
                                                 currentTarget.onerror = null;
                                                 currentTarget.src = "/images/placeholder.jpg";
                                             }}
                                        />
                                    )
                            }
                        </div>

                        <div className={styles.squareButtonsWrapper}>
                            <Button className={styles.squareButton}
                                    type={ButtonType.Orange}
                                    icon={{name: "phone", size: IconSize.Large}}
                                    label={Localizer.officesCall}
                                    disabled={!this.selectedDepot!.phonenumber}
                                    onClick={async () => Depots.call(this.selectedDepot!.phonenumber!)}
                            />

                            <Button className={styles.squareButton}
                                    type={ButtonType.Orange}
                                    icon={{name: "envelope", size: IconSize.Large}}
                                    label={Localizer.officesEmail}
                                    disabled={!this.selectedDepot!.email}
                                    onClick={async () => Depots.email(this.selectedDepot!.email!)}
                            />

                            {
                                (this.editing)
                                    ?
                                    (
                                        <>
                                            <TextInput className={styles.paddingLeft}
                                                       value={this.depotForEditing.navigationLink!}
                                                       label={Localizer.locationsDrivingInstructions}
                                                       onChange={async (sender, value) => await this.setNavigationLinkAsync(value)}

                                            />

                                            <TextInput className={styles.paddingLeft}
                                                       value={this.depotForEditing.reviewLink!}
                                                       label={Localizer.locationsReviewLink}
                                                       onChange={async (sender, value) => await this.setReviewLinkAsync(value)}
                                            />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Button className={styles.squareButton}
                                                    type={ButtonType.Orange}
                                                    icon={{name: "directions", size: IconSize.Large}}
                                                    label={Localizer.officesDriveInstructions}
                                                    disabled={!this.selectedDepot!.navigationLink}
                                                    onClick={async () => Depots.navigate(this.selectedDepot!.navigationLink!)}
                                            />

                                            <Button className={styles.squareButton}
                                                    type={ButtonType.Orange}
                                                    icon={{name: "star", size: IconSize.Large}}
                                                    label={Localizer.officesRate}
                                                    disabled={!this.selectedDepot!.reviewLink}
                                                    onClick={async () => Depots.navigate(this.selectedDepot!.reviewLink!)}
                                            />
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
                {
                    (AddressHelper.isGoogleApiRegistered) && (
                        <GoogleMap autoCloseInfoWindows
                                   height={500}
                                   initialCenter={{lat: this.getDepotGeoLocation(depotModel).lat ?? 0, lng: this.getDepotGeoLocation(depotModel).lon ?? 0}}
                                   initialZoom={16}
                                   markers={this.markers}
                        />
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.depots, "gap-2")} hasWideHeader>

                {this.renderHead(this.title, this.keywords, this.description)}

                <PageHeader title={this.title.toUpperCase()} wideHeader wideHeaderBackgroundImage="/images/offices/lahti.jpg"/>

                <BreadCrumb items={this.breadCrumbs} onItemClick={async () => {
                }}/>

                {
                    (this.selectedDepotModel) &&
                    (
                        this.renderDepotData(this.selectedDepotModel)
                    )
                }

                <div className={styles.searchByNameWrapper}>
                    <Icon name="search"/>

                    <TextInput className={styles.searchByName}
                               placeholder={Localizer.officesSearchByName}
                               value={this.state.filterText}
                               onChange={async (_, filterText) => {
                                   await this.setState({filterText});
                               }}
                    />
                </div>

                <div className={this.css(styles.locationList)}>
                    {
                        this.filteredDepots.map(depot =>
                            (
                                <div key={depot.name}
                                     data-cy={"depot"}
                                     className={styles.locationsListItem}
                                     style={this.isAdmin ? this.getDepotStyle(depot.depotGroupId) : {}}
                                     onClick={(async () => await this.changeDepotAsync(depot))}
                                >
                                    {depot.name}
                                </div>
                            )
                        )
                    }
                </div>

                <ConfirmationDialog
                    ref={this._confirmationRef}
                    title={this.deleteModalTitle}
                    callback={async () => await this.deleteLocationAsync()}
                />
            </PageContainer>
        );
    }
}