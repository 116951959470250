import React, {useState, useContext} from "react";
import styles from "../DeviceDetails.module.scss";

import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Box, ButtonSize, ButtonType, Icon, Text} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import SimpleTable, {SimpleTableRowData} from "@/components/SimpleTable/SimpleTable";
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";
import SubscribeToAlertsModal from "@/pages/FleetMonitoring/Modals/SubscribeToAlertsModal";
import {IDeletedAlarm, INewAlarm} from "@/pages/FleetMonitoring/FleetMonitoringContainer";

const tableHeaders = [
    {key: "name", label: Localizer.genericDescription},
    {key: "latestOccurrence", label: Localizer.genericTime, render: (rowData: SimpleTableRowData) => {
            return Utility.formatValue(Utility.restoreDate(rowData.latestOccurrence), "dt");
        }},
];

interface IDeviceDetailsAlertsProps {}

const DeviceDetailsAlerts: React.FC<IDeviceDetailsAlertsProps> = (props: IDeviceDetailsAlertsProps) => {

    const context = useContext(DeviceDetailsContext);

    const [isSubscribeToAlertsModalOpen, setIsSubscribeToAlertsModalOpen] = useState<boolean>(false);

    const handleAlertsSettingsClicked = () => {
        setIsSubscribeToAlertsModalOpen(true);
    }

    return (
        <div className={`${styles.deviceDetailsAlerts} ${styles.moduleWrapper}`}>
            <Box display="flex" flex-direction="row" justifyContent="space-between" >
                <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                     {Localizer.deviceDetailsAlertsTitle}
                </Text>

                <EasyPlusFeatureButton onClick={() => handleAlertsSettingsClicked()} size={ButtonSize.Small} type={ButtonType.Icon} id={"alertsSettingsButton"}>
                    <Icon name="gear"/>
                </EasyPlusFeatureButton>
            </Box>

            <Box className={styles.moduleContainer}>
                <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"configuredAlerts"}>
                    {context.deviceDetails?.configuredAlerts.length ? (
                        <SimpleTable headers={tableHeaders} data={context.deviceDetails?.configuredAlerts} rowGridClassName={styles.rowGrid}/>
                    ) : (
                        <Box p={16}>
                            <Text tag="span" weight="bold">{Localizer.deviceDetailsAlertsNoAlerts}</Text>
                        </Box>
                    )}
                </Box>
            </Box>

            <SubscribeToAlertsModal
                getSelectedDevices={() => context?.deviceDetails ? [context.deviceDetails] : []}
                saveNewAlarms={(newAlarms: INewAlarm[]) => context.saveNewAlarms(newAlarms)}
                removeDeletedAlarms={(deletedAlarms: IDeletedAlarm[]) => context.removeDeletedAlarms(deletedAlarms)}
                isOpen={isSubscribeToAlertsModalOpen}
                onClose={() => setIsSubscribeToAlertsModalOpen(false)}
            />
        </div>
    );
};

export default DeviceDetailsAlerts;