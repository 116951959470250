import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe('Desktop - Plan toolbar tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("juhani.sihvonen@weare.fi");
        });
        cy.visit(Localizer.pageRoutesPlans);

        executeWithIntercept(() =>
                pageData().plan.completedPlansTab().click(),
            [pageData().plan.routes.getPlans as RouteData]
        );
    });

    it('Opens completed plans and applies filters', () => {
        executeWithIntercept(() =>
                pageData().plan.clearFiltersButton().click(),
            [pageData().plan.routes.getPlans as RouteData]
        );

        GridHelper.getGridVisibleRows(pageData().plan.plansGridName())
            .should('have.length.above', 2);

        // Apply completed by filter
        cy.get("#completedPlanCompletedByFilter").click();

        executeWithIntercept(() =>
                CypressDropdownHelper.selectDropdownItemByIndex("#completedPlanCompletedByFilter", 0),
            [pageData().plan.routes.getPlans as RouteData]
        );

        GridHelper.getGridContentByRowAndCell(pageData().plan.plansGridName(), 0, 1)
            .find("div")
            .should('contain', "cs.user@domain.fi");

        // Apply depot filter
        executeWithIntercept(() =>
                pageData().plan.clearFiltersButton().click(),
            [pageData().plan.routes.getPlans as RouteData]
        );

        cy.get("#completedPlanDepotFilter").click();

        executeWithIntercept(() =>
                CypressDropdownHelper.selectDropdownItemByIndex("#completedPlanDepotFilter", 0),
            [pageData().plan.routes.getPlans as RouteData]
        );

        GridHelper.getGridContentByRowAndCell(pageData().plan.plansGridName(), 0, 1)
            .find("div")
            .should('contain', "juhani.sihvonen@weare.fi");
    });
});