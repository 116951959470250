import React from "react";
import Localizer from "@/localization/Localizer";
import {Button, ButtonType, Checkbox, InlineType} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";

type ProductDetailsAdminControlsProps = {
    product: ProductModel;
    editedProduct: ProductModel;

    isEditing: boolean;
    isLoading: boolean;

    saveChangesAsync: () => Promise<void>;
    setEditingAsync: (isEditing: boolean) => Promise<void>;
    setShowDescriptionAsync: (showDescription: boolean) => Promise<void>;
    setAlwaysAvailableAsync: (alwaysAvailable: boolean) => Promise<void>;
    setPreventModelSelection: (preventModelSelection: boolean) => Promise<void>;
    setIsRentaFutureAsync: (isRentaFuture: boolean) => Promise<void>;
}

const ProductDetailsAdminControls: React.FC<ProductDetailsAdminControlsProps> = (props: ProductDetailsAdminControlsProps) => {

    return (
        <>
            <div className="col-12 col-lg-4 py-2 d-flex flex-column flex-sm-row gap-2">
                {(props.isEditing) ?
                    (<>
                        <Button label={Localizer.genericSave}
                                id={"product_details_edit_save"}
                                className="flex-1"
                                type={ButtonType.Success}
                                disabled={props.isLoading}
                                onClick={async () => await props.saveChangesAsync()}
                        />

                        <Button label={Localizer.formCancel}
                                id={"product_details_edit_cancel"}
                                className="flex-1"
                                type={ButtonType.Default}
                                disabled={props.isLoading}
                                onClick={async () => await props.setEditingAsync(false)}
                        />
                    </>)
                    :
                    (<>
                        <Button label={Localizer.serviceDefinitionsEdit}
                                id={"product_details_edit"}
                                className="flex-1"
                                type={ButtonType.Default}
                                disabled={props.isLoading}
                                onClick={async () => await props.setEditingAsync(true)}
                        />
                    </>)
                }
            </div>
            <div className="col-12 col-lg-8 py-2 d-flex gap-2 flex-column flex-md-row justify-content-lg-end">
                <Checkbox inline
                          inlineType={InlineType.Right}
                          label={Localizer.productDetailsShowDescription}
                          value={props.editedProduct?.showDescription ?? props.product.showDescription}
                          readonly={!props.isEditing || props.isLoading}
                          onChange={async (_, checked) => await props.setShowDescriptionAsync(checked)}
                />

                <Checkbox inline
                          inlineType={InlineType.Right}
                          label={Localizer.productDetailsShowAlwaysAsAvailable}
                          value={props.editedProduct?.showAsAvailable ?? props.product.showAsAvailable}
                          readonly={!props.isEditing || props.isLoading}
                          onChange={async (_, checked) => await props.setAlwaysAvailableAsync(checked)}
                />

                <Checkbox inline
                          inlineType={InlineType.Right}
                          label={Localizer.productDetailsShowDropDown}
                          value={!(props.editedProduct?.preventModelSelection ?? props.product.preventModelSelection)}
                          readonly={!props.isEditing || props.isLoading}
                          onChange={async (_, checked) => await props.setPreventModelSelection(!checked)}
                />

                <Checkbox inline
                          inlineType={InlineType.Right}
                          label={Localizer.productDetailsIsRentaFuture}
                          value={(props.editedProduct?.isRentaFuture ?? props.product.isRentaFuture)}
                          readonly={!props.isEditing || props.isLoading}
                          onChange={async (_, checked) => await props.setIsRentaFutureAsync(checked)}
                />
            </div>
        </>
    );
}

export default ProductDetailsAdminControls;