import React, {useEffect, useMemo, useState} from "react";
import {Dropdown, IDropdownItem, Input} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {ViewControlProps} from "@/pages/FleetMonitoring/ViewControl/ViewControl";
import {getContractData, searchCompanyNames} from "@/services/CompanyService";
import {searchProductGroupNames} from "@/services/ProductService";
import {ConstructionSiteDetailsResponse} from "@/models/server/Responses/ConstructionSiteDetailsResponse";
import {getConstructionSiteDetails, searchConstructionSiteNames} from "@/services/ConstructionSiteService";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import styles from "./FiltersForm.module.scss";

interface FiltersFormProps extends ViewControlProps {
    vertical?: boolean;
    submitOnBlur?: boolean;
    children?: React.ReactNode;
    className?: string;
    idPrefix?: string;
}

const FiltersForm: React.FC<FiltersFormProps> = ({
    filters,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    onFilterAndSort,
    vertical,
    submitOnBlur,
    children,
    className,
    idPrefix = '',
}) => {
    const [constructionSite, setConstructionSite] = useState<string | undefined>(filters.constructionSite);
    const [constructionSiteId, setConstructionSiteId] = useState<string | undefined>(filters.constructionSiteId);
    const [deviceName, setDeviceName] = useState<string | undefined>(filters.deviceName);
    const [productGroup, setProductGroup] = useState<string | undefined>(filters.productGroup);
    const [productGroupId, setProductGroupId] = useState<string | undefined>(filters.productGroupId);
    const [company, setCompany] = useState<string | undefined>(filters.company);
    const [companyId, setCompanyId] = useState<string | undefined>(filters.companyId);
    const [sortBy, setSortBy] = useState(filters.sortBy);
    const [sortOrder, setSortOrder] = useState(filters.sortOrder);
    const [userRoleCompany, setUserRoleCompany] = useState<string | undefined>(undefined);
    const [userRoleConstructionSite, setUserRoleConstructionSite] = useState<string | undefined>(undefined);
    const [companies, setCompanies] = useState<IDropdownItem[]>([]);
    const constructionSitePlaceholderVisible = useMemo(() => {
        return !companyId && (!companies.length || companies.length > 1);
    }, [companyId, companies]);

    useEffect(() => {
        loadConstructionSiteData(userRoleConstructionSiteId).catch();
        loadContractData(userRoleContractId, userRoleIsAdmin).catch();
    }, [userRoleConstructionSiteId, userRoleContractId, userRoleIsAdmin]);

    useEffect(() => {
        setCompany(filters.company);
        setCompanyId(filters.companyId);
        setDeviceName(filters.deviceName);
        setProductGroup(filters.productGroup);
        setProductGroupId(filters.productGroupId);
        setSortBy(filters.sortBy);
        setSortOrder(filters.sortOrder);
        setConstructionSite(filters.constructionSite);
        setConstructionSiteId(filters.constructionSiteId);
    }, [filters]);

    const sortByItems: IDropdownItem[] = [
        {
            name: Localizer.fleetMonitoringPageFiltersSortByIdle,
            value: "IdleDays",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByAlerts,
            value: "AlertsCount",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByBattery,
            value: "BatteryLevel",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByFuel,
            value: "FluidLevel",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByName,
            value: "Name",
        },
    ];

    const sortOrderItems: IDropdownItem[] = [
        {
            name: Localizer.enumSortDirectionDesc,
            value: "Desc",
        },
        {
            name: Localizer.enumSortDirectionAsc,
            value: "Asc",
        },
    ];

    const onFilterInternal = (
        deviceName: string | undefined,
        productGroup: string | undefined,
        productGroupId: string | undefined,
        company: string | undefined,
        companyId: string | undefined,
        constructionSite: string | undefined,
        constructionSiteId: string | undefined,
        sortField: string = sortBy,
        sortDirection: string = sortOrder,
    ) => {
        onFilterAndSort({
            deviceName: deviceName === '' ? undefined : deviceName,
            productGroup: productGroup === '' ? undefined : productGroup,
            company: company === '' ? undefined : company,
            constructionSite: constructionSite === '' ? undefined : constructionSite,
            productGroupId,
            companyId,
            constructionSiteId,
            sortBy: sortField,
            sortOrder: sortDirection,
        });
    };

    const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        onFilterInternal(deviceName, productGroup, productGroupId, company, companyId, constructionSite, constructionSiteId);
    };

    const handleBlur = () => {
        if (submitOnBlur) {
            handleSubmit();
        }
    };

    const searchCompanyNamesInternal = async (value: string): Promise<IDropdownItem[]> => {
        try {
            const searchTerm = value.trim();
            if (searchTerm.length < 3) {
                return [];
            }
            const companyNames = await searchCompanyNames(searchTerm);
            return (companyNames ?? []).map((company) => ({name: company.name, value: company.id}));
        } catch (error) {
            console.error('Error searching company names:', error);
            return [];
        }
    };

    const searchProductGroupNamesInternal = async (value: string): Promise<IDropdownItem[]> => {
        try {
            const searchTerm = value.trim();
            if (searchTerm.length < 3) {
                return [];
            }
            const productGroupNames = await searchProductGroupNames(searchTerm);
            return (productGroupNames ?? []).map((group) => ({name: group.name, value: group.externalId}));
        } catch (error) {
            console.error('Error searching product group names:', error);
            return [];
        }
    };

    const searchConstructionSiteNamesInternal = async (value: string): Promise<IDropdownItem[]> => {
        try {
            const contractId = companyId ?? userRoleContractId;
            const searchTerm = value.trim();
            if (!contractId) {
                return [];
            }
            const constructionSiteNames = await searchConstructionSiteNames(searchTerm ?? '', contractId);
            return (constructionSiteNames ?? []).map((site) => ({name: site.name, value: site.id}));
        } catch (error) {
            console.error('Error searching construction site names:', error);
            return [];
        }
    };

    const loadConstructionSiteData = async (constructionSiteId: string | null) => {
        try {
            if (constructionSiteId !== null) {
                const constructionSiteDetailsResponse: ConstructionSiteDetailsResponse = await getConstructionSiteDetails(constructionSiteId);

                setUserRoleConstructionSite(constructionSiteDetailsResponse.constructionSite?.name!);
            }
        } catch (error) {
            console.error('Error loading construction site data:', error);
        }
    };

    const loadContractData = async (userRoleContractId: string | null, userRoleIsAdmin: boolean) => {
        if (userRoleIsAdmin || !userRoleContractId) {
            setCompanies([]);
            return;
        }

        try {
            const contractData = await getContractData(userRoleContractId);
            const companiesList = [mapCompanyToDropdownItem(contractData.organizationContract!)]
                .concat(contractData.organizationContract?.children?.map(mapCompanyToDropdownItem) ?? []);
            setCompanies(companiesList);
            if (companiesList.length === 1) {
                setUserRoleCompany(companiesList[0].name);
            }
        } catch (error) {
            console.error('Error loading contract data:', error);
        }
    };

    const mapCompanyToDropdownItem = (company: OrganizationContractModel): IDropdownItem => {
        return {
            name: `${company.name ?? ''} ${company.customerNumber ? `(${company.customerNumber})` : ''}`,
            value: company.contractId,
        };
    };

    const handleSortByChange = (item: IDropdownItem) => {
        const newSortBy = item.value.toString();
        setSortBy(newSortBy);
        onFilterInternal(deviceName, productGroup, productGroupId, company, companyId, constructionSite, constructionSiteId, newSortBy, sortOrder);
    };

    const handleSortOrderChange = (item: IDropdownItem) => {
        const newSortOrder = item.value.toString();
        setSortOrder(newSortOrder);
        onFilterInternal(deviceName, productGroup, productGroupId, company, companyId, constructionSite, constructionSiteId, sortBy, newSortOrder);
    };
    
    const handleCompanyChange = (name?: string, id?: string, reload: boolean = false) => {
        setCompany(name);
        setCompanyId(id);
        setConstructionSite(undefined);
        setConstructionSiteId(undefined);
        if (reload) {
            onFilterInternal(deviceName, productGroup, productGroupId, name, id, undefined, undefined);
        }
    };
    
    const handleProductGroupChange = (name: string, id?: string) => {
        setProductGroup(name);
        setProductGroupId(id);
        if (id) {
            onFilterInternal(deviceName, name, id, company, companyId, constructionSite, constructionSiteId);
        }
    };

    const handleConstructionSiteChange = (name: string, id?: string) => {
        setConstructionSite(name);
        setConstructionSiteId(id);
        if (id) {
            onFilterInternal(deviceName, productGroup, productGroupId, company, companyId, name, id);
        }
    };

    return (
        <form className={`${styles.filtersForm} ${className} ${vertical ? styles.vertical : ''}`}
              onSubmit={(event) => handleSubmit(event)}
              autoComplete="off"
        >
            <div className={styles.inputContainer}>
                <Input id={`${idPrefix}filter-by-name`}
                       value={deviceName}
                       label={Localizer.fleetMonitoringPageFiltersLabelsName}
                       iconName="magnifyingGlass"
                       iconButton
                       iconButtonType="submit"
                       inputProperties={{name: "deviceName"}}
                       onChange={setDeviceName}
                       onBlur={handleBlur}
                />
            </div>

            {companies?.length && companies.length > 1 ? (
                <div className={styles.inputFilter}>
                    <Dropdown id={`${idPrefix}filter-by-company-dropdown`}
                                 className={styles.field}
                                 items={companies}
                                 label={Localizer.fleetMonitoringPageFiltersLabelsCompany}
                                 selectedItem={companyId}
                                 onChange={(item: IDropdownItem) => handleCompanyChange(undefined, item?.value?.toString(), true)}
                                 allowEmpty
                    />
                </div>
            ) : (
                <div className={styles.inputContainer}>
                    <Input id={`${idPrefix}filter-by-company`}
                           value={userRoleCompany ?? company}
                           label={Localizer.fleetMonitoringPageFiltersLabelsCompany}
                           iconName="filter"
                           iconButton
                           iconButtonType="submit"
                           inputProperties={{name: "company"}}
                           onChange={handleCompanyChange}
                           onBlur={handleBlur}
                           disabled={(!!companies?.length && companies.length === 1) || !!userRoleConstructionSiteId}
                           useSuggestions={userRoleIsAdmin}
                           onSuggestionClick={({name, value}: IDropdownItem) => handleCompanyChange(name, value.toString(), true)}
                           getSuggestions={searchCompanyNamesInternal}
                    />
                </div>
            )}

            <div className={styles.inputFilter}>
                <Dropdown
                    id={`${idPrefix}sort-by`}
                    className={styles.field}
                    items={sortByItems}
                    label={Localizer.fleetMonitoringPageFiltersLabelsSortBy}
                    selectedItem={sortBy}
                    onChange={handleSortByChange}
                />
            </div>

            <div className={styles.inputContainer}>
                <Input id={`${idPrefix}filter-by-product-group`}
                       value={productGroup}
                       label={Localizer.fleetMonitoringPageFiltersLabelsProductGroup}
                       iconName="filter"
                       iconButton
                       iconButtonType="submit"
                       inputProperties={{name: "productGroup"}}
                       onChange={handleProductGroupChange}
                       onBlur={handleBlur}
                       useSuggestions
                       onSuggestionClick={({name, value}: IDropdownItem) => handleProductGroupChange(name, value.toString())}
                       getSuggestions={searchProductGroupNamesInternal}
                />
            </div>

            <div className={styles.inputContainer}>
                <Input id={`${idPrefix}filter-by-construction-site`}
                       value={userRoleConstructionSite ?? constructionSite}
                       label={Localizer.fleetMonitoringPageFiltersLabelsConstructionSite}
                       iconName="filter"
                       iconButton
                       iconButtonType="submit"
                       disabled={!companyId && !userRoleCompany}
                       inputProperties={{
                           name: "constructionSite",
                           placeholder: constructionSitePlaceholderVisible ? Localizer.fleetMonitoringPageFiltersSelectCompany : undefined,
                       }}
                       onChange={handleConstructionSiteChange}
                       onBlur={handleBlur}
                       useSuggestions
                       minValueLengthForSuggestions={0}
                       onSuggestionClick={({name, value}: IDropdownItem) => handleConstructionSiteChange(name, value.toString())}
                       getSuggestions={searchConstructionSiteNamesInternal}
                />
            </div>

            <div className={styles.inputFilter}>
                <Dropdown
                    id={`${idPrefix}sort-order`}
                    className={styles.field}
                    items={sortOrderItems}
                    label={Localizer.fleetMonitoringPageFiltersLabelsSortOrder}
                    selectedItem={sortOrder}
                    onChange={handleSortOrderChange}
                />
            </div>

            {children}
        </form>
    );
};

export default FiltersForm;