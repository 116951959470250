import { SortDirection } from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";
import {Method} from "cypress/types/net-stubbing";

describe('Desktop - Fleet Monitoring - Filters tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should display view controls', () => {
        const {routes: {getDevicesPagedList}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');

        DeviceGridHelper.getViewControl()
            .should('exist');

        // The filter has six elements.
        DeviceGridHelper.getViewControl()
            .children('div')
            .should('have.length', 6);

        DeviceGridHelper.getViewControlFilter(4)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        DeviceGridHelper.getViewControlFilter(2)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortBy);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        pageData().fleetMonitoring.filters.sortByFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 5)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByIdle);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByAlerts);
                        break;
                    case 2:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByBattery);
                        break;
                    case 3:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByFuel);
                        break;
                    case 4:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByName);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        DeviceGridHelper.getViewControlFilter(5)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortOrder);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

        pageData().fleetMonitoring.filters.sortOrderFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 2)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.enumSortDirectionDesc);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.enumSortDirectionAsc);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

    });

    it('should call API when sort by filter is changed', () => {
        const {routes: {getDevicesPagedList}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' }).as('postRequest');

        cy.visit('/fleet-monitoring');

        cy.wait('@postRequest', {timeout: 20_000}).then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortColumnName', 'IdleDays');

            // Select the Battery option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 2);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequest').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortColumnName', 'BatteryLevel');

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?pageNumber=1&pageSize=25&sortBy=BatteryLevel&sortOrder=Desc`);
            });
        });

    });

    it('should call API when sort order filter is changed', () => {
        const {routes: {getDevicesPagedList}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedListEmpty.json' }).as('postRequest');

        cy.visit('/fleet-monitoring');

        cy.wait('@postRequest', {timeout: 20_000}).then(({ request }) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortDirection', SortDirection.Desc);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequest').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortDirection', SortDirection.Asc);

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?pageNumber=1&pageSize=25&sortBy=IdleDays&sortOrder=Asc`);
            });
        });

    });

    it('should call API when construction site filter is changed', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedListEmpty.json' }).as('postRequest');
        cy.intercept('GET', '/api/ConstructionSites/SearchConstructionSiteNames?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409&searchLimit=50&searchTerm=test').as('searchConstructionSites');

        cy.visit('/fleet-monitoring');

        // First call to get devices
        cy.wait('@postRequest', {timeout: 20_000}).then(({ request }) => {
            expect(request.body).to.have.property('contractId', '31eaa6c0-42fa-40c2-a891-bcd16c367409');
            expect(request.body).to.have.property('constructionSiteId', null);

            // Select a construction site
            filters.constructionSiteFilter.input()
                .type('test');
            cy.waitSuccess('@searchConstructionSites');

            DeviceGridHelper.getViewControlFilter(4)
                .children('div')
                .eq(0)
                .children('div')
                .eq(1)
                .children('div')
                .eq(2)
                .click();

            // The request after the first construction site filter change
            cy.wait('@postRequest').then(({ request }) => {
                expect(request.body).to.have.property('constructionSiteId', 'f7422461-9c46-416d-b500-7ab6ae1ee591');
                expect(request.body).to.have.property('constructionSite', null);

                // Double check the options are set
                filters.constructionSiteFilter.input()
                    .should('have.value', 'TESTITILAUS RENTA EASY');

                // Deselect the construction site
                filters.constructionSiteFilter.input()
                    .clear().type('{enter}');

                // The request after the second construction site filter change
                cy.wait('@postRequest').then(({ request }) => {
                    expect(request.body).to.have.property('constructionSiteId', null);
                    expect(request.body).to.have.property('constructionSite', null);

                    // No option is selected
                    filters.constructionSiteFilter.input()
                        .should('be.empty');
                });
            });
        });
    });

    it('should set input fields with the default values correctly', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedListEmpty.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?constructionSite=TESTITILAUS%20RENTA%20EASY&constructionSiteId=f7422461-9c46-416d-b500-7ab6ae1ee591&sortBy=FluidLevel&sortOrder=Asc');

        cy.wait('@postRequest').then(() => {
            filters.constructionSiteFilter.input()
                .should('have.value', 'TESTITILAUS RENTA EASY');

            filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByFuel);

            filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            cy.visit('/fleet-monitoring?constructionSite=TESTITILAUS%20RENTA%20EASY%201&constructionSiteId=68240177-21ea-4d88-863e-fa924c8005df&sortOrder=Desc&sortBy=BatteryLevel');

            cy.wait('@postRequest').then(() => {
                filters.constructionSiteFilter.input()
                    .should('have.value', 'TESTITILAUS RENTA EASY 1');

                filters.sortByFilter.title()
                    .should('have.text', Localizer.fleetMonitoringPageFiltersSortByBattery);

                filters.sortOrderFilter.title()
                    .should('have.text', Localizer.enumSortDirectionDesc);
            });
        });
    });

    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });
        cy.intercept('GET', '/api/ConstructionSites/SearchConstructionSiteNames?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409&searchLimit=50&searchTerm=test').as('searchConstructionSites');

        cy.visit('/fleet-monitoring');

        // Select a construction site
        filters.constructionSiteFilter.input()
            .type('test');
        cy.waitSuccess('@searchConstructionSites');

        DeviceGridHelper.getViewControlFilter(4)
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(2)
            .click();

        // Select the Name option from the dropdown
        DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 4);

        // Select the Ascending option from the dropdown
        DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

        // Double check the options are set
        filters.constructionSiteFilter.input()
            .should('have.value', 'TESTITILAUS RENTA EASY');

        filters.sortByFilter.title()
            .should('have.text', Localizer.fleetMonitoringPageFiltersSortByName);

        filters.sortOrderFilter.title()
            .should('have.text', Localizer.enumSortDirectionAsc);

        // Leave the page
        cy.visit('/rent');

        // Go back to the page
        cy.visit('/fleet-monitoring')

        // Options are remembered
        filters.constructionSiteFilter.input()
            .should('have.value', 'TESTITILAUS RENTA EASY');

        filters.sortByFilter.title()
            .should('have.text', Localizer.fleetMonitoringPageFiltersSortByName);

        filters.sortOrderFilter.title()
            .should('have.text', Localizer.enumSortDirectionAsc);
    });
});