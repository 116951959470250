import Localizer from "../../../localization/Localizer";

describe('Desktop - Anonymous product details tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['accept_cookie'], cy.acceptCookies);
        cy.intercept('POST', 'api/Product/ProductData', {fixture: 'product'}).as('data');
        cy.intercept('POST', 'api/Product/GetProductAvailability', {fixture: 'productAvailability'}).as('data');
        cy.visit('tuote/mock-url');
        cy.waitSuccess('@data');
    });

    it('Copy share link to clipboard ', () => {

        cy.get('#share-link-button').click();

        //Note: importing localizer makes the test runner start a bit slower
        cy.get('.athenaeum-page-container-container').contains(Localizer.productDetailsShareLinkUrlCopied);

        //TODO check why this doesn't work in CI/CD (electron)
        // cy.window()
        //     .its('navigator.clipboard')
        //     .invoke('readText')
        //     .should('eq', Cypress.config().baseUrl + '/tuote/mock-url');
    });

    it('Open availability information and navigate to depot page ', () => {
        cy.get('#tab_productDetailsAvailabilityTab').click();

        cy.get('[data-cy=depot_link]').first().click();

        cy.url().should('include', Localizer.pageRoutesDepots);

    });
});