import {RoleType} from "@/models/Enums";

export default class ConstructionSiteUserModel {

    /**
     * Id of the users Contract or Construction Site role.
     */
    public roleId: string = "";

    /**
     * Name of the users Contract or Construction Site role.
     */
    public roleName: string | null = null;

    /**
     * Type of the users role.
     */
    public roleType: RoleType = RoleType.ConstructionSite;

    /**
     * Users email address.
     */
    public email: string | null = null;

    /**
     * Users id.
     */
    public userId: string | null = null;

    /**
     * Users first name.
     */
    public firstName: string | null = null;

    /**
     * Users last name.
     */
    public lastName: string | null = null;

    public readonly isConstructionSiteUserModel: true = true;

}