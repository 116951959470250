import { SortDirection } from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";
import {Method} from "cypress/types/net-stubbing";

describe('Desktop - Fleet Monitoring - Filters tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("cs.user@domain.fi", "ConstructionSite1");
        });

    });

    it('should display view controls', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', 'api/ConstructionSites/GetConstructionSiteDetails', { fixture: 'constructionSiteDetails.json' });

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getViewControl()
            .should('exist');

        // The filter has six elements.
        DeviceGridHelper.getViewControl()
            .children('div')
            .should('have.length', 6);

        DeviceGridHelper.getViewControlFilter(4)
            .find('label')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        filters.constructionSiteFilter.input()
            .should('have.value', '13395/10436287/PIENKALUSTO');

        DeviceGridHelper.getViewControlFilter(2)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortBy);

        DeviceGridHelper.getViewControlFilter(5)
            .children('div')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsSortOrder);
    });

    it('should set input fields with the values obtain from URL', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, { fixture: 'fleetGetDevicesPagedListEmpty.json' }).as('postRequest');

        cy.visit('/fleet-monitoring?sortBy=AlertsCount&sortOrder=Asc');

        cy.wait('@postRequest').then(() => {
            filters.constructionSiteFilter.input()
                .should('have.value', 'ConstructionSite1');

            filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByAlerts);

            filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            cy.wait('@postRequest').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('constructionSiteId', '20c46273-6c09-4c7d-98bf-300071dfc1e2');
                expect(request.body).to.have.property('sortColumnName', 'AlertsCount');
                expect(request.body).to.have.property('sortDirection', SortDirection.Asc);
            });

        });

    });

});