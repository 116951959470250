import React from "react";
import {BaseComponent, BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, CellModel, ColumnDefinition, Grid, OneColumn} from "@renta-apps/athenaeum-react-components";
import ProductInfoModel from "@/models/server/ProductInfoModel";
import Localizer from "@/localization/Localizer";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import GetMachineModelDocumentRequest from "@/models/server/Requests/GetMachineModelDocumentRequest";
import {sortProductInfoAttributes, TechnicalInfoRow} from "@/helpers/ProductInfoHelper";
import CategoryAttributeKeyModel from "@/models/server/CategoryAttributeKeyModel";

interface IProductModelInfoListProps extends BasePageParameters {
    infos: ProductInfoModel[];
    id?: string;
    categoryKeys: CategoryAttributeKeyModel[];
}

export default class ProductModelInfoList extends BaseComponent<IProductModelInfoListProps> {
    private readonly _modelInfoGrid: React.RefObject<Grid<TechnicalInfoRow>> = React.createRef();

    private readonly _modelInfoColumns: ColumnDefinition[] = [
        {
            header: Localizer.productModelInfoAttribute,
            accessor: "key",
            editable: false,
            sorting: false,
            wordBreak: true,
        },
        {
            header: Localizer.productModelInfoValue,
            accessor: "value",
            editable: false,
            sorting: false,
            wordBreak: true,
            render: (cell: CellModel<TechnicalInfoRow>) => this.renderValueCell(cell)
        }
    ];

    private get infos(): ProductInfoModel[] {
        return this.props.infos ?? [];
    }

    private renderValueCell(cell: CellModel<TechnicalInfoRow>): React.ReactNode {
        return (ch.isNorway && cell.model.key === "Document")
            ? (
                <Button type={ButtonType.Text}
                        label={cell.model.value!}
                        onClick={async () => await this.downloadNorwayDocumentAsync(cell.model.documentId!)}
                />
            )
            : (cell.model.isLink)
                ? (
                    <a href={cell.model.value as string} target="_blank" rel="noreferrer">
                        {cell.model.key}
                    </a>
                ) : (
                    <span>
                        {cell.model.value}
                    </span>
                );
    }

    private renderModelInfoColumn(modelInfo: ProductInfoModel, index: number, categoryKeys: CategoryAttributeKeyModel[]): React.ReactNode {
        const attributes = sortProductInfoAttributes(modelInfo, categoryKeys);

        return (
            <React.Fragment key={modelInfo.id + index}>
                <OneColumn>
                    <h6 data-cy={"modelInfoName"}>{modelInfo.name}</h6>
                    <Grid version2Styles
                          className={`modelInfoGrid`}
                          data={attributes}
                          ref={this._modelInfoGrid}
                          columns={this._modelInfoColumns}
                    />
                </OneColumn>
                <hr/>
            </React.Fragment>
        );
    }

    public async downloadNorwayDocumentAsync(documentId: number): Promise<void> {
        const request = new GetMachineModelDocumentRequest();
        request.documentId = documentId;
        
        const response: FileModel = await this.postAsync(`/api/Product/GetDocument`, request);

        ch.download(response);
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id}>
                <OneColumn>
                    <p>
                        {
                            Localizer.productDetailsProductsAreExamples.toUpperCase()
                        }
                    </p>
                </OneColumn>
                {
                    this.infos?.map((productInfo, index) => (
                        this.renderModelInfoColumn(productInfo, index, this.props.categoryKeys)
                    ))
                }
            </div>
        );
    }
}