import Localizer from "@/localization/Localizer";
import {onRedirect, pageData} from "@/helpers/CypressHelper";

describe('Desktop - Company user contract details tests', () => {

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit('/');
    });

    it('Should display page with company access friendly error', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee592`));

        cy.get('h1')
            .should('contain', Localizer.companyDetailsCannotBeAccessedTitle);

        cy.get('div')
            .should('contain', Localizer.companyDetailsCannotBeAccessedDescription);

        cy.get('div')
            .should('contain', Localizer.companyDetailsCannotBeAccessedWhatNext);

        cy.get('li')
            .should('contain', Localizer.genericTryLoggingInAgain);

        cy.get('a')
            .should('contain', Localizer.genericContactSupport);

    });

    it('Should display page with contract details', () => {

        onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`));

        pageData().company.contractName()
            .should('contain', 'TMI JUKKA WILSKA');

        cy.get('#contractVatId')
            .should('contain', '2798958-4');

        cy.get('#customerId')
            .should('contain', '29276');

        cy.get('#ContractDetailsContainer')
            .should('exist');
    });

});