import React, {useEffect} from "react";
import {Link, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import PageDefinitions from "@/providers/PageDefinitions";
import styles from "./EasyPlusInfoModal.module.scss";
import {Button, ButtonSize, ButtonType} from "@renta-apps/renta-react-components";

interface EasyPlusInfoModalProps {
    isOpen: boolean;
    onClose(): void;
    onContactMeClick(): void;
}

const EasyPlusInfoModal: React.FC<EasyPlusInfoModalProps> = ({isOpen, onClose, onContactMeClick}) => {
    const _modalRef: React.RefObject<Modal> = React.createRef();

    useEffect(() => {
        if (isOpen) {
            _modalRef.current?.openAsync();
        } else {
            _modalRef.current?.closeAsync();
        }
    }, [_modalRef, isOpen]);
    
    return isOpen ? (
        <Modal id="easy-plus-info-modal"
               ref={_modalRef}
               preventEsc preventClosingOnInsideClick
               size={ModalSize.Large}
               title={Localizer.easyPlusInfoModalTitle}
               className={styles.modal}
               onClose={async () => onClose()}
        >
                <div className={styles.easyPlusInfo}>
                    <div className={styles.easyPlusInfoContent}>
                        <div>
                            <div>{Localizer.easyPlusInfoPanelLine1}</div>
                            <div>{Localizer.easyPlusInfoPanelLine2}</div>
                        </div>
                        <div>
                            <div>
                                {Localizer.easyPlusInfoPanelLine3}
                                &nbsp;
                                <Link className={styles.easyPlusLink}
                                      route={PageDefinitions.easyPlus.route()}
                                >
                                    {Localizer.easyPlusInfoPanelReadMore}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.easyPlusContactMeButton}>
                        <Button onClick={onContactMeClick} size={ButtonSize.Default} type={ButtonType.Primary}>
                            {Localizer.easyPlusInfoModalContactMe}
                        </Button>
                    </div>
                </div>
        </Modal>
    ) : null;
}

export default EasyPlusInfoModal;