import Localizer from "../../../localization/Localizer";

describe('Desktop - Request company access', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session('private_user_session', cy.login, {});
    });

    function AssertInputError(input: string): void {
        cy.get('#form_submit')
            .click();

        cy.get(input)
            .children()
            .first()
            .should('have.class', 'athenaeum-form-validationError');
    }

    function AssertSubmitSuccess(): void {

        cy.intercept('POST', 'api/Account/ApplyCompanyAccount').as('applyCompanyAccount');

        cy.get('#form_submit')
            .click();

        cy.waitSuccess('@applyCompanyAccount');

        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.applyCompanyAccountSent);
    }

    function FillForm(): void {
        cy.get('#vat_id')
            .type('12345');

        cy.get('#company_name')
            .type('Test Company Ltd');

        cy.get('#manager_name')
            .type('Manager Person');

        cy.get('#manager_email')
            .type('test@weare.fi');
    }

    function AssertConstructionSitesInputWorks(sites: string): void {
        cy.get('#construction_sites').type(sites);

        cy.get('#all_construction_sites')
            .children()
            .eq(1)
            .click();

        cy.get('#input_construction_sites')
            .should('have.value', Localizer.genericAll);

        cy.get('#all_construction_sites')
            .children()
            .eq(1)
            .click();

        cy.get('#input_construction_sites')
            .should('have.value', sites);
    }

    it('Assert form validation works', () => {
        cy.visit(Localizer.pageRoutesApplyCompanyAccount);

        AssertInputError('#vat_id');
        AssertInputError('#company_name');
        AssertInputError('#manager_email');
        AssertInputError('#manager_name');
        AssertInputError('#construction_sites');
    });

    it('Assert valid form submit', () => {
        cy.visit(Localizer.pageRoutesApplyCompanyAccount);

        AssertConstructionSitesInputWorks('1, 2, 3, 4, 5');

        FillForm();

        cy.get('#select_depot');

        AssertSubmitSuccess();
    });
});

export default {};