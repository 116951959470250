import React, { Suspense } from "react";
import { BaseAsyncComponent, ch, IBaseAsyncComponentState } from "@renta-apps/athenaeum-react-common";

import DoughnutChart from "@/components/Charts/DoughnutChart/DoughnutChart";
import BarChart from "@/components/Charts/BarChart/BarChart";
import { ChartData } from "@/components/Charts/Models/IDoughnutData";
import styles from "./Emissions.module.scss";
import { Button, ButtonType, DateRangeInput, Dropdown, Icon, IconSize, Modal, ModalSize, SelectListItem, Spinner } from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import { EmissionFilterTimeFrame, EmissionType } from "@/models/Enums";
import { IEmissionData } from "@/components/Charts/Models/IEmissionData";
import EnumProvider from "@/providers/EnumProvider";
import GetEquipmentEmissionData from "@/models/server/Requests/GetEquipmentEmissionData";
import { FileModel } from "@renta-apps/athenaeum-toolkit";
import RentaEasyController from "@/pages/RentaEasyController";
import { FeatureSwitch } from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import PageDefinitions from "@/providers/PageDefinitions";
import { getNextData } from "@/helpers/ChartDataHelper";

interface IServicesState extends IBaseAsyncComponentState<ChartData[]> {
    previousData: ChartData[][];

    currentData: ChartData[];

    selectedCategories: string[];

    selectedFilter: EmissionType | null;

    dateRangeFrom: Date | null;

    dateRangeTo: Date | null;

    emissionFilterTimeFrame: EmissionFilterTimeFrame;
}

export default abstract class Emissions<TProps> extends BaseAsyncComponent<TProps, IServicesState, {}> {
    private readonly _legalNoticeInfoModal: React.RefObject<Modal> = React.createRef();

    // Fields
    public state: IServicesState = {
        previousData: [],
        currentData: [],
        selectedCategories: [],
        data: null,
        selectedFilter: null,
        isLoading: false,
        dateRangeFrom: new Date().addMonths(-6),
        dateRangeTo: new Date(),
        emissionFilterTimeFrame: EmissionFilterTimeFrame.Monthly,
    };

    protected abstract getExcelEndpoint(): string;

    protected abstract getRequest(
        dateRangeFrom: Date | null,
        dateRangeTo: Date | null,
        emissionType: EmissionType | null,
        emissionFilterTimeFrame: EmissionFilterTimeFrame): GetEquipmentEmissionData;

    protected async fetchDataAsync(): Promise<ChartData[]> {
        return await this.fetchChartDataAsync();
    }

    private async fetchChartDataAsync(
        emissionFilter: EmissionFilterTimeFrame | null = null,
        emissionType: number | null = null,
        dateFrom: Date | null = null,
        dateTo: Date | null = null,
    ): Promise<ChartData[]> {
        if (this.isFeatureUnavailable) {
            return [];
        }

        // -1 means that the user has unselected an option in the dropdown, so we should not filter by emission type
        const selectedFilter = emissionType === -1 ? null : (emissionType ?? this.state.selectedFilter);
        const emissionFilterTimeFrame = emissionFilter ?? this.state.emissionFilterTimeFrame;
        const dateRangeFrom = dateFrom ?? this.state.dateRangeFrom;
        const dateRangeTo = dateTo ?? this.state.dateRangeTo;

        const request: GetEquipmentEmissionData = this.getRequest(dateRangeFrom, dateRangeTo, emissionType, emissionFilterTimeFrame);
        let currentData: ChartData[] = await this.postAsync(this.getEndpoint(), request);
        let previousData: ChartData[][] = [];
        const {selectedCategories} = this.state;

        if (selectedCategories.length) {
            for (const item of selectedCategories) {
                const selected = currentData.find(x => x.name === item);
                if (!selected) {
                    break;
                }
                previousData.push(currentData);
                currentData = getNextData(selected);
            }
        }

        this.setState({
            currentData,
            emissionFilterTimeFrame,
            selectedFilter,
            dateRangeFrom,
            dateRangeTo,
            previousData,
        });

        return currentData;
    }

    private async downloadExcelFile(): Promise<void> {
        const request: GetEquipmentEmissionData = this.getRequest(
            this.state.dateRangeFrom,
            this.state.dateRangeTo,
            this.state.selectedFilter,
            this.state.emissionFilterTimeFrame
        );
        const data: FileModel = await this.postAsync(this.getExcelEndpoint(), request);

        ch.download(data);
    }

    private get getPageTitle(): string {
        const checkCategoryNameConsistency = (data: ChartData[]): boolean => {
            if (data.length === 0) {
                return false; // No items in the array
            }
            const firstCategoryName = data[0].categoryName;
            if (!firstCategoryName) {
                return false; // No productData in the first item
            }

            for (const chartItem of data) {
                if (chartItem.categoryName !== firstCategoryName) {
                    return false; // productGroupNumber inconsistency found
                }
            }

            return true; // All productGroupNumbers are consistent
        };

        if (this.displayInventoryItemInfo) {
            return this.chartData[0].name;
        }

        return this.chartData[0]?.isTopLevel && this.chartData.length > 1 ?
            Localizer.emissionsPageTitle
            : checkCategoryNameConsistency(this.chartData) ?
                this.chartData[0].categoryName ?? ""
                : this.chartData[0]?.parentName ?? this.chartData[0]?.name ?? "";
    }

    private get displayInventoryItemInfo(): boolean {
        return this.chartData?.length === 1 && this.chartData[0].children.length === 0;
    }

    private get chartData(): ChartData[] {
        return this.state.currentData;
    }

    private get isFeatureUnavailable(): boolean {
        return !RentaEasyController.isEasyPlusUser;
    }

    private setDoughnutDataToPrevious() {
        this.setState(prev => {
            return {
                currentData: prev.previousData.slice(-1)[0] ?? prev.currentData,
                previousData: prev.previousData.slice(0, -1),
                selectedCategories: prev.selectedCategories.slice(0, -1),
            }
        });
    }

    private setPreviousToState(data: ChartData[]) {
        this.setState(prev => ({previousData: [...prev.previousData, data]}));
    }

    private setDataToState(data: ChartData[]) {
        this.setState(prev => ({currentData: data, selectedCategories: [...prev.selectedCategories, data[0].parentName]}));
    }

    private getKey(forBarChart: boolean): string {
        let prefix: string = forBarChart ? "bar" : "doughnut";
        return `${prefix}_chart_${this.chartData[0]?.id}_${this.state.selectedFilter}`;
    }

    private async onDateRangeChangeAsync(from: Date | null, to: Date | null): Promise<void> {
        if (from && to) {
            await this.fetchChartDataAsync(null, null, from, to);
        } else {
            this.setState({dateRangeFrom: from, dateRangeTo: to});
        }
    }

    private async onEmissionTimeFrameFilterChange(emissionFilterTimeFrame: EmissionFilterTimeFrame): Promise<void> {
        await this.fetchChartDataAsync(emissionFilterTimeFrame);
    }

    private onEmissionFilterChangeAsync = async (item: SelectListItem | number | null): Promise<void> => {
        const getItemAsNumber = (item: number | null): number => item == null ? -1 : item as number;
        const selectedEmissionType: number = item instanceof SelectListItem ? Number.parseInt(item.value) : getItemAsNumber(item);
        await this.fetchChartDataAsync(null, selectedEmissionType);
    };

    public get getEmissionTypeItems(): SelectListItem[] {
        if (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCo2TransportEnabled)) {
            return EnumProvider.getEmissionTypeItems();
        }
        else {
            return EnumProvider.getEmissionTypeItems()
                .filter(item => Number.parseInt(item.value) !== EmissionType.Transport);
        }
    }

    private renderBackButton(): React.ReactNode {
        if (this.chartData.length === 0 || this.chartData[0]?.isTopLevel) {
            return <></>;
        }

        return (
            <div className={styles.backButton}
                 onClick={() => this.setDoughnutDataToPrevious()}>
                <Icon name={"far chevron-left"}/>
                {Localizer.emissionsBackButton}
            </div>
        );
    }

    private renderInfoBox(text: string, unit: string, value: number | string | undefined): React.ReactNode {
        return (
            <div className={styles.statisticsBox}>
                <div>
                    {text}
                </div>
                <div>
                    <div className={styles.valueRow}>
                        <div>{value}</div>
                        <div>{unit}</div>
                    </div>
                </div>
            </div>
        );

    }

    sumEmissionValuesByType(data: IEmissionData[], emissionType: EmissionType): number {
        let sum = 0;

        for (const item of data) {
            for (const emissionValue of item.emissionValues) {
                if (emissionValue.emissionType === emissionType) {
                    sum += emissionValue.value;
                }
            }
        }
        return sum;
    }

    CreateTotalRow(emissionData: number, emissionType: string): React.ReactNode {
        return (
            <tr>
                <td>
                    {
                        emissionType
                    }
                </td>
                <td>
                    {
                        `${emissionData.toFixed(1)} ${Localizer.emissionsCalculatedEmissionsTotalUnit}`
                    }
                </td>
            </tr>
        );
    }

    RenderTableRows(): React.ReactNode {

        if (this.chartData.length === 0) {
            return (<></>);
        }

        const data = this.chartData[0];

        return (
            <React.Fragment>
                {this.CreateTotalRow(data?.value, Localizer.genericAll)}
                <FeatureSwitch flagName={RentaEasyConstants.featureFlagCo2TransportEnabled}>
                    {this.CreateRow(data?.emissionData, EmissionType.Transport)}
                </FeatureSwitch>
                {this.CreateRow(data?.emissionData, EmissionType.OilMaintenance)}
                {this.CreateRow(data?.emissionData, EmissionType.Energy)}
                {this.CreateRow(data?.emissionData, EmissionType.Production)}
                {this.CreateRow(data?.emissionData, EmissionType.EndOfLife)}
            </React.Fragment>
        );
    }

    CreateRow(emissionData: IEmissionData[], emissionType: EmissionType): React.ReactNode {
        return (
            <tr>
                <td>
                    {
                        EnumProvider.getEmissionTypeText(emissionType)
                    }
                </td>
                <td>
                    {
                        `${this.sumEmissionValuesByType(emissionData, emissionType).toFixed(1)} ${Localizer.emissionsCalculatedEmissionsTotalUnit}`
                    }
                </td>
            </tr>
        );
    }

    public render(): React.ReactNode {
        if (this.isFeatureUnavailable) {
            return (
                <div className={styles.easyPlus}>
                    <div className={styles.easyPlusTitle}>{Localizer.easyPlusFeatureTitle}</div>
                    <div className={styles.easyPlusText}>{Localizer.easyPlusNewSubscriptionInfo}</div>
                    <Button label={Localizer.easyPlusNewSubscriptionInfoButton}
                            type={ButtonType.Orange}
                            route={PageDefinitions.easyPlus.route()}
                    />
                </div>
            );
        }

        return (
            <div className={styles.emissions}>
                {
                    this.renderBackButton()
                }
                <h2>{this.getPageTitle}</h2>
                <hr/>
                {
                    !(this.displayInventoryItemInfo) && (
                        <div className={styles.statisticsContainer} id={"emissions-info-box"}>
                            {
                                this.renderInfoBox(Localizer.emissionsMachinesTotalCount, Localizer.emissionsMachinesTotalCountUnit, this.chartData.sum(a => a.productCount))
                            }
                            {
                                this.renderInfoBox(Localizer.emissionsUsageHoursTotal, Localizer.emissionsUsageHoursTotalUnit, this.chartData.sum(item => item.emissionData.sum(p => p.operatingHours)))
                            }
                            {
                                this.renderInfoBox(Localizer.emissionsCalculatedEmissionsTotal, Localizer.emissionsCalculatedEmissionsTotalUnit, this.chartData.sum(item => item.emissionData.sum(p => p.emissionValues.sum(item => item.value))).toFixed(1))
                            }
                        </div>
                    )
                }
                {
                    (this.displayInventoryItemInfo) && (
                        <div>
                            <table className={styles.emissionsTable}>
                                <tbody>
                                {
                                    this.RenderTableRows()
                                }
                                </tbody>
                            </table>

                        </div>
                    )
                }

                <div className={styles.filters}>
                    <Dropdown label={Localizer.emissionsFilterEmissions}
                              nothingSelectedText={Localizer.emissionFilterAll}
                              items={this.getEmissionTypeItems}
                              onChange={async (sender, item) => await this.onEmissionFilterChangeAsync(item)}
                    />
                    <DateRangeInput sameDay clickToEdit
                                    icon={{name: "far calendar", customStyle: {color: "#fe5000"}}}
                                    label={Localizer.emissionsFilterTimeframe}
                                    className={styles.dateRangeInput}
                                    model={{value: [this.state.dateRangeFrom, this.state.dateRangeTo]}}
                                    onChange={async ([start, end]) => await this.onDateRangeChangeAsync(start, end)}
                    />
                    <div>
                        {Localizer.emissionsDrillDownHelpText}
                    </div>
                </div>

                <div className={styles.barChartContainer}>
                    <div className={styles.filtersContainer}>
                        <button className={this.css(styles.pillButton, this.state.emissionFilterTimeFrame === EmissionFilterTimeFrame.Daily ? styles.pillButtonActive : "")}
                                onClick={async () => await this.onEmissionTimeFrameFilterChange(EmissionFilterTimeFrame.Daily)}
                        >{Localizer.environmentSensorChart1Day}
                        </button>
                        <button className={this.css(styles.pillButton, this.state.emissionFilterTimeFrame === EmissionFilterTimeFrame.Weekly ? styles.pillButtonActive : "")}
                                onClick={async () => await this.onEmissionTimeFrameFilterChange(EmissionFilterTimeFrame.Weekly)}
                        >{Localizer.environmentSensorChartWeek}
                        </button>
                        <button className={this.css(styles.pillButton, this.state.emissionFilterTimeFrame === EmissionFilterTimeFrame.Monthly ? styles.pillButtonActive : "")}
                                onClick={async () => await this.onEmissionTimeFrameFilterChange(EmissionFilterTimeFrame.Monthly)}
                        >{Localizer.environmentSensorChartMonth}
                        </button>
                    </div>
                </div>

                {
                    this.chartData.length > 0 && (
                        <Suspense fallback={<Spinner/>}>
                            <div className={styles.chartContainer}>
                                {
                                    (!this.displayInventoryItemInfo) && (
                                        <DoughnutChart data={this.chartData}
                                                       key={this.getKey(false)}
                                                       onDataChange={(data: ChartData[]) => this.setDataToState(data)}
                                                       onSetPrevious={(data: ChartData[]) => this.setPreviousToState(data)}
                                        />
                                    )
                                }

                                <div className={styles.barChart}>
                                    <BarChart data={this.chartData}
                                              key={this.getKey(true)}
                                              timeFrame={this.state.emissionFilterTimeFrame}
                                    />
                                </div>
                            </div>
                        </Suspense>
                    )
                }
                {
                    <div className={styles.infoButtonsContainer}>
                        {
                            RentaEasyController.isEasyPlusUser && (
                                <Button id={"downloadExcelButton"}
                                        label={Localizer.emissionsDownload}
                                        icon={{name: "fa fa-download", size: IconSize.Large}}
                                        type={ButtonType.Orange}
                                        onClick={async () => await this.downloadExcelFile()}
                                />
                            )
                        }
                        <Modal info
                               id={"emissionLegalNoticeModal"}
                               title={` `}
                               ref={this._legalNoticeInfoModal}
                               size={ModalSize.Auto}>
                            {
                                // Below text is hard coded for legal reasons.
                                // We don't want to localize it and have a risk that someone changes the content of below text.
                            }
                            <div style={{maxWidth: "750px"}}>
                                <h4>Understanding Our CO2-eq Calculator: Making Informed Environmental Choices</h4>
                                <p>At Renta, we are committed to providing top-tier construction equipment and contributing to a more sustainable future.
                                    Our CO2-eq calculator is designed to help you understand the environmental impact of your equipment rentals and make informed choices that align with your sustainability goals.
                                </p>
                                <h4>How It Works:</h4>
                                <p>The vast majority of our fleet is equipped with telematics. This technology enables us to measure factors such as fuel consumption and energy usage. For equipment with telematics, we calculate CO2-eq emissions based
                                    on real-time data, ensuring accuracy and reliability to best extent possible.
                                </p>
                                <h4>Data Sources:</h4>
                                <p>We rely on the reputable European Rental Association's Equipment CO2-eq Calculator to determine CO2-eq emissions for your rentals: <a
                                    href={"https://equipmentcalculator.org/en/era-equipment-co2-calculator"}>https://equipmentcalculator.org/en/era-equipment-co2-calculator</a>. This robust database ensures that our calculations are aligned with
                                    industry standards, enabling you to compare emissions across various equipment types and rental periods.
                                </p>
                                <h4>Continuous Improvement:</h4>
                                <p>As we grow and expand, we're continuously enhancing our CO2-eq calculator. For instances where telematics isn't yet installed, we use carefully averaged numbers to estimate CO2-eq emissions per rental. This is a
                                    temporary solution as we actively work towards retrofitting older equipment and ensuring the accuracy of our calculations. However, we wanted to give you insight into the minor part of the fleet without telematics
                                    before you rent to help you make a conscious decision on the average emissions.
                                </p>
                                <h4>Your Role:</h4>
                                <p>Our CO2-eq calculator empowers you to make conscious decisions about your equipment choices, which could help you reduce your carbon footprint. </p>
                                <p>
                                    Thank you for trusting us with your equipment needs and joining us on our journey toward a more sustainable tomorrow.
                                    For any inquiries about our CO2-eq calculator or our commitment to sustainability, please get in touch with our team. Together, we can build a better, greener world.
                                </p>
                                <p>
                                    Sincerely,<br/><br/>
                                    Rebecca Långström,<br/>
                                    Head of Sustainability, Renta Group<br/>
                                    <a href={"mailto:rebecca.langstrom@renta.com"}>rebecca.langstrom@renta.com</a>
                                </p>
                            </div>
                        </Modal>
                        <Button id={"emissionLegalNoticeModalButton"}
                                label={Localizer.emissionsLegalNoticeButton}
                                icon={{name: "fa fa-info", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                onClick={async () => await this._legalNoticeInfoModal.current?.openAsync()}
                        />
                    </div>
                }
                {
                    <div className={styles.emissionHelpTextContainer}>
                        {
                            this.RenderHelpText(Localizer.enumEmissionTypeEndOfLife, Localizer.emissionsEmissionTypeEndOfLifeHelpText)
                        }
                        {
                            this.RenderHelpText(Localizer.enumEmissionTypeEnergy, Localizer.emissionsEmissionTypeEnergyHelpText)
                        }
                        {
                            this.RenderHelpText(Localizer.enumEmissionTypeOilMaintenance, Localizer.emissionsEmissionTypeOilMaintenanceHelpText)
                        }
                        {
                            this.RenderHelpText(Localizer.enumEmissionTypeProduction, Localizer.emissionsEmissionTypeProductionHelpText)
                        }
                        {
                            UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCo2TransportEnabled) && (
                                this.RenderHelpText(Localizer.enumEmissionTypeTransport, Localizer.emissionsEmissionTypeTransportHelpText)
                            )
                        }
                    </div>
                }

            </div>
        );
    }

    private RenderHelpText(key: string, value: string): React.ReactNode {
        return (<p className={styles.emissionHelpText}>
            <span>{key}:</span> <span>{value}</span>
        </p>);

    }
}