import React from "react";
import {
    Button,
    ButtonType,
    CarouselNavigation,
    ConfirmationDialog,
    DateRangeInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    Icon,
    IconSize,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListGroup,
    SelectListItem,
    TextAreaInput,
    TextInput,
    TwoColumns,
    WeekDaysEnum
} from "@renta-apps/athenaeum-react-components";
import {BasePageParameters, DialogResult,  UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import {assert, Utility} from "@renta-apps/athenaeum-toolkit";
import RentaEasyController from "@/pages/RentaEasyController";
import SaveProductToCartRequest from "@/models/server/SaveProductToCartRequest";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import OrderDetails from "@/models/server/OrderDetails";
import {OrderType, OrganizationContractLevel, ProductType, RequestState} from "@/models/Enums";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import {ContractUserModel} from "@/models/server/ContractUserModel";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import PriceHelper from "@/helpers/PriceHelper";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import UserModel from "@/models/server/UserModel";
import DepotModel from "@/models/server/DepotModel";
import ShoppingCartModel from "@/models/server/ShoppingCartModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/ShoppingCart/ShoppingCart.module.scss";
import ProductAccessories from "@/components/ProductConfirmationModal/ProductAccessories/ProductAccessories";
import InviteNewUserModal from "@/components/InviteNewUserModal/InviteNewUserModal";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import {ContractConstructionSiteUserModel} from "@/models/server/ContractConstructionSiteUserModel";
import UnleashHelper from "@/helpers/UnleashHelper";
import ShoppingCartEmpty from "@/pages/ShoppingCart/ShoppingCartEmpty/ShoppingCartEmpty";
import ShoppingCartRequiresCompanyAccess from "@/pages/ShoppingCart/ShoppingCartRequiresCompanyAccess/ShoppingCartRequiresCompanyAccess";
import ShoppingCartSummary from "@/pages/ShoppingCart/ShoppingCartPriceSummary/ShoppingCartPriceSummary";
import ShoppingCartFormButtons from "@/pages/ShoppingCart/ShoppingCartFormButtons/ShoppingCartFormButtons";
import ShoppingCartProducts from "@/pages/ShoppingCart/ShoppingCartProducts/ShoppingCartProducts";

export interface IShoppingCartParams extends BasePageParameters {
}

export interface IShoppingCartState {
    contractId: string | null;
    parentContractId: string | null;
    creatingNewConstructionSite: boolean;
    estimatedRentalPeriod: [Date | null, Date | null];
    unavailableProductsLoaded: RequestState;
    order: OrderDetails | null;
    depots: DepotModel[];
    orderingForContract: OrganizationContractModel | null;
    unavailableProductIds: string[];
    contractDiscounts: OrganizationContractDiscounts | null;
    constructionSites: ConstructionSiteModel[];
    constructionSitesUsers: ContractConstructionSiteUserModel[];
    inviteUserRequest: InviteNewUserRequest | null;
    inviteUserResponse: IResponse | null;
    showNetPriceOnlyFlag: boolean,
    companyAccessRequiredFlag: boolean,
    showRequiresCompanyAccessMessage: boolean;
}

export interface IResponse {
    alertMessage: string,
    errorMessage: string
}

export default class ShoppingCart extends AuthorizedPage<IShoppingCartParams, IShoppingCartState> {

    // Fields

    public state: IShoppingCartState = {
        contractId: null,
        parentContractId: null,
        creatingNewConstructionSite: false,
        estimatedRentalPeriod: [null, null],
        unavailableProductsLoaded: RequestState.Sending,
        order: null,
        depots: [],
        orderingForContract: null,
        unavailableProductIds: [],
        contractDiscounts: null,
        constructionSites: [],
        constructionSitesUsers: [],
        inviteUserRequest: null,
        inviteUserResponse: null,
        showNetPriceOnlyFlag: false,
        companyAccessRequiredFlag: false,
        showRequiresCompanyAccessMessage: false,
    };

    private readonly _shoppingCartFormDataKey: string = "shoppingCartFormData";
    private readonly _confirmationRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _inviteUserButtonRef: React.RefObject<Button> = React.createRef();
    private readonly _inviteNewUserModal: React.RefObject<InviteNewUserModal> = React.createRef();

    /**
     * NOTICE: is null before {@link initializeAsync} has finished, but is never null afterwards.
     */
    private get order(): OrderDetails {
        return this.state.order!;
    }

    private get shoppingCartProducts(): ShoppingCartProductModel[] {
        return this.order.shoppingCart?.products ?? [];
    }

    public get inviteUserRequest(): InviteNewUserRequest | null {
        return this.state.inviteUserRequest;
    }

    /**
     * External id's of products in the Shopping Cart.
     */
    private get productExternalIds(): string[] {
        return this.shoppingCartProducts
            .flatMap(
                product =>
                    [product.product?.externalId].concat((product.product?.attachedProducts || []).map(attProduct => attProduct.externalId)))
            .filter(
                externalId =>
                    assert(externalId).isString.isNotEmpty.isNotWhitespace.getIsSuccess
            ) as string[];
    }

    /**
     * All rental products in the Shopping Cart.
     *
     * @private
     */
    private get rentalProducts(): ShoppingCartProductModel[] {
        return this.getShoppingCartProductsOfType(ProductType.Rental);
    }

    private get attachedRentalProducts(): ShoppingCartProductModel[] {
        return this.getAttachedProductsOfType(ProductType.Rental);
    }

    /**
     * All sales products in the shopping cart.
     */
    private get salesProducts(): ShoppingCartProductModel[] {
        return this.getShoppingCartProductsOfType(ProductType.Sales);
    }

    private get attachedSalesProducts(): ShoppingCartProductModel[] {
        return this.getAttachedProductsOfType(ProductType.Sales);
    }

    /**
     * Contract the (Renta) user is making an order for.
     */
    private get orderingForContract(): OrganizationContractModel | null {
        return this.state.orderingForContract;
    }

    private get constructionSitesUsers(): ContractConstructionSiteUserModel[] {
        return this.state.constructionSitesUsers;
    }

    /**
     * Mock Contract for private users.
     */
    private get privatePersonContract(): OrganizationContractModel {
        return {
            contractId: RentaEasyConstants.emptyGuid,
            isOrganizationContractModel: true,
        } as OrganizationContractModel;
    }

    /**
     * Mock Construction Site representing a new Construction Site.
     */
    private get newConstructionSite(): ConstructionSiteModel {
        return {
            id: RentaEasyConstants.emptyGuid,
            isConstructionSiteModel: true,
        } as ConstructionSiteModel;
    }

    /**
     * 'raw' Construction Sites without the {@link newConstructionSite}.
     */
    private get constructionSites(): ConstructionSiteModel[] {
        return this.state.constructionSites;
    }

    /**
     * Start date of the order.
     * @private
     */
    private get startDate(): Date | null {
        return ((this.order.startDate.isToday() || (this.order.startDate.inFuture())))
            ? this.order.startDate
            : null;
    }

    /**
     * Estimated end date of the order.
     */
    private get estimatedEndDate(): Date | null {
        return ((this.order.endDate.isToday() || (this.order.endDate.inFuture())))
            ? this.order.endDate
            : null;
    }

    /**
     * Products which are not in the Shopping Cart but are related to Products in the Shopping Cart.
     */
    private get relatedShoppingCartProducts(): ShoppingCartProductModel[] {

        return this
            .shoppingCartProducts
            .map(
                shoppingCartProduct =>
                    shoppingCartProduct.product)
            .filter(
                product =>
                    assert(product?.relatedProducts).isObject.isNotNull.isArray.isNotEmpty.getIsSuccess)
            .selectMany(
                product =>
                    product!.relatedProducts!)
            .filter(
                relatedProduct =>
                    !this
                        .shoppingCartProducts
                        .find(
                            shoppingCartProduct =>
                                shoppingCartProduct.product?.id === relatedProduct.id))
            .map(
                product => {
                    return {
                        product,
                        count: 0,
                    } as ShoppingCartProductModel;
                });
    }

    private get contractDiscounts(): OrganizationContractDiscounts | null {
        return this.state.contractDiscounts;
    }

    /**
     * Is the user a corporate customer with {@link newConstructionSite} currently selected.
     */
    private get creatingNewConstructionSite(): boolean {
        return (!this.isPrivateUser) && (this.state.creatingNewConstructionSite);
    }

    public get hasCompanyOrSiteRoles(): boolean {
        return this.userContext.user!.organizationRoles.length > 0
            || this.userContext.user!.constructionSiteRoles.length > 0;
    }

    private get isPrivateUser(): boolean {
        return (this.order?.constructionSiteOwnerId === this.privatePersonContract.contractId);
    }

    private get isOrderingForContract(): boolean {
        return (!!this.state.orderingForContract);
    }

    private get hasProducts(): boolean {
        return ((this.order?.shoppingCart?.products?.length ?? 0) > 0);
    }

    private get hasRentalProductsExcludingAttachedRentalProducts(): boolean {
        return (this.hasProducts) && (this.rentalProducts.length > 0);
    }

    private get hasRentalProducts(): boolean {
        return (this.hasProducts) && (this.hasRentalProductsExcludingAttachedRentalProducts || this.attachedRentalProducts.length > 0);
    }

    private get hasSalesProducts(): boolean {
        return (this.hasProducts) && (this.salesProducts.length > 0 || this.attachedSalesProducts.length > 0);
    }

    private get hasConstructionSiteExternalAddress(): boolean {
        return assert(this.order.constructionSiteExternalAddress).isString.isNotEmpty.isNotWhitespace.getIsSuccess;
    }

    private get isAddressRequired(): boolean {
        return (this.isPrivateUser) || (this.creatingNewConstructionSite);
    }

    /**
     * Get all Contracts the user has a role in.
     * TODO: assert has direct rent right in CS roles !!
     */
    private get contracts(): OrganizationContractModel[] {
        return (this.orderingForContract)
            ? [this.orderingForContract]
            : this
                .userContext
                .user!
                .organizationRoles
                .filter(role => role.contract?.level !== 0)
                .map(
                    role =>
                        role.contract!
                )
                .concat(
                    this
                        .userContext
                        .user!
                        .constructionSiteRoles
                        .map(
                            role =>
                                role.contract!
                        )
                );
    }

    // includes an ungrouped PrivatePerson if not making an order for a contract
    private get contractsSelectListItems(): SelectListItem[] {
        const items: SelectListItem[] = [];

        if (!this.isOrderingForContract) {
            const privatePersonItem = TransformProvider.toSelectListItem(this.privatePersonContract);
            privatePersonItem.text = Localizer.privatePerson;

            items.push(privatePersonItem);
        }

        if (this.contracts.length > 0) {
            const companiesGroup = new SelectListGroup();
            companiesGroup.name = Localizer.companies;

            this.contracts.forEach((contract: OrganizationContractModel) => {
                if (items.every(item => item.value !== contract.contractId)) {
                    const item = TransformProvider.toSelectListItem(contract);
                    item.group = companiesGroup;

                    items.push(item);
                }
            });

            const selectedConstructionSiteContract: OrganizationContractModel | null | undefined =
                this.userContext.user!.constructionSiteRoles
                    .find((role) => role.constructionSiteId === this.userContext.selectedConstructionSiteId)?.contract;
            const selectedContractId: string | null = this.userContext.selectedContractId;

            if (assert(selectedContractId).isString.isNotEmpty.isNotWhitespace.getIsSuccess
                || (assert(selectedConstructionSiteContract?.contractId).isString.isNotEmpty.isNotWhitespace.getIsSuccess)
                || (assert(selectedConstructionSiteContract?.parentContractId).isString.isNotEmpty.isNotWhitespace.getIsSuccess)) {

                const selectedItem: SelectListItem | undefined =
                    items.find(
                        (item) => {
                            const contractId: string = (item.ref as OrganizationContractModel).contractId;
                            return (assert(contractId).isString.isNotEmpty.isNotWhitespace.getIsSuccess
                                && (contractId === selectedContractId
                                    || contractId === selectedConstructionSiteContract?.contractId
                                    || contractId === selectedConstructionSiteContract?.parentContractId));
                        })
                    || items.find(
                        (item) => {
                            const parentContractId: string | null = (item.ref as OrganizationContractModel).parentContractId;
                            return (assert(parentContractId).isString.isNotEmpty.isNotWhitespace.getIsSuccess
                                && (parentContractId === selectedContractId
                                    || parentContractId === selectedConstructionSiteContract?.contractId
                                    || parentContractId === selectedConstructionSiteContract?.parentContractId));
                        });

                if (selectedItem) {
                    selectedItem.selected = true;
                }
            }
        }

        return items;
    }

    private get subContractsSelectListItems(): SelectListItem[] {
        const items: SelectListItem[] = [];

        if (this.state.parentContractId) {
            const contract = this.contracts.find((value, _, __) => value.contractId === this.state.parentContractId);

            if (contract?.children) {
                contract.children.forEach((subContract: OrganizationContractModel) => {
                    if (items.every(item => item.value !== subContract.contractId)) {
                        const item = TransformProvider.toSelectListItem(subContract);


                        items.push(item);

                    }
                });
            }
        }
        return items;
    }

    /**
     * Includes an ungrouped NewConstructionSite on the top.
     *
     * @private
     */
    private get constructionSitesSelectListItems(): SelectListItem[] {

        const items: SelectListItem[] = [];
        const isMainUser = this.isOrderingForContract || this.user.organizationRoles.some(org => org.contractId === this.state.contractId || org.contractId === this.state.parentContractId);

        if (isMainUser) {
            const newConstructionSiteItem: SelectListItem = TransformProvider.toSelectListItem(this.newConstructionSite);

            newConstructionSiteItem.text = Localizer.shoppingCartNewConstructionSite;

            items.push(newConstructionSiteItem);
        }

        if (this.constructionSites?.length > 0) {
            const userConstructionSiteIds = this.user.constructionSiteRoles.map(role => role.constructionSiteId);

            const openConstructionSites = this
                .constructionSites
                .filter(
                    constructionSite =>
                        constructionSite.isOpen && (isMainUser || userConstructionSiteIds.includes(constructionSite.id)));

            const closedConstructionSites = this
                .constructionSites
                .filter(
                    constructionSite =>
                        !constructionSite.isOpen && (isMainUser || userConstructionSiteIds.includes(constructionSite.id)));

            if (openConstructionSites.length > 0) {

                const openConstructionSitesGroup = new SelectListGroup();

                openConstructionSitesGroup.name = Localizer.constructionSitesActive;

                openConstructionSites
                    .forEach(
                        constructionSite => {

                            const item = TransformProvider.toSelectListItem(constructionSite);

                            item.group = openConstructionSitesGroup;

                            items.push(item);
                        });
            }

            if (closedConstructionSites.length > 0) {

                const closedConstructionSitesGroup = new SelectListGroup();

                closedConstructionSitesGroup.name = Localizer.constructionSitesNonActive;

                closedConstructionSites
                    .forEach(
                        constructionSite => {

                            const item = TransformProvider.toSelectListItem(constructionSite);

                            item.group = closedConstructionSitesGroup;

                            items.push(item);
                        });
            }

            // if there is only one construction site (the NewConstructionSite), it is automatically selected by default.
            // Otherwise select the one selected by the user in the front page or first open/closed one.

            if (items.length > 1) {

                const selectedConstructionSiteItem: SelectListItem = items
                        .find(
                            item =>
                                (item.ref as ConstructionSiteModel).id === this.userContext.selectedConstructionSiteId)
                    || items[1];

                selectedConstructionSiteItem.selected = true;
            }
        }

        return items;
    }

    private get rentalDepotSelectListItems(): SelectListItem[] {

        const items: SelectListItem[] = this
            .state
            .depots
            .filter(depot => (depot.orderEmails && depot.orderEmails.length > 0))
            .map(
                depot =>
                    TransformProvider.toSelectListItem(depot));

        if (this.userContext.user!.favoriteDepotId) {

            const favoriteItem: SelectListItem | undefined = items
                .find(
                    item =>
                        (item.ref as DepotModel).id === this.userContext.user!.favoriteDepotId);

            if (favoriteItem) {
                favoriteItem.selected = true;
            }
        }

        return items;
    }

    private get orderingForContractUsersSelectListItems(): SelectListItem[] {
        const items: SelectListItem[] = [];
        const invitedGroup = new SelectListGroup();
        invitedGroup.name = Localizer.contractDetailsInvited;
        this.orderingForContract?.users?.forEach(user => {
            if (!user.firstName || !user.lastName) {
                return;
            }

            const item = TransformProvider.toSelectListItem(user);
            if (user.hasPendingInvitation) {
                item.group = invitedGroup;
            }
            items.push(item);
        });
        this.constructionSitesUsers?.forEach(user => {
            if (!user.firstName || !user.lastName || user.constructionSiteId !== this.order?.constructionSiteId) {
                return;
            }

            const item = TransformProvider.toSelectListItem(user);
            if (user.hasPendingInvitation) {
                item.group = invitedGroup;
            }
            items.push(item);
        });

        return items;
    }

    private get vat(): number {
        return (this.isPrivateUser)
            ? (this.state.showNetPriceOnlyFlag ? 0 : PriceHelper.environmentVat)
            : 0;
    }

    private get privateUserNotStronglyAuthenticated(): boolean {
        return (this.isPrivateUser && !this.user.strongAuthBoundToUser);
    }

    protected get title(): string {
        return Localizer.breadCrumbShoppingCart;
    }

    /**
     * Assert that the inputs are valid for sending an order.
     * Sets an error-message if they are not.
     *
     * @return Are the inputs valid for sending an order.
     * @private
     */
    private async assertIsValid(): Promise<boolean> {

        if (!this.hasProducts) {
            throw new Error("Cannot validate if shopping cart is empty");
        }

        // need to have the function in a variable so 'this' can be used easily.
        const setError = async (message: string): Promise<boolean> => {
            await this.alertErrorAsync(message, true, true);
            return false;
        };

        if (this.orderingForContract
            && !assert(this.order.orderedBy).isString.isNotEmpty.isNotWhitespace.getIsSuccess
            && !assert(this.inviteUserRequest?.firstName).isString.isNotEmpty.isNotWhitespace.getIsSuccess
            && !assert(this.inviteUserRequest?.lastName).isString.isNotEmpty.isNotWhitespace.getIsSuccess
            && !assert(this.inviteUserRequest?.telephone).isString.isNotEmpty.isNotWhitespace.getIsSuccess) {

            return await setError(Localizer.contractDetailsFieldRequiredAssertionError);
        }

        if (this.creatingNewConstructionSite
            && !assert(this.order.constructionSiteName).isString.isNotEmpty.isNotWhitespace.getIsSuccess) {

            return await setError(Localizer.shoppingCartPageValidationConstructionSiteNameMissing);
        }

        if (this.isAddressRequired) {

            if (!assert(this.order.constructionSiteAddress).isString.isNotEmpty.isNotWhitespace.getIsSuccess) {
                return await setError(Localizer.shoppingCartPageValidationConstructionSiteAddressMissing);
            }

            if (!assert(this.order.constructionSitePostalCode).isString.isNotEmpty.isNotWhitespace.getIsSuccess) {
                return await setError(Localizer.shoppingCartPageValidationConstructionSitePostalCodeMissing);
            }

            if (!assert(this.order.constructionSiteCity).isString.isNotEmpty.isNotWhitespace.getIsSuccess) {
                return await setError(Localizer.shoppingCartPageValidationConstructionSiteCityMissing);
            }
        }

        if (this.hasRentalProducts) {

            if (!assert(this.startDate).isObject.isNotNull.getIsSuccess) {
                return await setError(Localizer.shoppingCartPageValidationStartDateMissing);
            }

            if (!assert(this.estimatedEndDate).isObject.isNotNull.getIsSuccess || (this.startDate! > this.estimatedEndDate!)) {
                return await setError(Localizer.shoppingCartPageValidationEndDateMissing);
            }
        }

        return true;
    }

    /**
     * Despite being stored internally as UTC, JavaScript Dates are ALWAYS considered to be in the browsers timezone,
     * which means that a Date with 00:00 Time component is almost NEVER 00:00 when deserialized as UTC.
     * This leads to the Day (and even Month or Year) component being different in the Back-end and the Front-end.
     * This method recreates the date in UTC by converting it first to yyyy-mm-dd.
     * @private
     */
    private static toUtcSafeDate(date: Date): Date {
        return new Date(Utility.toISODateString(date));
    }

    private getShoppingCartProductsOfType(productType: ProductType): ShoppingCartProductModel[] {

        return this
            .shoppingCartProducts
            .filter(
                product =>
                    product.product?.productType === productType);
    }

    private getAttachedProductsOfType(productType: ProductType): ShoppingCartProductModel[] {
        return this
            .shoppingCartProducts
            .flatMap(product => (product.product?.attachedProducts || []).map<ShoppingCartProductModel>(attProduct => ({
                product: attProduct,
                count: product.count * attProduct.quantity!,
                endDate: null,
                preferredModel:null,
                preferredModelName: null,
                relatedProducts: [],
            })))
            .filter(
                product =>
                    product.product!.productType === productType);
    }

    /*
     * setters
     */

    private async updateStateAsync(state: object): Promise<void> {
        this.setState(state);
        await this.reRenderAsync();
    }

    private async clearIsOrderingForContract() {
        this.setState({
            orderingForContract: null,
        });
    }

    private async setCreatingNewConstructionSite(creatingNewConstructionSite: boolean): Promise<void> {
        this.setState({
            creatingNewConstructionSite
        });
    }

    private async setContractAsync(contract: OrganizationContractModel): Promise<void> {
        if (this.state.parentContractId && contract.parentContractId && this.state.parentContractId !== contract.parentContractId) {
            this.setState({parentContractId: null});
        }

        if (this.order.constructionSiteOwnerId !== contract.contractId) {
            this.order.constructionSiteOwnerId = contract.contractId;

            if (contract.contractId === this.privatePersonContract.contractId) {
                await this.setConstructionSitesAsync([]);
                await this.setContractDiscountsAsync(null);
                await this.setConstructionSiteAddressAsync(this.userContext.user?.address ?? null);
                await this.setConstructionSiteCityAsync(this.userContext.user?.city ?? null);
                await this.setConstructionSitePostalCodeAsync(this.userContext.user?.postalCode ?? null);
                this.setState({contractId: null});

            }
            else {
                this.setState({contractId: contract.contractId});
                await this.setConstructionSiteAddressAsync(null);
                await this.setConstructionSiteCityAsync(null);
                await this.setConstructionSitePostalCodeAsync(null);

                const constructionSites: Promise<ConstructionSiteModel[]> = RentaEasyController.getContractsConstructionSitesAsync(contract.contractId, this);
                const contractDiscounts: Promise<OrganizationContractDiscounts> = RentaEasyController.getContractDiscounts(contract.contractId, this.productExternalIds, this);

                await this.setConstructionSitesAsync(await constructionSites);
                await this.setContractDiscountsAsync(await contractDiscounts);
            }

            await this.reRenderAsync();

            return;
        }

        if (this.state.orderingForContract?.contractId === contract.contractId) {
            const constructionSites: Promise<ConstructionSiteModel[]> = RentaEasyController.getContractsConstructionSitesAsync(contract.contractId, this);
            await this.setConstructionSitesAsync(await constructionSites);

            await this.reRenderAsync();
        }
    }

    private async setParentContractAsync(parentContract: OrganizationContractModel): Promise<void> {
        if (this.order.constructionSiteOwnerId !== parentContract.contractId) {
            this.order.constructionSiteOwnerId = null;
        }

        if (parentContract.contractId === this.privatePersonContract.contractId) {
            this.setState({parentContractId: null});
        } else {
            this.setState({parentContractId: parentContract.contractId});
        }
        await this.reRenderAsync();
    }

    private async handleCompanyChange(contract: OrganizationContractModel): Promise<void> {
        return contract.level < OrganizationContractLevel.SubCompany ? this.setParentContractAsync(contract) : this.setContractAsync(contract);
    }

    private async setOrderedByAsync(user: ContractUserModel | ContractConstructionSiteUserModel | null): Promise<void> {

        if (user) {
            this.order.orderedBy = user.userId;
        }
        else {
            this.order.orderedBy = null;
        }

        await this.reRenderAsync();
    }

    private async setConstructionSiteAsync(constructionSite: ConstructionSiteModel): Promise<void> {

        if ((this.isPrivateUser) || (this.order.constructionSiteId === constructionSite.id)) {
            return;
        }

        if (constructionSite.id === this.newConstructionSite.id) {
            await this.setCreatingNewConstructionSite(true);
        }
        else if (this.creatingNewConstructionSite) {
            await this.setCreatingNewConstructionSite(false);
        }

        await this.setConstructionSiteIdAsync(constructionSite.id);
        await this.setConstructionSiteNameAsync(constructionSite.name);
        await this.setConstructionSiteAddressAsync(constructionSite.address);
        await this.setConstructionSiteExternalAddressAsync(constructionSite.externalAddress);
        await this.setConstructionSiteCityAsync(constructionSite.city);
        await this.setConstructionSitePostalCodeAsync(constructionSite.postalCode);
        await this.setConstructionSiteExternalIdAsync(constructionSite.externalId);

        await this.reRenderAsync();
    }

    private async setConstructionSiteIdAsync(id: string): Promise<void> {
        this.order.constructionSiteId = id;
    }

    private async setConstructionSiteNameAsync(name: string | null): Promise<void> {
        this.order.constructionSiteName = name;
    }

    private async setConstructionSiteAddressAsync(address: string | null): Promise<void> {
        this.order.constructionSiteAddress = address;
    }

    private async setConstructionSiteExternalAddressAsync(externalAddress: string | null): Promise<void> {
        this.order.constructionSiteExternalAddress = externalAddress;
    }

    private async setConstructionSitePostalCodeAsync(postalCode: string | null): Promise<void> {
        this.order.constructionSitePostalCode = postalCode;
    }

    private async setConstructionSiteCityAsync(city: string | null): Promise<void> {
        this.order.constructionSiteCity = city;
    }

    private async setConstructionSiteExternalIdAsync(externalId: string | null): Promise<void> {
        this.order.constructionSiteExternalId = externalId;
    }

    private async setHasDiscounts(discountedProductExternalIds: string[]): Promise<void> {

        if (discountedProductExternalIds.length > 0) {
            this.order.discountedProductExternalIds = discountedProductExternalIds;
            this.order.hasDiscounts = true;
        }
        else {
            this.order.discountedProductExternalIds = [];
            this.order.hasDiscounts = false;
        }
    }

    private async onEstimatedRentalPeriodChange([start, end]: [Date | null, Date | null]) {
        this.setState({estimatedRentalPeriod: [start, end]}, () => this.setOrderStartEndDateAsync());
    }

    private async setOrderStartEndDateAsync(): Promise<void> {

        const [startDate, endDate]: [Date | null, Date | null] = this.state.estimatedRentalPeriod;

        this.order.startDate = (startDate)
            ? startDate.addMilliseconds(RentaEasyConstants.rentalDayStartHoursInMillis)
            : new Date(0);

        this.order.endDate = (endDate)
            ? endDate.addMilliseconds(RentaEasyConstants.rentalDayEndHoursInMillis)
            : new Date(0);

        this.shoppingCartProducts.forEach((product) => {
            if ((this.startDate) && (product.endDate) && (product.endDate < this.startDate)) {
                product.endDate = startDate;
            }
            else if ((this.estimatedEndDate) && (product.endDate) && (product.endDate > this.estimatedEndDate)) {
                product.endDate = endDate;
            }
        });

        await this.reRenderAsync();
    }

    private async setOrderTypeAsync(orderType: OrderType): Promise<void> {
        this.order.type = orderType;
        await this.reRenderAsync();
    }

    private async setOrderCommentAsync(comment: string): Promise<void> {
        this.order.comment = comment;
    }

    private async setDepotAsync(depotModel: DepotModel): Promise<void> {
        this.setState({
            unavailableProductsLoaded: RequestState.Sending
        });

        const products: string[] = await RentaEasyController.getUnavailableProductsIdsAsync(depotModel.id, this);

        this.order.depotId = depotModel.id;

        this.setState({
            unavailableProductIds: products,
            unavailableProductsLoaded: RequestState.Success
        });
    }

    private async setProductEstimatedReturnDateAsync(productId: string, returnDate: Date): Promise<void> {
        const product: ShoppingCartProductModel | undefined = this.shoppingCartProducts.find((product) => product.product?.id === productId);

        if (!product) {
            return;
        }

        product.endDate = ShoppingCart.toUtcSafeDate(returnDate);
    }

    private async setContractDiscountsAsync(contractDiscounts: OrganizationContractDiscounts | null): Promise<void> {

        this.state.contractDiscounts = contractDiscounts;

        await this.reRenderAsync();
    }

    private async setConstructionSitesAsync(constructionSites: ConstructionSiteModel[]): Promise<void> {

        this.state.constructionSites = constructionSites;

        await this.reRenderAsync();
    }

    private async onUpdateShoppingCart(productId: string, newCount: number, productName: string | null): Promise<void> {

        if (this.isSpinning()) {
            throw new Error("Cannot call onUpdateShoppingCart while another update is in process");
        }

        const shoppingCartProduct: ShoppingCartProductModel | undefined = this
            .shoppingCartProducts
            .find(
                shoppingCartProduct =>
                    shoppingCartProduct.product?.id === productId);

        const request: SaveProductToCartRequest = {
            productId: productId,
            productName: productName,
            count: newCount,
            preferredModel: shoppingCartProduct?.preferredModel ?? "",
            preferredModelName: shoppingCartProduct?.preferredModelName ?? null,
        };

        await RentaEasyController.saveProductToShoppingCartAsync(request, this);

        if (this.hasProducts) {

            const relatedShoppingCartProductProduct: ShoppingCartProductModel | undefined = this
                .relatedShoppingCartProducts
                .find(
                    relatedProduct =>
                        relatedProduct.product?.id === productId);

            if (!(shoppingCartProduct) && !(relatedShoppingCartProductProduct)) {
                return;
            }

            if (shoppingCartProduct) {

                shoppingCartProduct.count = newCount;

                if (shoppingCartProduct.count <= 0) {
                    this.shoppingCartProducts.remove(shoppingCartProduct);
                }

                await this.reRenderAsync();
            }
            else if (relatedShoppingCartProductProduct) {

                relatedShoppingCartProductProduct.count = newCount;

                this.shoppingCartProducts.push(relatedShoppingCartProductProduct);

                await this.reRenderAsync();
            }
        }
    }

    private async promptAuthentication(): Promise<boolean | void> {

        const result: DialogResult = await this.messageBoxAsync(
            Localizer.shoppingCartStrongAuthenticationRequired,
            "",
            {
                yesButton: Localizer.shoppingCartAuthenticate,
                cancelButton: Localizer.formCancel
            }
        );

        if (result === DialogResult.Cancel) {
            return false;
        }

        if (result === DialogResult.Yes) {
            //Save to local storage so we can restore the form data after authentication
            UserInteractionDataStorage.set(this._shoppingCartFormDataKey, this.order);
            await RentaEasyController.invokeStrongAuthentication("GetSignicatSsoLogin", this);
        }
    }

    public async confirmAsync(message: string): Promise<boolean> {
        const dialog: ConfirmationDialog = this._confirmationRef.current!;
        return await dialog.confirmAsync(message);
    }

    public async initializeAsync(): Promise<void> {

        await super.initializeAsync();

        //In case user completed strong authentication from this page
        //we will have data stored here
        const orderFromLocalstorage: OrderDetails = UserInteractionDataStorage.get(this._shoppingCartFormDataKey) as OrderDetails;

        if (orderFromLocalstorage) {
            orderFromLocalstorage.startDate = new Date(orderFromLocalstorage.startDate);
            orderFromLocalstorage.endDate = new Date(orderFromLocalstorage.endDate);
        }

        const depots: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        const orderingForContract: OrganizationContractModel | null = (this.userContext.temporaryContractIdForPrices)
            ? await RentaEasyController.getContractAsync(this.userContext.temporaryContractIdForPrices, this)
            : null;

        const constructionSitesUsers: ContractConstructionSiteUserModel[] | null = (this.userContext.temporaryContractIdForPrices)
            ? await RentaEasyController.getContractConstructionSitesUsersAsync(this.userContext.temporaryContractIdForPrices, this)
            : null;

        const shoppingCart: ShoppingCartModel = await RentaEasyController.getShoppingCartAsync(this);

        const constructionSiteOwnerId: string = this.userContext.temporaryContractIdForPrices ?? RentaEasyConstants.emptyGuid;

        const order: OrderDetails = orderFromLocalstorage ?? {
            constructionSiteOwnerId: constructionSiteOwnerId,
            shoppingCart,
            shoppingCartId: shoppingCart.id,
            startDate: new Date(0),
            endDate: new Date(0),
            type: OrderType.Pickup,
        } as OrderDetails;

        this.setState({
            order,
            orderingForContract,
            constructionSitesUsers: constructionSitesUsers ?? [],
            depots: depots,
        });

        //No need for this data anymore
        UserInteractionDataStorage.set(this._shoppingCartFormDataKey, null);

        if (this.isPrivateUser) {
            if (!orderFromLocalstorage){
                const user: UserModel = this.userContext.user!;

                await this.setConstructionSiteAddressAsync(user.address);
                await this.setConstructionSiteCityAsync(user.city);
                await this.setConstructionSitePostalCodeAsync(user.postalCode);
            }

            await this.reRenderAsync();

        }
        else if ((this.hasProducts)
            && (this.order.constructionSiteOwnerId)
            && (this.order.constructionSiteOwnerId !== this.privatePersonContract.contractId)) {

            const contractDiscounts: OrganizationContractDiscounts = await RentaEasyController.getContractDiscounts(this.order.constructionSiteOwnerId, this.productExternalIds, this);

            await this.setContractDiscountsAsync(contractDiscounts);
        }

        const showNetPriceOnlyFlag: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPriceNetOnly);
        const companyAccessRequiredFlag: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagShoppingCartCompanyAccessRequired);
        this.setState({showNetPriceOnlyFlag, companyAccessRequiredFlag})

        const showRequiresCompanyAccessMessage = companyAccessRequiredFlag && !this.hasCompanyOrSiteRoles;
        this.setState({showRequiresCompanyAccessMessage});
    }

    private async inviteNewUserAsync(request: InviteNewUserRequest): Promise<void> {
        if (this._inviteNewUserModal.current) {
            await this._inviteNewUserModal.current.closeAsync();
        }

        this.setState({inviteUserRequest: request});
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.shoppingCart} fullHeight>
                {
                    this.renderContractPricesAlert(async () => {
                        await this.clearIsOrderingForContract();
                    })
                }

                <PageHeader title={this.title}/>

                {
                    (!!this.order) && (this.userContext?.user) &&
                    (
                        (!this.hasProducts)
                            ?
                            (
                                <TwoColumns>
                                    <div className={styles.emptyShoppingCartContainer}>
                                        <Icon name={"shopping-cart"} size={IconSize.X10} />
                                    </div>
                                    <div className={styles.emptyShoppingCartContainer}>
                                        <ShoppingCartEmpty/>
                                    </div>
                                </TwoColumns>
                            )
                            :
                            (
                                <>
                                    <PageRow className={this.css(styles.flexFix)}>
                                        <TwoColumns className={this.css(styles.columnContainer)}>
                                            <>
                                                {this.state.showRequiresCompanyAccessMessage &&
                                                    <ShoppingCartRequiresCompanyAccess />
                                                }
                                                {!this.state.showRequiresCompanyAccessMessage &&
                                                    <Form id={"shopping_cart_form"}
                                                          className={this.css(styles.form)}
                                                    >
                                                        <Dropdown noWrap required
                                                                  id={"company-selection"}
                                                                  align={DropdownAlign.Left}
                                                                  label={`${Localizer.shoppingCartPageSelectCompany}`}
                                                                  items={this.contractsSelectListItems}
                                                                  orderBy={DropdownOrderBy.Value}
                                                                  onChange={async (_, item: any) => await this.handleCompanyChange(item)}
                                                                  disabled={this.isSpinning() || this.contractsSelectListItems.length < 2}
                                                        />

                                                        {
                                                            (!this.isPrivateUser && this.state.parentContractId) &&
                                                            <Dropdown noWrap required
                                                                      id={"subcompany-selection"}
                                                                      align={DropdownAlign.Left}
                                                                      label={`${Localizer.shoppingCartPageSelectSubCompany}`}
                                                                      items={this.subContractsSelectListItems}
                                                                      orderBy={DropdownOrderBy.Value}
                                                                      onChange={async (_, item: any) => await this.setContractAsync(item)}
                                                                      disabled={this.isSpinning() || this.subContractsSelectListItems.length === 0}
                                                            />
                                                        }

                                                        {
                                                            (this.isOrderingForContract) &&
                                                            (
                                                                <>
                                                                    {
                                                                        ((this.orderingForContract!.users?.filter(user => user.firstName && user.lastName).length ?? 0) > 0 ||
                                                                            (this.constructionSitesUsers?.filter(user => user.firstName && user.lastName &&
                                                                                user.constructionSiteId === this.order.constructionSiteId).length ?? 0) > 0) &&
                                                                        (
                                                                            // If a Renta sales-person is ordering for a customer and they have Easy users, then one can be selected from a dropdown.
                                                                            // The ID of the user populates the Order field OrderedBy (typeof nullable Guid).
                                                                            // If a user is selected from the dropdown then the freetext field will be hidden.

                                                                            <Dropdown required={!this.inviteUserRequest?.firstName && !this.inviteUserRequest?.lastName && !this.inviteUserRequest?.telephone}
                                                                                      onValidationError={() => Localizer.contractDetailsFieldRequiredError}
                                                                                      requiredType={DropdownRequiredType.Manual}
                                                                                      label={Localizer.contractDetailsMakeOrderAsCustomer}
                                                                                      items={this.orderingForContractUsersSelectListItems}
                                                                                      disabled={this.isSpinning() || !!this.inviteUserRequest?.firstName}
                                                                                      onChange={async (_, item: any) => await this.setOrderedByAsync(item)}
                                                                            />
                                                                        )
                                                                    }
                                                                    {
                                                                        // Shows the invited user info.
                                                                        (this.inviteUserRequest?.firstName && this.inviteUserRequest?.lastName && this.inviteUserRequest?.telephone) &&

                                                                        <div>
                                                                            <div>
                                                                                <p className={this.css("base-input-label", "d-flex")}>
                                                                                    {Localizer.contractDetailsMakeOrderAsCustomer}
                                                                                </p>
                                                                                <p className={styles.label}>
                                                                                    {this.inviteUserRequest?.firstName} {this.inviteUserRequest?.lastName} {this.inviteUserRequest?.telephone}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !(this.inviteUserRequest?.firstName && this.inviteUserRequest?.lastName && this.inviteUserRequest?.telephone) &&
                                                                        // Renta sales-person can invite user if the user is not available.
                                                                        // The name and phone of the user populates the Return field ReturnedOnBehalfFreeText on server side.
                                                                        <div>

                                                                            <div id="PricingPeriodInfoLbl" className={this.css("base-input-label", "d-flex")}>
                                                                                <label>
                                                                                    {Localizer.inviteUserInfoText}
                                                                                </label>
                                                                            </div>

                                                                            <Button ref={this._inviteUserButtonRef}
                                                                                    label={Localizer.inviteUserContactPerson}
                                                                                    type={ButtonType.Orange}
                                                                                    disabled={this.isSpinning() || !!this.order.orderedBy}
                                                                                    className={styles.button}
                                                                                    icon={{name: "circle-plus"}}
                                                                                    onClick={async () => await this._inviteNewUserModal.current?.openAsync()}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {
                                                                        (this.orderingForContract?.contractId) &&
                                                                        <InviteNewUserModal ref={this._inviteNewUserModal}
                                                                                            inviteNewUserAsync={async  (request) => await this.inviteNewUserAsync(request)}
                                                                                            contractId={this.orderingForContract.contractId}
                                                                        />
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            (!this.isPrivateUser) &&
                                                            (
                                                                <>
                                                                    <Dropdown noWrap required
                                                                              align={DropdownAlign.Left}
                                                                              label={`${Localizer.selectCompanyPageSelectConstruction}`}
                                                                              items={this.constructionSitesSelectListItems}
                                                                              orderBy={DropdownOrderBy.Value}
                                                                              // Amount of items we will display in the dropdown before we only display "type something to get results"
                                                                              filterMaxLength={2000}
                                                                              onChange={async (_, item: any) => await this.setConstructionSiteAsync(item)}
                                                                              disabled={this.isSpinning()}
                                                                    />

                                                                    {
                                                                        (this.creatingNewConstructionSite) &&
                                                                        (
                                                                            <TextInput required
                                                                                       label={Localizer.shoppingCartPageConstructionSiteName}
                                                                                       value={this.order.constructionSiteName ?? undefined}
                                                                                       onChange={async (_, value: string) => await this.setConstructionSiteNameAsync(value)}
                                                                            />
                                                                        )
                                                                    }

                                                                    {
                                                                        (!this.creatingNewConstructionSite) &&
                                                                        (
                                                                            <TextInput readonly
                                                                                       label={Localizer.shoppingCartPageConstrutionSiteExternalId}
                                                                                       value={this.order.constructionSiteExternalId ?? undefined}
                                                                            />
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            (this.hasConstructionSiteExternalAddress) &&
                                                            (
                                                                <TextInput readonly
                                                                           label={Localizer.genericAddress}
                                                                           value={this.order.constructionSiteExternalAddress ?? undefined}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (!this.hasConstructionSiteExternalAddress) &&
                                                            (
                                                                <>
                                                                    <TextInput id={"shopping_cart_input_address"}
                                                                               required={this.isAddressRequired}
                                                                               label={Localizer.genericAddress}
                                                                               value={this.order.constructionSiteAddress ?? undefined}
                                                                               onChange={async (_, value: string) => await this.setConstructionSiteAddressAsync(value)}
                                                                    />

                                                                    <TextInput id={"shopping_cart_input_postalCode"}
                                                                               required={this.isAddressRequired}
                                                                               label={Localizer.genericPostalCode}
                                                                               value={this.order.constructionSitePostalCode ?? undefined}
                                                                               onChange={async (_, value: string) => await this.setConstructionSitePostalCodeAsync(value)}
                                                                    />

                                                                    <TextInput id={"shopping_cart_input_city"}
                                                                               required={this.isAddressRequired}
                                                                               label={Localizer.genericCity}
                                                                               value={this.order.constructionSiteCity ?? undefined}
                                                                               onChange={async (_, value: string) => await this.setConstructionSiteCityAsync(value)}
                                                                    />
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            (this.order.type === OrderType.Delivery) &&
                                                            (
                                                                <i className={this.css(styles.deliveryPointer, "fa", "fa-exclamation-circle")}
                                                                >

                                                                    <span className={styles.deliveryInfoText}>
                                                                        {Localizer.shoppingCartPageShippingCharges}
                                                                    </span>
                                                                </i>
                                                            )
                                                        }

                                                        <Dropdown noWrap required
                                                                  id={"shopping_cart_dropdown_delivery_type"}
                                                                  align={DropdownAlign.Left}
                                                                  label={Localizer.shoppingCartPageDeliveryType}
                                                                  items={EnumProvider.getOrderTypeItems()}
                                                                  orderBy={DropdownOrderBy.Value}
                                                                  requiredType={DropdownRequiredType.Manual}
                                                                  onChange={async (_, item: SelectListItem | null) => await this.setOrderTypeAsync(Number.parseInt(item!.value))}
                                                        />

                                                        <Dropdown noWrap required
                                                                  align={DropdownAlign.Left}
                                                                  label={Localizer.shoppingCartPageLocation}
                                                                  items={this.rentalDepotSelectListItems}
                                                                  orderBy={DropdownOrderBy.Name}
                                                            // do not await so the page is not blocked by the loading
                                                                  onChange={(_, item: any) => this.setDepotAsync(item)}
                                                                  disabled={this.isSpinning()}
                                                        />

                                                        {
                                                            (this.hasRentalProducts) &&
                                                            (
                                                                <DateRangeInput required sameDay
                                                                                id={"shopping_cart_input_from_to"}
                                                                                disabledDays={[WeekDaysEnum.Saturday, WeekDaysEnum.Sunday]}
                                                                                label={Localizer.shoppingCartPageOrderEstimatedRentalPeriod}
                                                                                model={{value: [this.startDate, this.estimatedEndDate]}}
                                                                                minDate={new Date()}
                                                                                onChange={async (value: [Date | null, Date | null]) => await this.onEstimatedRentalPeriodChange(value)}
                                                                                disabledDaysHint={Localizer.shoppingCartPageCannotRentOrReturnOnAWeekend}
                                                                />
                                                            )
                                                        }

                                                        <TextAreaInput id={"shopping_cart_input_comment"}
                                                                       maxLength={RentaEasyConstants.shoppingCartCommentMaxLength}
                                                                       placeholder={Localizer.shoppingCartPageComment}
                                                                       value={this.order.comment ?? undefined}
                                                                       onChange={async (_, value) => await this.setOrderCommentAsync(value)}
                                                        />

                                                        <ShoppingCartSummary
                                                            rentalProducts={this.rentalProducts}
                                                            salesProducts={this.salesProducts}
                                                            attachedRentalProducts={this.attachedRentalProducts}
                                                            attachedSalesProducts={this.attachedSalesProducts}
                                                            hasRentalProducts={this.hasRentalProducts}
                                                            hasSalesProducts={this.hasSalesProducts}
                                                            contractDiscounts={this.state.contractDiscounts}
                                                            vat={this.vat}
                                                        />

                                                        <p>
                                                            <small>
                                                                {Localizer.shoppingCartPageOrderProcessInfo}
                                                            </small>
                                                        </p>

                                                        <p>
                                                            <small>
                                                                {Localizer.shoppingCartPageAdditionalCharges}
                                                            </small>
                                                        </p>

                                                        <ShoppingCartFormButtons
                                                            isSpinning={this.isSpinning()}
                                                            assertIsValid={async () => await this.assertIsValid()}
                                                            order={this.order}
                                                            setHasDiscounts={async (discountedProductExternalIds) => await this.setHasDiscounts(discountedProductExternalIds)}
                                                            userContext={this.userContext}
                                                            inviteUserRequest={this.state.inviteUserRequest}
                                                            contractId={this.state.contractId}
                                                            contractDiscounts={this.state.contractDiscounts}
                                                            updateState={this.updateStateAsync}
                                                            unavailableProductIds={this.state.unavailableProductIds}
                                                            shoppingCartProducts={this.shoppingCartProducts}
                                                            privateUserNotStronglyAuthenticated={this.privateUserNotStronglyAuthenticated}
                                                            promptAuthentication={async () => await this.promptAuthentication()}
                                                            confirmAsync={async (message) => await this.confirmAsync(message)}
                                                        />
                                                    </Form>
                                                }
                                            </>

                                            <div className={this.css(styles.columnRight)}>
                                                <ShoppingCartProducts
                                                    isSpinning={this.isSpinning()}
                                                    rentalProducts={this.rentalProducts}
                                                    salesProducts={this.salesProducts}
                                                    attachedRentalProducts={this.attachedRentalProducts}
                                                    attachedSalesProducts={this.attachedSalesProducts}
                                                    contractDiscounts={this.state.contractDiscounts}
                                                    unavailableProductsLoaded={this.state.unavailableProductsLoaded}
                                                    unavailableProductIds={this.state.unavailableProductIds}
                                                    hasProducts={this.hasProducts}
                                                    hasRentalProductsExcludingAttachedRentalProducts={this.hasRentalProductsExcludingAttachedRentalProducts}
                                                    hasRentalProducts={this.hasRentalProducts}
                                                    hasSalesProducts={this.hasSalesProducts}
                                                    onUpdateProductCount={(productId, newCount, productName) => this.onUpdateShoppingCart(productId, newCount, productName)}
                                                    vat={this.vat}
                                                    startDate={this.startDate}
                                                    estimatedEndDate={this.estimatedEndDate}
                                                    setProductEstimatedReturnDateAsync={this.setProductEstimatedReturnDateAsync}
                                                />
                                            </div>
                                        </TwoColumns>
                                    </PageRow>

                                    {
                                        (this.relatedShoppingCartProducts.length > 0) && !this.state.showRequiresCompanyAccessMessage &&
                                        (
                                                <div className={styles.relatedProducts}>
                                                    <h3>
                                                        {Localizer.productDetailsAccessories}
                                                    </h3>

                                                    <div className={styles.productAccessoriesWrapper}>
                                                        <ProductAccessories navigation={CarouselNavigation.Outside}
                                                            readonly={this.isSpinning()}
                                                            onUpdateProductCount={async (productId, newCount, productName) => await this.onUpdateShoppingCart(productId, newCount, productName)}
                                                            relatedProducts={this.relatedShoppingCartProducts}
                                                            vat={this.vat} />
                                                    </div>
                                                </div>
                                        )
                                    }
                                </>
                            )
                    )
                }

                <ConfirmationDialog id={"shopping_cart_confirmDialog"}
                                    ref={this._confirmationRef}
                                    dialogContentClassName={styles.dialogContent}
                />
            </PageContainer>
        );
    }
}