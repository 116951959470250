import React, {Suspense} from "react";
import {Button, ButtonType, Dropdown, DropdownRequiredType, IconSize, Modal, PageContainer, Spinner, TextInput} from "@renta-apps/athenaeum-react-components";
import {AlertModel, BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import EnvironmentFloorPlan from "@/models/server/EnvironmentFloorPlan";
import GetEnvironmentSiteDetailsResponse from "@/models/server/Responses/GetEnvironmentSiteDetailsResponse";
import EnvironmentDeviceDetails from "@/models/server/EnvironmentDeviceDetails";
import EnvironmentSensorChart from "@/components/EnvironmentSensorChart/EnvironmentSensorChart";
import GetEnvironmentDeviceDetailsRequest from "@/models/server/Requests/GetEnvironmentDeviceDetailsRequest";
import EnvironmentSensor from "@/models/server/EnvironmentSensor";
import GetEnvironmentDeviceDetailsResponse from "@/models/server/Responses/GetEnvironmentDeviceDetailsResponse";
import EnvironmentSensorDetails from "@/models/server/EnvironmentSensorDetails";
import Localizer from "@/localization/Localizer";

import styles from "./EnvironmentControl.module.scss";
import EnvironmentDevice from "@/models/server/EnvironmentDevice";
import LoadingOrDataNotFound from "@/components/LoadingOrDataNotFound/LoadingOrDataNotFound";
import RentaEasyController from "@/pages/RentaEasyController";
import ExportEnvironmentDevicesDetailsRequest from "@/models/server/Requests/ExportEnvironmentDevicesDetailsRequest";
import EnvironmentControlSensorValues from "@/components/EnvironmentControl/EnvironmentControlSensorValues";
import ButtonWithSpinner from "@/components/ButtonWithSpinner/ButtonWithSpinner";

interface IEnvironmentControlProps {
    constructionSiteId: string,
}

interface IEnvironmentControlState {
    constructionSiteId: string;

    isLoading: boolean;

    alertModel: AlertModel | null;

    floorPlans: EnvironmentFloorPlan[];

    devices: EnvironmentDevice[];

    externalId: string | null;

    fromLimit: Date | null;

    toLimit: Date | null;

    exportInProgress: boolean;

    selectedFloorPlan: EnvironmentFloorPlan | null;

    deviceDetailsToShow: EnvironmentDeviceDetails | null;

    sensorDetailsToShow: EnvironmentSensorDetails | null;

    sensorsDetailsToShow: EnvironmentSensorDetails[];

    shareInProgress: boolean;

    shareUrl: string | null;

    environmentName: string | null;
}

class EnvironmentControl extends BaseComponent<IEnvironmentControlProps, IEnvironmentControlState> {
    public state: IEnvironmentControlState = {
        exportInProgress: false,
        externalId: null,
        constructionSiteId: "",
        isLoading: true,
        alertModel: null,
        floorPlans: [],
        devices: [],
        sensorsDetailsToShow: [],
        selectedFloorPlan: null,
        deviceDetailsToShow: null,
        sensorDetailsToShow: null,
        fromLimit: null,
        toLimit: null,
        shareInProgress: false,
        shareUrl: null,
        environmentName: null,
    };

    private modalRef: React.RefObject<Modal> = React.createRef();

    private transFormUnit(cellValue: string): string {
        return EnvironmentSensor.transFormUnit(cellValue);
    }

    // Getters
    public get deviceDetailsToShow(): EnvironmentDeviceDetails | null {
        return this.state.deviceDetailsToShow;
    }

    public get sensorsDetailsToShow(): EnvironmentSensorDetails[] {
        return this.state.sensorsDetailsToShow;
    }

    public get selectedFloorPlan(): EnvironmentFloorPlan | null {
        return this.state.selectedFloorPlan;
    }

    public get floorPlans(): EnvironmentFloorPlan[] {
        return this.state.floorPlans;
    }

    public get selectedDevices(): EnvironmentDevice[] {
        return this.state.selectedFloorPlan?.floorPlanDevices ?? this.state.devices;
    }

    // Async-methods
    private async loadSensorsDataAsync(deviceId: string, sensorIds: number[], from: Date, to: Date): Promise<void> {
        //Verify we always fetch from start of the day
        from.setHours(0, 0, 0, 0);

        if (new Date() > to) {
            to.setHours(23, 59, 0, 0);
        }

        const limitedFrom: Date = (this.state.fromLimit) ?
            (this.state.fromLimit > from) ? this.state.fromLimit
                : from
            : from;

        const limitedTo: Date = (this.state.toLimit) ?
            (to > this.state.toLimit) ? this.state.toLimit
                : to
            : to;
        const request: GetEnvironmentDeviceDetailsRequest = {
            to: limitedTo,
            from: limitedFrom,
            sensorsExternalId: sensorIds,
            constructionSiteId: this.props.constructionSiteId,
            deviceExternalId: deviceId
        };

        const deviceData: GetEnvironmentDeviceDetailsResponse = await this.postAsync("/api/EnvironmentControl/GetDeviceData", request);

        const deviceDetailToShow: EnvironmentDeviceDetails = deviceData.environmentDeviceDetails.find(item => item.id === deviceId)!;

        if (!deviceDetailToShow) {
            await ch.alertWarningAsync("Data unavailable", true, true);
            return;
        }

        if (this.state.deviceDetailsToShow?.id === deviceDetailToShow.id && sensorIds.length === 1) {
            //Find and update
            let sensorsStateCopy = this.state.sensorsDetailsToShow;

            let sensorDataFromApi = deviceDetailToShow.sensorDetails.filter(item => item.sensorId === sensorIds[0])[0];

            let index = sensorsStateCopy.findIndex(item => item.sensorId === sensorDataFromApi.sensorId);
            let match: EnvironmentSensorDetails | null = sensorsStateCopy.filter(item => item.sensorId === sensorDataFromApi.sensorId)[0];

            if (match) {
                sensorsStateCopy[index] = {
                    ...sensorsStateCopy[index],
                    sensorValues: sensorDataFromApi.sensorValues,
                    multiValueSensor: sensorDataFromApi.multiValueSensor,
                };

                this.setState({sensorsDetailsToShow: sensorsStateCopy});

                return;
            }

            return;
        }

        await this.setState({deviceDetailsToShow: deviceDetailToShow, sensorsDetailsToShow: deviceDetailToShow.sensorDetails});
    }

    public async loadDataAsync(): Promise<void> {
        const response: GetEnvironmentSiteDetailsResponse = await this.postAsync("/api/EnvironmentControl/GetSiteData", this.props.constructionSiteId);

        if (!response) {
            return;
        }

        this.setState({
            floorPlans: response.floorPlans,
            selectedFloorPlan: response.floorPlans.length ? response.floorPlans[0] : null,
            devices: response.floorPlans.length ? [] : response.devices ?? [],
            externalId: response.externalId,
            fromLimit: response.fromLimit,
            toLimit: response.toLimit,
            exportInProgress: response.exportInProgress,
            environmentName: response.name,
        });
    }

    private async changeFloorPlanAsync(item: EnvironmentFloorPlan): Promise<void> {
        await this.setState({selectedFloorPlan: item});
    }

    public async initializeAsync(): Promise<void> {
        await this.loadDataAsync();
        await this.setState({isLoading: false});
    }

    private async returnBackAsync(): Promise<void> {
        await this.setState({deviceDetailsToShow: null, sensorDetailsToShow: null});
    }

    private redirectToRentaPage(): Promise<void> {
        if (ch.isFinland) {
            window.location.href = "https://www.renta.fi/palvelut/olosuhdehallinta/";
        }
        return Promise.resolve();
    }

    private async exportAlreadyInProgressMessage(): Promise<void> {
        await ch.messageBoxAsync(Localizer.environmentControlExportAlreadyInProgress);
    }

    private async exportEmissionDataAsync(): Promise<void> {
        const request: ExportEnvironmentDevicesDetailsRequest = {
            environmentControlId: this.state.externalId ?? "",
            constructionSiteId: this.props.constructionSiteId,
            deviceExternalId: this.state.floorPlans
                .flatMap(item => item.floorPlanDevices
                    .where(item => item.id !== null)
                    .map(device => device.id!))
        };

        await this.setState({exportInProgress: true});
        await this.postAsync("/api/EnvironmentControl/ExportSiteData", request);
    }

    private async shareViewAsync(): Promise<void> {
        if (!this.selectedFloorPlan?.id && !this.state.devices.length) {
            return;
        }

        this.setState({shareInProgress: true});
        const request = {
            floorPlanId: this.selectedFloorPlan?.id,
            environmentControlId: this.state.externalId ?? "",
            constructionSiteId: this.props.constructionSiteId,
        };

        const url = await this.postAsync<string>("/api/EnvironmentControl/ShareFloorPlan", request);
        this.setState({shareInProgress: false, shareUrl: `${window.location.origin}/infoscreen/${url}`});
        this.modalRef.current?.openAsync();
    }

    render(): React.ReactNode {
        return (
            <PageContainer className={styles.environmentControl}>
                {
                    (this.state.isLoading) && (
                        <div className={styles.loaderContainer}>
                            <LoadingOrDataNotFound isLoading={this.state.isLoading} noDataText={""}/>
                        </div>
                    )
                }
                {
                    (!this.state.isLoading && !this.deviceDetailsToShow && (!this.selectedFloorPlan && !this.state.devices.length)) && (
                        <div className={this.css("row", styles.bannerContainer)}>
                            <div className={"col-md-6"}>
                                <img className={styles.bannerImage}
                                     src={"/images/IOT-map.png"}
                                     alt={""}
                                />
                            </div>
                            <div className={this.css("col-md-6", styles.textContainer)}>
                                <div className={styles.header}>
                                    {Localizer.environmentControlBannerHeader}
                                </div>
                                <div className={styles.content}>
                                    {Localizer.environmentControlBannerContent}

                                </div>
                                <div className={styles.button}>
                                    <Button label={Localizer.environmentControlBannerButton.toUpperCase()} type={ButtonType.Orange}
                                            onClick={() => this.redirectToRentaPage()}/>
                                </div>

                            </div>
                        </div>
                    )
                }
                {
                    (!this.deviceDetailsToShow && (this.selectedFloorPlan || !!this.state.devices.length)) && (
                        <div>
                            <div className={styles.topPanel}>
                                {(!!this.floorPlans.length) ? (
                                    <Dropdown items={this.floorPlans}
                                              label="Floorplan"
                                              className={styles.floorPlanDropdown}
                                              selectedItem={this.selectedFloorPlan}
                                              onChange={async (_, item: EnvironmentFloorPlan) => await this.changeFloorPlanAsync(item)}
                                              requiredType={DropdownRequiredType.Restricted}
                                              required
                                    />
                                ) : <div className={styles.environmentName}>{this.state.environmentName}</div>}
                                <ButtonWithSpinner type={ButtonType.Orange}
                                                   label="Share view"
                                                   onClick={async () => await this.shareViewAsync()}
                                                   spinning={this.state.shareInProgress}
                                />
                            </div>

                            <EnvironmentControlSensorValues floorPlan={this.selectedFloorPlan}
                                                            devices={this.state.devices}
                                                            onSensorClick={async (id, sensors: number[]) => await this.loadSensorsDataAsync(id, sensors, new Date(), new Date())}
                            />

                            {
                                RentaEasyController.isEasyPlusUser && (
                                    <>
                                        {
                                            !this.state.exportInProgress && (
                                                <Button id={"downloadExcelButton"}
                                                        label={Localizer.emissionsDownload}
                                                        icon={{name: "fa fa-download", size: IconSize.Large}}
                                                        confirm={Localizer.environmentControlExportConfirmation}
                                                        type={ButtonType.Orange}
                                                        onClick={async () => await this.exportEmissionDataAsync()}
                                                />
                                            )

                                        }
                                        {
                                            this.state.exportInProgress && (
                                                <Button id={"downloadExcelButton"}
                                                        label={Localizer.emissionsDownload}
                                                        icon={{name: "fa fa-download", size: IconSize.Large}}
                                                        type={ButtonType.Orange}
                                                        onClick={async () => this.exportAlreadyInProgressMessage()}
                                                />
                                            )

                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }
                {
                    (this.deviceDetailsToShow && this.sensorsDetailsToShow.length > 0) && (
                        <Suspense fallback={<Spinner />}>
                            <div className={"container"}>
                                <Button label={Localizer.environmentControlBack.toUpperCase()}
                                        className={styles.backButton}
                                        id={"temperatureChart_Back_Button"}
                                        type={ButtonType.Orange}
                                        icon={{name: "far chevron-left"}}
                                        onClick={async () => await this.returnBackAsync()}
                                />

                                {
                                    (this.sensorsDetailsToShow.map(item =>
                                        <div className={styles.sensorContainer} key={`sensor_container_${item.sensorId}`}>
                                            <EnvironmentSensorChart sensorName={this.deviceDetailsToShow!.name}
                                                                    key={`sensors_${item.sensorId}`}
                                                                    unit={this.selectUnitToShow(item.sensorId)}
                                                                    deviceId={this.deviceDetailsToShow!.id}
                                                                    sensorId={item.sensorId}
                                                                    fromLimit={this.state.fromLimit}
                                                                    toLimit={this.state.toLimit}
                                                                    sensorData={item.sensorValues}
                                                                    multiValueSensor={item.multiValueSensor}
                                                                    onDateRangeChangeAsync={async (deviceId, sensorId, from, to) => await this.loadSensorsDataAsync(deviceId, [sensorId], from, to)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </Suspense>
                    )
                }
                {/* Temporary modal, will be changed when designs are ready */}
                <Modal ref={this.modalRef}
                       info
                       preventClosingOnInsideClick
                       title="Floorplan view URL"
                >
                    <div className={styles.floorPlanUrl}>
                        <TextInput readonly value={this.state.shareUrl ?? ""} />
                        <Button type={ButtonType.Light}
                                icon={{name: "far copy"}}
                                onClick={async () => navigator.clipboard.writeText(this.state.shareUrl ?? "")}
                                title="Copy to clipboard"
                        />
                    </div>
                </Modal>
            </PageContainer>
        );
    }

    private selectUnitToShow(sensorId: number): string {
        if (!this.deviceDetailsToShow) {
            return "";
        }

        const selectedDevice: EnvironmentDevice = this.selectedDevices.filter(a => a.id === this.deviceDetailsToShow!.id)[0];

        const selectedSensor: EnvironmentSensor = selectedDevice.sensors.filter(a => a.id === sensorId)[0];

        if (!selectedSensor.unitOfMeasurement) {
            return "";
        }

        return this.transFormUnit(selectedSensor.unitOfMeasurement);

    }
}

export default EnvironmentControl;