import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, Icon, IconSize, INumberInputModel, Link, NumberInput, NumberInputBehaviour} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {DailyRentalType, RentType, ServiceType} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import PriceHelper from "@/helpers/PriceHelper";
import Localizer from "@/localization/Localizer";
import EquipmentGroup from "@/pages/ConstructionSiteDetails/EquipmentGroup/EquipmentGroup";
import ConstructionSiteReturn from "@/models/server/ConstructionSiteReturn";
import PageDefinitions from "@/providers/PageDefinitions";
import EasyEquipment from "@/models/server/EasyEquipment";

import styles from "./Equipment.module.scss";

interface IEquipmentProps {
    equipment: EasyEquipment;
    showPrices: boolean;
    canOrderServices: boolean;
    canReturn: boolean;
    toggler?: boolean | React.ReactNode;
    expanded?: boolean;

    createServiceRequest?(serviceType: ServiceType, location: number | null, deviceName: string, deviceInventoryNumber: string): Promise<void>;

    onAmountChange(model: ConstructionSiteReturn): Promise<void>;
}

interface IEquipmentState {
    expanded: boolean;

    isReturningItem: boolean;

}

export default class Equipment extends BaseComponent<IEquipmentProps, IEquipmentState> {

    // Fields

    public state: IEquipmentState = {
        expanded: !!this.props.expanded,
        isReturningItem: false,
    };
    private _amount: INumberInputModel = {value: 0};

    // Properties

    private get equipment(): EasyEquipment {
        return this.props.equipment;
    }

    private get equipmentGroup(): EasyEquipment[] {
        return [this.equipment, ...(this.equipment.equipmentGroup ?? [])];
    }

    private get canSeeEquipmentControls(): boolean {
        return (this.props.canOrderServices) && (!this.equipment.isReturned);
    }

    private get canSelectCharging(): boolean {
        return this.userContext.country !== "nor";
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public get collapsed(): boolean {
        return !this.state.expanded;
    }

    public get hasToggle(): boolean {
        return !!this.props.toggler;
    }

    public get showPrices(): boolean {
        return this.props.showPrices;
    }

    public get hasBothPrices(): boolean {
        return this.equipmentGroup.some(a => a.rentType !== this.equipment.rentType);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private async setExpanded(expanded: boolean): Promise<void> {
        if (expanded !== this.expanded) {
            await this.setState({expanded: expanded});
        }
    }

    private async createServiceRequestAsync(serviceType: ServiceType): Promise<void> {
        if (this.props.createServiceRequest) {
            await this.props.createServiceRequest(serviceType, this.equipment.location, this.equipment.name, this.equipment.inventoryItemDisplayNumber);
        }
    }

    private async onAmountChangeHandlerAsync(
        value: number,
        name: string,
        inventoryItemId: string,
        rentaId: string,
        contractNumber: string,
        rentalObjectNumber: string,
        rentalObjectId: string,
        depotExternalId: number | null,
        inventoryItemDisplayNumber: string
    ): Promise<void> {
        const model: ConstructionSiteReturn = new ConstructionSiteReturn();
        model.name = name;
        model.count = value;
        model.contractNumber = contractNumber;
        model.inventoryItemId = inventoryItemId;
        model.rentaId = rentaId
        model.rentalObjectId = rentalObjectId;
        model.inventoryItemDisplayNumber = inventoryItemDisplayNumber;
        model.rentalObjectNumber = rentalObjectNumber;
        model.depotExternalId = depotExternalId ?? 0;

        if (this.props.onAmountChange) {
            await this.props.onAmountChange(model);
        }

        if (value === 0) {
            this.setState({isReturningItem: false});
        }
    }

    private async onReturnItemClick(name: string,
                                    inventoryItemId: string,
                                    rentaId: string,
                                    contractNumber: string,
                                    rentalObjectNumber: string,
                                    depotExternalId: number | null,
                                    inventoryItemDisplayNumber: string): Promise<void> {

        const model: ConstructionSiteReturn = new ConstructionSiteReturn();
        model.name = name;
        model.count = 1;
        model.contractNumber = contractNumber;
        model.inventoryItemId = inventoryItemId;
        model.rentalObjectNumber = rentalObjectNumber;
        model.depotExternalId = depotExternalId ?? 0;
        model.inventoryItemDisplayNumber = inventoryItemDisplayNumber;
        model.rentaId = rentaId;

        this.setState({isReturningItem: true});
        this._amount.value += 1;

        if (this.props.onAmountChange) {
            await this.props.onAmountChange(model);
        }

    }

    private renderHeader(): React.ReactNode {

        return (
            <div className={styles.infoContainer} data-cy="equipment-item-container">
                {
                    this.renderProductName(this.equipment)
                }

                <div className={this.css(styles.equipmentContainer, "justify-content-between")}>
                    <div className={"flex flex-direction-column flex-2"}>
                        <div className={styles.cost}>
                            {
                                <div className={"flex flex-direction-column flex-1"}>
                                    <span>
                                        <b>{Localizer.catalogEditProductUnitPrice}: </b>
                                        <span className={styles.price}>
                                            {Utility.format(` {0:C} ${PriceHelper.environmentCurrencyCode}`, this.equipment.unitPrice ?? 0)}
                                        </span>
                                    </span>
                                </div>
                            }
                            {
                                <div className={"flex flex-direction-column flex-1"}>
                                    <span>
                                        <b>{Localizer.constructionSiteDetailsProductsAvailableForReturn}: </b> {this.equipment.combinedCount}
                                    </span>
                                </div>
                            }
                            {
                                (this.showPrices) && (!this.equipment.isReturned) &&
                                (
                                    <div>
                                        <span>
                                            <b>{Localizer.constructionSiteDetailsEquipmentTotalPrice}: </b>
                                        </span>
                                        {
                                            ((this.equipment.rentType === RentType.Daily) || (this.hasBothPrices)) &&
                                            (
                                                <>
                                                    <span className={styles.price}>{Utility.format(` {0:C} ${PriceHelper.environmentCurrencyCode}`, this.equipment.combinedDailyPrice ?? 0)}</span>
                                                    <span> / {Localizer.genericDay} {(!this.hasBothPrices && this.equipment!.dailyRentalType !== null)
                                                    && this.equipment.dailyRentalType === DailyRentalType.FiveDayRent ?
                                                        Localizer.enumDailyRentalTypeFiveDayRent :
                                                        this.equipment.dailyRentalType === DailyRentalType.SixDayRent ?
                                                            Localizer.enumDailyRentalTypeSixDayRent :
                                                            Localizer.enumDailyRentalTypeSevenDayRent}
                                                    </span>
                                                </>
                                            )
                                        }

                                        {
                                            (this.hasBothPrices) &&
                                            (
                                                <span className={styles.verticalSeparator}>
                                                    |
                                                </span>
                                            )
                                        }

                                        {
                                            ((this.equipment.rentType === RentType.Monthly) || (this.hasBothPrices)) &&
                                            (
                                                <>
                                                    <span className={styles.price}>{Utility.format(` {0:C} ${PriceHelper.environmentCurrencyCode}`, this.equipment.combinedMonthlyPrice ?? 0)}</span>
                                                    <span> / {Localizer.genericMonth}</span>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </div>

                    {
                        (this.equipment.isReturned)
                            ?
                            (
                                <div className={this.css(styles.returnedInfo, "flex flex-direction-column flex-1")}>
                                    <p>
                                        {Localizer.constructionSiteDetailsProductReturnedText}
                                    </p>
                                </div>
                            )
                            :
                            (
                                <div className={this.css(styles.inputContainer)}>
                                    {
                                        (!this.equipment.isReturned) && (this.state.isReturningItem) &&
                                        (
                                            <NumberInput className={styles.numberInput}
                                                         model={this._amount}
                                                         min={0}
                                                         max={this.equipment.combinedCount || this.equipment.count || undefined}
                                                         behaviour={NumberInputBehaviour.Restricted}
                                                         onChange={async (_, value) => await this.onAmountChangeHandlerAsync(
                                                             value,
                                                             this.equipment.name,
                                                             this.equipment.inventoryItemId,
                                                             this.equipment.rentaId,
                                                             this.equipment.contractId,
                                                             this.equipment.rentalObjectNumber,
                                                             this.equipment.rentalObjectId,
                                                             this.equipment.location,
                                                             this.equipment.inventoryItemDisplayNumber
                                                         )}
                                            />
                                        )
                                    }
                                    {
                                        (this.props.canReturn && !this.state.isReturningItem) &&
                                        (
                                            <div>
                                                <Button id="return_items"
                                                        type={ButtonType.Orange}
                                                        label={Localizer.constructionSiteEquipmentReturnEquipmentButton}
                                                        className={styles.button}
                                                        onClick={async (_, value) => await this.onReturnItemClick(
                                                            this.equipment.name,
                                                            this.equipment.inventoryItemId,
                                                            this.equipment.rentaId,
                                                            this.equipment.contractId,
                                                            this.equipment.rentalObjectNumber,
                                                            this.equipment.location,
                                                            this.equipment.inventoryItemDisplayNumber
                                                        )}
                                                >
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                    }
                </div>
            </div>
        );
    }

    private renderProductName(equipment: EasyEquipment): React.ReactNode {
        return (equipment.url)
            ?
            (

                <Link key={"product-details-link"}
                      route={PageDefinitions.productDetails.route({id: equipment.url!})}
                >
                    <h3 id={"equipmentProductName"} className={this.css(styles.productName)}>
                        {
                            this.equipment.isRentaFuture &&
                            (
                                <img src={'../images/renta-future.png'} alt={"Renta Future"}/>
                            )
                        }
                        {equipment.name} ({equipment.inventoryItemDisplayNumber})
                    </h3>
                </Link>
            )
            :
            (
                <h3 className={this.css(styles.productName)}>
                    {equipment.name} ({equipment.inventoryItemDisplayNumber})
                </h3>
            );
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public getToggler(): React.ReactNode | null {
        if (this.hasToggle) {
            const isComponent: boolean = React.isValidElement(this.props.toggler);

            if (isComponent) {
                return this.props.toggler;
            }

            return (
                <Icon id={"equipmentToggler"}
                      className={this.css(styles.icon, this.expanded && styles.expanded)}
                      name={"angle-down"}
                      size={IconSize.X2}
                />
            );
        }

        return null;
    }

    public async expandAsync(): Promise<void> {
        await this.setExpanded(true);
    }

    public async collapseAsync(): Promise<void> {
        await this.setExpanded(false);
    }

    public async toggleAsync(): Promise<void> {
        return (this.expanded) ? await this.collapseAsync() : await this.expandAsync();
    }

    render(): React.ReactNode {
        return (
            <div id={this.id}
                 className={styles.equipment}
            >
                <div className={this.css(styles.headerContainer, (!this.hasToggle) && "cursor-pointer")}
                     onClick={(!this.hasToggle)
                         ? (async () => await this.toggleAsync())
                         : undefined}
                >
                    <div className={styles.header}>
                        {
                            this.renderHeader()
                        }
                    </div>

                    {
                        (this.hasToggle) &&
                        (
                            <div className={styles.toggler}
                                 onClick={async () => this.toggleAsync()}
                            >
                                {
                                    this.getToggler()
                                }
                            </div>
                        )
                    }
                </div>

                {
                    (this.expanded) &&
                    (
                        <div className={this.css(styles.contentContainer)}>
                            <hr className={styles.separator}/>

                            {
                                (this.canSeeEquipmentControls) &&
                                (
                                    <div className={styles.equipmentControls}>

                                        <Button id="request_maintenance"
                                                type={ButtonType.Orange}
                                                title={Localizer.serviceRequestServiceRequest}
                                                label={Localizer.serviceRequestServiceRequest}
                                                icon={{name: "cogs", size: IconSize.Normal}}
                                        >

                                            <Button.Action title={Localizer.enumServiceTypeRefuel}
                                                           icon={{name: "gas-pump", size: IconSize.Normal}}
                                                           onClick={async () => await this.createServiceRequestAsync(ServiceType.Refuel)}
                                            />

                                            <Button.Action title={Localizer.enumServiceTypeMaintenance}
                                                           icon={{name: "cogs", size: IconSize.Normal}}
                                                           onClick={async () => await this.createServiceRequestAsync(ServiceType.Maintenance)}
                                            />

                                            {
                                                //TODO feature flags
                                                this.canSelectCharging && (
                                                    <Button.Action title={Localizer.enumServiceTypeCharging}
                                                                   icon={{name: "battery-bolt", size: IconSize.Normal}}
                                                                   onClick={async () => await this.createServiceRequestAsync(ServiceType.Charging)}
                                                    />
                                                )
                                            }
                                        </Button>
                                    </div>
                                )
                            }
                            {
                                (this.equipment.isReturned && this.equipment.url) && (
                                    <div className={styles.equipmentControls}>
                                        <Button label={Localizer.constructionSiteDetailsProductOrderAgain}
                                                type={ButtonType.Orange}
                                                route={PageDefinitions.productDetails.route({id: this.equipment.url!})}
                                        />
                                    </div>
                                )
                            }

                            <EquipmentGroup equipmentGroup={this.equipmentGroup}

                                            showPrices={this.showPrices}
                            />
                        </div>
                    )
                }
            </div>
        );
    }

}