import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./DeviceUsageChart.module.scss";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";

interface ITooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface IDeviceUsageChartProps {
    usageData: DeviceUsage[] | null | undefined
}

const DeviceUsageChart: React.FC<IDeviceUsageChartProps> = ({ usageData }) => {

    const getTimeString = (value: number): string => {
        const n = new Date(0, 0);
        n.setSeconds(+value * 60 * 60);
        return n.toTimeString().slice(0, 8);
    };

    const convertHourToTimeFormat = (hour: number) => {
        const totalSeconds = Math.floor(hour * 3600); // Convert hours to total seconds
        const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '00');
        const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    };

    const customTooltip = ({ active, payload, label }: ITooltipProps) => {
        if (active) {
            return (
                <div className={styles.chartTooltip}>
                    <p className={styles.label}>{label}</p>
                    {
                        (payload && payload[0]) &&
                        (
                            <p className={styles.value}>
                                {Localizer.productLocationXAxel} {getTimeString(payload[0].value as number)}
                            </p>
                        )
                    }
                </div>
            );
        }
        return null;
    };

    const barChartModel = usageData
        ? usageData.map((item: any) => ({ label: item.label, [Localizer.productLocationXAxel]: item.hours }))
        : undefined;

    return (
        <div>
            {(barChartModel) && (
                <ResponsiveContainer width={"100%"} height={400}>
                    <BarChart width={500} height={300} data={barChartModel} margin={{ top: 15, right: 15, left: -5, bottom: 5 }} className={styles.rechartsBarChart}>
                        <CartesianGrid strokeDasharray="3 3" fill="white" format={"0.0"} />
                        <XAxis dataKey="label" />
                        <YAxis tickFormatter={timeStr => convertHourToTimeFormat(timeStr)}
                               width={70}
                        />
                        <Tooltip content={customTooltip} />
                        <Bar dataKey={Localizer.productLocationXAxel} fill="#fe5000" />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default DeviceUsageChart;