import React from "react";
import {Button, ButtonType, CellAction, CellModel, ColumnDefinition, Grid, PageContainer, PageHeader, PageRow, RowModel} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import {FileModel, IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {ActionType, BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";

import AdminPage from "@/models/base/AdminPage";
import EasyPlusSubscription from "@/models/server/Models/EasyPlus/EasyPlusSubscription";
import styles from "./AdminEasyPlusManagement.module.scss";

export interface IAdminEasyPlusManagementParams extends BasePageParameters {
}

interface IAdminEasyPlusManagementState {
    keyword: string | null;
    productsWithoutImages: boolean;
    productRentType: number | null;
    allProducts: EasyPlusSubscription[];
}

export default class AdminEasyPlusManagement extends AdminPage<IAdminEasyPlusManagementParams, IAdminEasyPlusManagementState> {

    public state: IAdminEasyPlusManagementState = {
        keyword: null,
        productsWithoutImages: false,
        productRentType: null,
        allProducts: []
    };

    private readonly _easyPlusManagement: React.RefObject<Grid<EasyPlusSubscription>> = React.createRef();

    private readonly _productsColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericUsersLanguageItemName,
            minWidth: 25,
            actions: [
                {
                    title: Localizer.showMoreInfoTextLanguageItemName,
                    icon: {name: "far fa-user-friends", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Info,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ]
        },
        {
            header: Localizer.companyDetailsCustomerIdLanguageItemName,
            accessor: nameof<EasyPlusSubscription>(d => (d.customerNumber)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.customerTextLanguageItemName,
            accessor: nameof<EasyPlusSubscription>(d => (d.companyName)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.easyPlusManagementUsersCountLanguageItemName,
            accessor: nameof<EasyPlusSubscription>(d => (d.usersCount)),
            editable: false,
            sorting: true,
            minWidth: 90
        },
        {
            header: Localizer.genericDownloadCsvLanguageItemName,
            minWidth: 25,
            actions: [
                {
                    name: "download",
                    icon: {name: "far download", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Info,
                    callback: async (cell, action) => await this.downloadDocumentAsync(cell, action)
                }
            ]
        }
    ];

    private async downloadDocumentAsync(cell: CellModel<EasyPlusSubscription>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "download") {
            await this.downloadCsvAsync(cell.model.customerNumber);
        }
    }

    private async downloadCsvAsync(customerNumber: string | null = null): Promise<void> {
        const data: FileModel = await this.postAsync("/api/Admin/DownloadEasyPlusSubscriptionsCsv", customerNumber ?? "");
        ch.download(data);
    }

    private async listEasyPlusSubscriptions(pageNumber: number,
                                            pageSize: number,
                                            sortColumnName: string | null,
                                            sortDirection: SortDirection | null
    ): Promise<IPagedList<EasyPlusSubscription>> {

        return await this.postAsync("/api/Admin/ListEasyPlusSubscriptions", null);
    }


    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this._easyPlusManagement.current) {
            this._easyPlusManagement.current.model.sortDirection = SortDirection.Asc;
        }
    }

    protected get title(): string {
        return Localizer.easyPlusManagementPageTitle;
    }

    private renderDetailsContent(row: RowModel<EasyPlusSubscription>) {
        const model: EasyPlusSubscription = row.model;
        return (
            <table className={styles.infoBox}>
                <thead>
                    <tr>
                        <th>{Localizer.genericEmail}</th>
                        <th>{Localizer.easyPlusManagementCsvFrom}</th>
                        <th>{Localizer.easyPlusManagementCsvTo}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        model.users.map(item => {
                            return (
                                <tr key={item.email}>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {Utility.format("{0:D}", item.validFrom)}
                                    </td>
                                    <td>
                                        {item.validTo ? Utility.format("{0:D}", item.validTo) : ""}
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );
    }

    private async toggleDetailsAsync(cell: CellModel<EasyPlusSubscription>): Promise<void> {
        const spannedRows: RowModel<EasyPlusSubscription>[] = cell.spannedRows;
        const rowToExpand: RowModel<EasyPlusSubscription> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }


    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.adminEasyPlusManagement}>
                <PageHeader title={Localizer.adminPageRentaAdminManager}
                            subtitle={this.title}
                />
                <PageRow className={styles.downloadRow}>
                    <Button label={Localizer.genericDownloadCsv}
                            type={ButtonType.Orange}
                            onClick={async () => await this.downloadCsvAsync()}
                    />
                </PageRow>
                <PageRow>
                    <Grid responsive version2Styles
                          id={"easy_plus_management"}
                          pagination={25}
                          renderDetails={(row) => this.renderDetailsContent(row)}
                          ref={this._easyPlusManagement}
                          columns={this._productsColumns}
                          noDataText={Localizer.componentDropdownNoDataLanguageItemName}
                          fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                              await this.listEasyPlusSubscriptions(pageNumber, pageSize, sortColumnName, sortDirection)}
                    />
                </PageRow>

            </PageContainer>
        );
    }
}