import {ConstructionSiteRoleModel} from "./ConstructionSiteRoleModel";
import {OrganizationContractRoleModel} from "@/models/server/OrganizationContractRoleModel";
import {IUser} from "@renta-apps/athenaeum-react-common";
import { AlarmMediaType } from "../Enums";

export default class UserModel implements IUser {

    public id: string = "";

    public address: string | null = null;

    public postalCode: string | null = null;

    public city: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public phoneNumber: string | null = null;

    public email: string | null = null;

    public vatId: string | null = null;

    public fullName: string | null = null;

    public agreementAccepted: boolean = false;

    public alarmMediaType: AlarmMediaType | null = null;

    public hasPassword: boolean = false;

    public registerAccepted: boolean = false;

    public informationFilled: boolean = false;

    public language: string | null = null;

    public modifiedAt: Date = new Date();

    public organizationRoles: OrganizationContractRoleModel[] = [];

    public constructionSiteRoles: ConstructionSiteRoleModel[] = [];

    /**
     * Easy Id's of the users favorite Products.
     */
    public favoriteProductsIds: string[] = [];

    public approveMarketing: boolean = false;

    /**
     * Users email address.
     */
    public username: string = this.email!;

    public favoriteDepotId: string | null = null;

    public favoriteDepotExternalId: string | null = null;

    public favoriteDepotName: string | null = null;

    public readonly isUserModel: true = true;

    public readonly isUser: true = true;

    public strongAuthBoundToUser: boolean = false;

    public isAgreementAcceptedObsolete: boolean = false;

    public isRegisterAcceptedObsolete: boolean = false;
}