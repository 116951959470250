import React from "react";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import DeviceDetails from "./DeviceDetails";

export interface IDeviceDetailsPageParams extends BasePageParameters {
    serialNumber: string;
    rentaId: string;
    rentalObjectId: string;
}

interface IDeviceDetailsPageState {
}

export default class DeviceDetailsPage extends AuthorizedPage<IDeviceDetailsPageParams, IDeviceDetailsPageState> {
    protected get title(): string {
        return "Localizer.fleetMonitoringDeviceDetailsPageTitle";
    }

    public render(): React.ReactNode {
        return (
            this.typedParameters?.rentaId &&
                <DeviceDetails
                    serialNumber={this.typedParameters?.serialNumber}
                    rentaId={this.typedParameters?.rentaId}
                    rentalObjectId={this.typedParameters?.rentalObjectId}
                    userRoleConstructionSiteId={this.userContext.selectedConstructionSiteId}
                    userRoleContractId={this.userContext.selectedContractId}
                    userRoleIsAdmin={this.isAdmin}
                />
        );
    }
}