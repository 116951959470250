import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";

import styles from "./EasyPlusFeatureMarketing.module.scss";

interface IEasyPlusFeatureMarketingProps {

    icon: string;

    text: string;

    subtitle: string;

    title: string;
}

interface IEasyPlusFeatureMarketingState {
}

export default class EasyPlusFeatureMarketing extends BaseComponent<IEasyPlusFeatureMarketingProps, IEasyPlusFeatureMarketingState> {


    public render(): React.ReactNode {
        return (
            <div className={styles.easyPlusFeatureMarketingContainer}>
                <div className={styles.titleContainer}>
                    <div className={styles.imageContainer}>
                        <img className={styles.bannerImage}
                             src={this.props.icon}
                             width={75}
                             height={75}
                             alt={""}
                        />
                    </div>
                    <div>
                        <div><h6>{this.props.subtitle}</h6></div>
                        <div><h4>{this.props.title}</h4></div>
                    </div>


                </div>

                <div className={styles.marketingText}>{this.props.text}</div>

            </div>
        );
    }
}