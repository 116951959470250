import React from "react";
import {Button, ButtonType, EmailInput, Form, IconStyle, Link, PageContainer, PageHeader, PageRow, PasswordInput} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import AnonymousPage from "@/models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import PageDefinitions from "@/providers/PageDefinitions";
import LoginRequest from "@/models/server/Requests/LoginRequest";
import {BasePageParameters, ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {LoginResultStatus} from "@/models/Enums";

import styles from "./Login.module.scss";
import RentaEasyController from "@/pages/RentaEasyController";
import UnleashHelper from "@/helpers/UnleashHelper";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export interface ILoginParams extends BasePageParameters {
    ssoBindLogin?: boolean,
    redirectTo?: PageRoute;
}

interface ILoginState {
}

export default class Login extends AnonymousPage<ILoginParams, ILoginState> {

    public state: ILoginState = {};

    private readonly _formRef: React.RefObject<any> = React.createRef();

    private get ssoBindingInProgress(): boolean {
        return this.typedParameters?.ssoBindLogin ?? false;
    }

    protected get title(): string {
        return (this.ssoBindingInProgress) ? Localizer.loginStrongAuthenticationInProgress : Localizer.loginTitle;
    }

    protected get subtitle(): string {
        return (this.ssoBindingInProgress) ? Localizer.loginFirstTimeSsoLoginHelptext : Localizer.loginHelpText;
    }

    public get params(): PageRoute | null {
        return this.parameters as PageRoute;
    }

    private static async redirectToForgotPassword() {
        await PageRouteProvider.redirectAsync(PageDefinitions.forgotPassword.route());
    }

    private static async redirectToRegisterPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.register.route());
    }

    private async loginAsync(data: Dictionary<string, any>): Promise<void> {

        const request: LoginRequest = {
            username: data.getValue("email"),
            password: data.getValue("password"),
            ssoBindLogin: this.ssoBindingInProgress
        };

        if (!request.password || !request.username) {
            this.alertErrorAsync(Localizer.loginErrorInLogin);
            return;
        }

        const result: LoginResultStatus = await this.postAsync("/api/Application/Login", request);

        try {
            if (result === LoginResultStatus.Success || result === LoginResultStatus.SsoLoginSuccess) {
                await UnleashHelper.setEmailAsUserId(request.username);
            }
        }
        catch {
            //Not worth throwing exception for this
        }

    }

    private async cancelBindingProcess(): Promise<void> {
        window.location.search = "";
    }

    private onSignicatClickAsync(): Promise<void> {
        return this.onSsoClickAsync("GetSignicatSsoLogin");
    }

    private async onAzureClickAsync(): Promise<void> {
        const httpResponse: Response = await RentaEasyController.get("/api/Application/GetAzureSsoLogin");

        if (httpResponse.headers.get("location")) {
            window.location.href = httpResponse.headers.get("location")!;
        }
    }

    private async onGoogleClickAsync(): Promise<void> {
        const httpResponse: Response = await RentaEasyController.get("/api/Application/GetGoogleSsoLogin");

        if (httpResponse.headers.get("location")) {
            window.location.href = httpResponse.headers.get("location")!;
        }
    }

    private async onSsoClickAsync(action: string): Promise<void> {
        await RentaEasyController.invokeStrongAuthentication(action, this);
    }

    public async initializeAsync(): Promise<void> {

        await super.initializeAsync();

        if (this.typedParameters) {
            if (this.typedParameters.redirectTo?.name === "ShoppingCart") {
                await this.alertMessageAsync(Localizer.loginLoginToContinueWithOrder);
            }
        }
    }

    public render(): React.ReactNode {
        const microsoftLogin: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagMicrosoftLogin);
        const googleLogin: boolean = (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagGoogleLogin) && !ch.mobileApp);
        const signicatLogin: boolean = UnleashHelper.isEnabled(RentaEasyConstants.featureFlagSsoLogin);

        return (
            <PageContainer>
                <PageHeader title={this.title}
                            subtitle={this.subtitle}
                />

                <PageRow className={this.css(styles.loginPage, "col-md-7")}>

                    <Form id="loginForm"
                          ref={this._formRef}
                          onSubmit={async (_, data) => await this.loginAsync(data)}
                    >

                        <EmailInput required autoFocus noValidate
                                    id="email"
                                    label={Localizer.genericEmail}
                        />

                        <PasswordInput required noValidate
                                       id="password"
                                       className={styles.passwordInputs}
                                       label={Localizer.registerInputPassword}
                        />
                        <Link route={async () => await Login.redirectToForgotPassword()}>{Localizer.loginForgotPassword}</Link>

                        <div className={this.css(styles.buttons)}>
                            <Button submit id={"login_button"}
                                    className={styles.big}
                                    type={ButtonType.Orange}
                                    label={Localizer.loginLogin}
                            />

                            {
                                !this.ssoBindingInProgress &&
                                (
                                    <>
                                        <Button type={ButtonType.Default}
                                                className={styles.big}
                                                label={Localizer.loginRegister}
                                                onClick={async () => await Login.redirectToRegisterPage()}
                                        />
                                    </>
                                )
                            }

                            {

                                (!this.ssoBindingInProgress) &&
                                (
                                    <>
                                        {
                                            (microsoftLogin || signicatLogin || googleLogin) && (
                                                <div className={styles.separator}>{Localizer.selectCompanyPageOr}</div>
                                            )
                                        }
                                        {
                                            microsoftLogin && (
                                                <Button type={ButtonType.Default}
                                                        id={"microsoft_login"}
                                                        icon={{name: "windows", style: IconStyle.Brands}}
                                                        label={Localizer.loginLogin}
                                                        onClick={async () => await this.onAzureClickAsync()}
                                                />
                                            )
                                        }
                                        {
                                            googleLogin && (
                                                <Button type={ButtonType.Default}
                                                        id={"google_login"}
                                                        icon={{name: "google", style: IconStyle.Brands}}
                                                        label={Localizer.loginLogin}
                                                        onClick={async () => await this.onGoogleClickAsync()}
                                                />
                                            )
                                        }
                                        {
                                            signicatLogin && (
                                                <Button type={ButtonType.Default}
                                                        id={"strong_authentication"}
                                                        label={Localizer.loginBankLogin}
                                                        onClick={() => this.onSignicatClickAsync()}
                                                />
                                            )
                                        }

                                    </>

                                )
                            }

                            {
                                (this.ssoBindingInProgress) &&
                                <Button type={ButtonType.Default}
                                        label={Localizer.formCancel}
                                        onClick={async () => await this.cancelBindingProcess()}
                                />
                            }

                        </div>
                    </Form>
                </PageRow>
                {
                    (!this.ssoBindingInProgress) &&
                    (
                        <FeatureSwitch flagName={RentaEasyConstants.featureFlagSsoTestMode}>
                            <a style={{color: "transparent"}} onClick={() => this.onSignicatClickAsync()}>test</a>
                        </FeatureSwitch>
                    )
                }
            </PageContainer>
        );
    }
}