import React, {useMemo} from "react";
import styles from "./MeasurementValue.module.scss";

interface MeasurementValueProps {
    type: MeasurementType;
    value: number | null;
}

type MeasurementType = 'alerts' | 'idle' | 'battery' | 'fluid';

const MeasurementConfig = {
    alerts: {
        className: '',
        icon: 'fas fa-bell',
        format: (value: number) => `${Math.round(value)}`,
        getStatus: (value: number) => value > 0 ? styles.success : styles.error,
    },
    idle: {
        className: '',
        icon: 'fas fa-clock',
        format: (value: number) => `${Math.round(value)}d`,
        getStatus: (value: number) => value < 3 ? styles.success : styles.error,
    },
    fluid: {
        className: '',
        icon: 'fas fa-droplet',
        format: (value: number) => `${Math.round(value)}%`,
        getStatus: (value: number) => value > 50 ? styles.success : styles.error,
    },
    battery: {
        className: styles.battery,
        icon: 'fas fa-battery-half',
        format: (value: number) => {
            if (value > 80) {
                return '●●●';
            }
            if (value > 30) {
                return '●●○';
            }
            if (value > 10) {
                return '●○○';
            }

            return '○○○';
        },
        getStatus: (value: number) => value > 80 ? styles.success : value > 30 ? styles.warning : styles.error,
    },
};

const MeasurementValue: React.FC<MeasurementValueProps> = ({type, value}) => {
    const config = useMemo(() => {
        const typeConfig = MeasurementConfig[type];
        if (!typeConfig) {
            return null;
        }
        
        const status = value === null ? styles.disabled : typeConfig.getStatus(value)
        return {
            className: `${styles.measurementContainer} ${status} ${typeConfig.className}`,
            icon: typeConfig.icon,
            displayValue: value === null ? '-' : typeConfig.format(value),
        }
    }, [type, value]);
    
    if (!config) {
        return null;
    }

    const {icon, displayValue, className} = config;

    return (
        <div className={className}>
            <span className={styles.measurementIcon}>
                <i className={icon} />
            </span>
            <span className={styles.measurementValue}>{displayValue}</span>
        </div>
    );
};

export default MeasurementValue;