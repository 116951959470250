import DeviceGridHelper from "@/helpers/CypressFleetMonitoringHelper";
import Localizer from "@/localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";


describe('Desktop - Fleet Monitoring - Grid navidation tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });
    it('should open construction site page when click on the construction site name', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', {fixture: 'getDeviceDetails.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(2)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(2)
            .find('[data-cy="constructionSiteLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesConstructionSiteDetails}?id=9727f2e0-74ef-4267-b505-ceb4ed8f8591`);

    });

    it('should open company page when click on the company name', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Fleet/GetDeviceDetails', {fixture: 'getDeviceDetails.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(2)
            .click();

        DeviceGridHelper.getDeviceGridExpandedContentByRow(2)
            .find('[data-cy="customerLink"]')
            .first()
            .click();

        cy.url().should('include', `${Localizer.pageRoutesContractDetails}?id=9410d0d2-a86e-43c5-85b4-7cebf3ba0562`);
    });

    it('should open device details page by clicking chart icon', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Product/HasAccessToProduct', {fixture: 'hasAccessToProductTrue.json'});

        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', {fixture: 'getDeviceExtendedDetails.json'});

        cy.visit('/fleet-monitoring');


        executeWithIntercept(() => DeviceGridHelper.getDeviceGridAngleIcon(3)
                .click(),
            [pageData().fleetMonitoring.routes.getDeviceDetails as RouteData]);


        DeviceGridHelper.getDeviceGridChartIcon(3)
            .click();

        cy.url().should('include', `${Localizer.pageRoutesDeviceDetailsPage}`);
        cy.url().should('include', `rentaId=90193`);
        cy.url().should('include', `serialNumber=160XTB-2-2019-0001`);
        cy.url().should('include', `rentalObjectId=123456`);
    });

    it('should open productLocationPage from product name', () => {

        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/Product/HasAccessToProduct', {fixture: 'hasAccessToProductTrue.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridContentByRowAndCell(7, 0, 1)
            .trigger('click');

        const responseContainer = {
            "value": null,
            "context": null,
            "newVersionAvailable": false,
            "redirect": null,
            "error": null,
            "alert": null,
            "unauthorized": false,
            "isResponseContainer": true
        };
        cy.intercept('POST', '/api/Product/FindProductByExternalId', {statusCode: 200, body: responseContainer});
        cy.intercept('POST', '/api/Locations/GetDeviceLocation', {statusCode: 200, body: responseContainer});
        cy.intercept('POST', '/api/Locations/GetDeviceFuelLevel', {statusCode: 200, body: responseContainer});

        cy.url().should('include', Localizer.pageRoutesProductLocation);

        cy.get('#data_not_found').should('exist');
    });

});