import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export class UserRolesHelper {

    public static isAdmin(): boolean {
        const userContext = (ch.getContext() as UserContext);
        return userContext.isAdmin;
    };

    public static getCurrentUserRole(): string {
        const userContext = (ch.getContext() as UserContext);

        if (userContext.isAdmin) {
            return RentaEasyConstants.adminUser; // intentionally the officeUser role is not detected (we're not allowing to set this role from the UI now)
        } else if (userContext.isCompanyMainUser) {
            return RentaEasyConstants.organizationMainUser;
        } else if (userContext.isConstructionSiteMainUser) {
            return RentaEasyConstants.constructionSiteMainUser;
        } else if (userContext.isConstructionSiteUser) {
            return RentaEasyConstants.constructionSiteUser;
        }
        return RentaEasyConstants.privateUser;
    };

    public static getAvailableUserRolesByUserRoleName(userRoleName: string): string[] {
        switch (userRoleName) {
            default:
            case RentaEasyConstants.organizationMainUser:
                return [RentaEasyConstants.organizationMainUser, RentaEasyConstants.constructionSiteMainUser];
            case RentaEasyConstants.constructionSiteMainUser:
                return [RentaEasyConstants.constructionSiteMainUser];
            case RentaEasyConstants.constructionSiteUser:
                return [];
        }
    }
}

