import React, {useEffect, useState} from "react";
import {ButtonType, Form, TextInput} from "@renta-apps/athenaeum-react-components";
import ButtonWithSpinner from "@/components/ButtonWithSpinner/ButtonWithSpinner";
import Localizer from "@/localization/Localizer";
import styles from "./EasyPlusSettings.module.scss";
import SaveEasyPlusSubscriptionInvoiceReference from "@/models/server/Requests/SaveEasyPlusSubscriptionInvoiceReference";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import EasyPlusSubscription from "@/models/server/Models/EasyPlus/EasyPlusSubscription";

interface IEasyPlusSettingsProps {
    contractId: string;
}

const EasyPlusSettings: React.FC<IEasyPlusSettingsProps> = ({contractId}) => {
    const [invoiceReference, setInvoiceReference] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const fetchInvoiceReference = async (): Promise<void> => {
            const easyPlusSubscription: EasyPlusSubscription | null = await ApiProvider.postAsync("/api/Companies/getEasyPlusSubscription", contractId);
            setInvoiceReference(easyPlusSubscription?.invoiceReference ?? null);
        };

        fetchInvoiceReference();
    }, [contractId]);
    
    const saveEasyPlusInvoiceReference = async (): Promise<void> => {
        setSaving(true);
        const request: SaveEasyPlusSubscriptionInvoiceReference = {
            invoiceReference: invoiceReference ?? null,
            contractId: contractId,
        };
    
        await ApiProvider.postAsync("/api/Companies/SaveEasyPlusInvoiceReference", request);
        setSaving(false);
    };

    return (
        <div className={styles.easyPlusSettings}>
            <div className={styles.easyPlusSettingsTitle}>{Localizer.easyPlusManagementSettings}</div>
            <Form onSubmit={async () => await saveEasyPlusInvoiceReference()}>
                <TextInput label={Localizer.easyPlusInvoiceReference}
                           id={"invoice_reference"}
                           value={invoiceReference ?? ""}
                           onChange={async (_, value) => setInvoiceReference(value)}
                           className={styles.invoiceReferenceInput}
                           maxLength={255}
                />
                <div className={styles.easyPlusSettingsNote}>{Localizer.easyPlusManagementInvoiceReferenceNote}</div>
                <ButtonWithSpinner submit
                                   spinning={saving}
                                   type={ButtonType.Orange}
                                   label={Localizer.easyPlusManagementSaveSettings}
                />
            </Form>
        </div>
    );
}

export default EasyPlusSettings;