import React from "react";
import {Button, ButtonType, CellAction, CellModel, Checkbox, ColumnDefinition, Dropdown, DropdownOrderBy, Grid, GridModel, InlineType, SelectListItem, TextInput} from "@renta-apps/athenaeum-react-components";
import {ActionType, AlertModel, BaseComponent} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import ConstructionSiteUserModel from "@/models/server/ConstructionSiteUserModel";
import DeleteUserRoleRequest from "@/models/server/DeleteUserRoleRequest";
import ConstructionSiteUserPagedListRequest from "@/models/server/ConstructionSiteUserPagedListRequest";
import PendingInvitationOverviewModel from "@/models/server/PendingInvitationOverviewModel";
import InvitationsGrid from "@/components/InvitationsGrid/InvitationsGrid";
import InvitationsGridToolbarModel from "@/components/InvitationsGrid/Toolbar/InvitationsGridToolbarModel";
import PendingInvitationsRequest from "@/models/server/Requests/PendingInvitationsRequest";
import RentaEasyController from "@/pages/RentaEasyController";
import PageDefinitions from "@/providers/PageDefinitions";

import styles from "./ConstructionSiteUsers.module.scss";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

interface IConstructionSiteUsersProps {
    constructionSiteId: string,
    constructionSiteOwnerId: string,
    canManageUsers: boolean
}

interface IServicesState {
    constructionSiteId: string;
    isLoading: boolean;
    alertModel: AlertModel | null;
    worksiteUsers: ConstructionSiteUserModel[];
    sortDirection: SortDirection;
    searchTerm: string;
    notProcessedUsers: boolean;
    selectedRole: string;
}

export default class ConstructionSiteUsers extends BaseComponent<IConstructionSiteUsersProps, IServicesState> {

    // Fields

    public state: IServicesState = {
        constructionSiteId: this.constructionSiteId,
        isLoading: true,
        worksiteUsers: [],
        sortDirection: SortDirection.Asc,
        searchTerm: "",
        notProcessedUsers: false,
        selectedRole: "",
        alertModel: null
    };

    private readonly _usersGrid: React.RefObject<Grid<ConstructionSiteUserModel>> = React.createRef();

    private readonly _usersColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: "name",
            transform: (cell: CellModel<ConstructionSiteUserModel>) => (cell.model.firstName && cell.model.lastName) ? `${cell.model.firstName} ${cell.model.lastName}` : cell.model.email!,
            editable: false,
            sorting: true,
            isDefaultSorting: true,
        },
        {
            header: Localizer.genericEmailLanguageItemName,
            accessor: "email",
            editable: false,
            sorting: true
        },
        {
            header: Localizer.genericRoleLanguageItemName,
            accessor: "roleName",
            transform: (cell: CellModel<ConstructionSiteUserModel>) => Localizer.get(cell.value),
            editable: false,
            sorting: true
        },
        {
            minWidth: 50,
            visible: this.canManageUsers,
            actions: [
                {
                    name: "delete",
                    title: Localizer.genericDeleteRoleLanguageItemName,
                    icon: {name: "far trash-alt", customStyle: {fontSize: "1.5rem"}},
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<ConstructionSiteUserModel>) => Localizer.get(Localizer.constructionSiteDetailsUsersTableConfirmRoleDeletion, cell.model.email),
                    callback: async (cell, action) => await this.processUserOperationAsync(cell, action)
                }
            ]
        },

    ];

    // Properties

    private get canManageUsers(): boolean {
        return this.props.canManageUsers;
    }

    private get constructionSiteId(): string {
        return this.props.constructionSiteId;
    }

    private get constructionSiteOwnerId(): string {
        return this.props.constructionSiteOwnerId;
    }

    private get usersGrid(): GridModel<ConstructionSiteUserModel> {
        return this._usersGrid.current!.model;
    }

    private get userDropdownItems(): SelectListItem[] {
        return [
            new SelectListItem(RentaEasyConstants.constructionSiteUser, Localizer.constructionSiteUser),
            new SelectListItem(RentaEasyConstants.constructionSiteMainUser, Localizer.constructionSiteMainUser)
        ];
    }

    // Methods

    private async resendUserInvitationAsync(invitationId: string): Promise<void> {
        await RentaEasyController.resendInvitationAsync(invitationId, this);
    }

    private async processUserOperationAsync(cell: CellModel<ConstructionSiteUserModel>, action: CellAction<any>): Promise<void> {
        if (action.action.name === "delete") {

            await this.onDeleteAsync(cell.model);

            await cell.grid.deleteAsync(cell.row.index);
        }
    }

    private async getConstructionSiteUsersAsync(pageNumber: number,
                                                pageSize: number,
                                                sortColumnName: string | null,
                                                sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSiteUserModel>> {

        const request: ConstructionSiteUserPagedListRequest = {
            constructionSiteId: this.constructionSiteId,
            pageSize: pageSize,
            pageNumber: pageNumber,
            sortDirection: sortDirection!,
            sortColumnName: sortColumnName,
            searchTerm: this.state.searchTerm,
            selectedRole: this.state.selectedRole,
            isConstructionSiteUserPagedListRequest: true,
            notProcessedUsers: this.state.notProcessedUsers,
        };

        return await this.postAsync("/api/Users/GetConstructionSiteUsersPaged", request);
    }

    private async getPendingInvitations(filters: InvitationsGridToolbarModel, pageNumber: number, pageSize: number): Promise<IPagedList<PendingInvitationOverviewModel>> {

        const request: PendingInvitationsRequest = {
            contractId: null,
            constructionSiteId: this.constructionSiteId,
            pageSize: pageSize,
            pageNumber: pageNumber,
            sortDirection: SortDirection.Asc,
            sortColumnName: null,
            keyWord: filters?.keyword ?? null,
            processed: filters.processed,
        };

        return await RentaEasyController.getPendingInvitationsAsync(request, this);
    }

    private async onSearchChangeAsync(value: string): Promise<void> {
        await this.setState(
            {searchTerm: value},
            async () => await this.usersGrid.reloadAsync()
        );
    }

    private async onRoleChangeAsync(item: SelectListItem | null): Promise<void> {
        await this.setState(
            {selectedRole: item?.value ?? ""},
            async () => await this.usersGrid.reloadAsync()
        );
    }

    public async initializeAsync(): Promise<void> {
        await this.setState({isLoading: false});
    }

    private async onDeletePendingInvitationAsync(dataItem: PendingInvitationOverviewModel): Promise<void> {
        return await this.postAsync("/api/Users/DeleteInvitation", dataItem.invitationId);
    }

    private async onDeleteAsync(dataItem: ConstructionSiteUserModel): Promise<void> {
        const request: DeleteUserRoleRequest = {
            roleId: dataItem.roleId
        };

        await this.postAsync("/api/ConstructionSites/DeleteRole", request);

        await this.initializeAsync();
    }

    private async setNotProcessedAsync(notProcessed: boolean): Promise<void> {
        this.state.notProcessedUsers = notProcessed;
        await this._usersGrid.current?.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.worksiteUsers, "container")}>
                <div className={styles.searchBar}>
                    {
                        (this.canManageUsers) &&
                        (
                            <div className={styles.searchBarInviteGroup}>
                                <Button className={styles.searchBarButton}
                                        type={ButtonType.Orange}
                                        label={Localizer.contractDetailsModelInviteUser}
                                        route={PageDefinitions.inviteUser.route({
                                            params: {
                                                constructionSiteId: this.constructionSiteId,
                                                contractId: this.constructionSiteOwnerId,
                                            }
                                        })}
                                />
                            </div>
                        )
                    }

                    <div className={styles.searchBarFilterGroup}>
                        <TextInput className={styles.searchBarNameField}
                                   label={Localizer.constructionSiteDetailsUsersTableSearchByNameOrEmail}
                                   model={{value: this.state.searchTerm}}
                                   onChange={async (_, value) => await this.onSearchChangeAsync(value)}
                        />

                        <Dropdown className={styles.searchBarRoleDropdown}
                                  label={Localizer.genericRole}
                                  items={this.userDropdownItems}
                                  orderBy={DropdownOrderBy.None}
                                  selectedItem={this.state.selectedRole}
                                  onChange={(async (_, item) => await this.onRoleChangeAsync(item))}
                        />

                        <Checkbox inline inlineType={InlineType.Right}
                                  label={Localizer.adminUserManagementFilterNotProcessed}
                                  value={this.state.notProcessedUsers}
                                  onChange={async (_, checked) => await this.setNotProcessedAsync(checked)}
                        />
                    </div>
                </div>

                <Grid responsive
                      version2Styles
                      pagination={10}
                      ref={this._usersGrid}
                      columns={this._usersColumns}
                      noDataText={Localizer.componentDropdownNoData}
                      fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) =>
                          await this.getConstructionSiteUsersAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                <h3 className={styles.pendingInvitationsTitle}>
                    {Localizer.adminUserManagementPendingInvitationsTitle}
                </h3>

                <InvitationsGrid getPendingInvitationsAsync={async (filters, pageNumber, pageSize) => await this.getPendingInvitations(filters, pageNumber, pageSize)}
                                 onDeletePendingInvitationAsync={async (dataItem) => await this.onDeletePendingInvitationAsync(dataItem)}
                                 onResendUserInvitationAsync={async (invitationId) => await this.resendUserInvitationAsync(invitationId)}
                />
            </div>
        );
    }
}