import Localizer from "../../../../localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";

// @ts-ignore
describe('Desktop - Authenticated construction site equipment environment control tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`));

        cy.intercept('POST', 'api/EnvironmentControl/GetSiteData', {fixture: 'environmentControlSiteData.json'});

        cy.intercept('POST', 'api/EnvironmentControl/GetDeviceData', {fixture: 'environmentControlDeviceData.json'});

        cy.intercept("https://www.ioliving.com/account/floor_plan/huge/63e1f2ad78b24.jpg", { fixture: "63e1f2ad78b24.jpg" })

        executeWithIntercept(() => cy.get('#tab_environmental').click(),
            [pageData().constructionSite.routes.getEnvironmentControlSiteData as RouteData]
        );
    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    it('Verify canvas is rendered', () => {
        cy.get('canvas');
    });

    it('Temperature sensor opens and renders', () => {
        executeWithIntercept(() => GridHelper.getGridVisibleRows('#sensorDataTable')
                .first()
                .find('[class*=athenaeum-button-button]')
                .first()
                .click(),
            [pageData().constructionSite.environmentControl.routes.getEnvironmentControlDeviceData as RouteData]);

        cy.get('#emission-chart-container');

        cy.get('#temperatureChart_Back_Button').click();

        cy.get('#emission-chart-container').should('not.exist');
    });
});