import React from "react";
import {Button, ButtonType, Checkbox, Dropdown, DropdownSelectType, EmailInput, Form, PageContainer, PageHeader, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import ApplyForCompanyAccountRequest from '@/models/server/ApplyForCompanyAccountRequest';
import Localizer from "@/localization/Localizer";
import DepotModel from "@/models/server/DepotModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import RentaEasyController from "@/pages/RentaEasyController";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import styles from "./ApplyCompanyAccount.module.scss";

export interface IApplyCompanyAccountParams extends BasePageParameters {
}

interface IApplyCompanyAccountState {
    requestObject: ApplyForCompanyAccountRequest,
    selectedRentalOffice: DepotModel,
    rentalOfficeDropdown: DepotModel[],
}

export default class ApplyCompanyAccount extends AuthorizedPage<IApplyCompanyAccountParams, IApplyCompanyAccountState> {

    state: IApplyCompanyAccountState = {
        requestObject: new ApplyForCompanyAccountRequest(),
        selectedRentalOffice: {} as DepotModel,
        rentalOfficeDropdown: [],
    };

    private _constructionSitesInput: string = "";

    public async initializeAsync(): Promise<void> {

        await super.initializeAsync();

        const depots: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        const userFavoriteLocation = this.userContext.user!.favoriteDepotId;

        const selectedOffice = userFavoriteLocation ? depots.find((office) => (office.id === userFavoriteLocation)) : null;

        if (selectedOffice) {
            await this.setState({
                rentalOfficeDropdown: depots,
                selectedRentalOffice: selectedOffice as DepotModel,
                requestObject: {...this.requestObject, selectedRentalOfficeId: selectedOffice.id}
            });
        }
        else {
            await this.setState({
                rentalOfficeDropdown: depots,
            });
        }

    }

    private async sendApplyRequestAsync(request: ApplyForCompanyAccountRequest): Promise<void> {
        await this.postAsync(`/api/Account/ApplyCompanyAccount`, request);

    }

    //GET
    private get requestObject(): ApplyForCompanyAccountRequest {
        return this.state.requestObject;
    }

    private get vatId(): string {
        return this.requestObject.vatId ? this.requestObject.vatId : "";
    }

    private get districtCompany(): string {
        return this.requestObject.districtCompany ? this.requestObject.districtCompany : "";
    }

    private get managerName(): string {
        return this.requestObject.managerName ? this.requestObject.managerName : "";
    }

    private get projects(): string {
        return this.requestObject.constructionSites ? this.requestObject.constructionSites : "";
    }

    private get isAllConstructionSitesRequest(): boolean {
        return this.requestObject.isAllConstructionSitesRequested;
    }

    private get managerEmail(): string {
        return this.requestObject.managerEmail ? this.requestObject.managerEmail : "";
    }

    private get selectedRentalOffice(): DepotModel {
        return this.state.selectedRentalOffice;
    }

    private get rentalOfficeDropdown(): DepotModel[] {
        return this.state.rentalOfficeDropdown;
    }

    //SET

    private async setVatIdAsync(vatId: string): Promise<void> {
        let helper = this.requestObject;
        helper.vatId = vatId;
        await this.setState({requestObject: helper});
    }

    private async setDistrictCompanyAsync(districtCompany: string): Promise<void> {
        let helper = this.requestObject;
        helper.districtCompany = districtCompany;
        await this.setState({requestObject: helper});
    }

    private async setManagerNameAsync(managerName: string): Promise<void> {
        let helper = this.requestObject;
        helper.managerName = managerName;
        await this.setState({requestObject: helper});
    }

    private async setManagerEmailAsync(managerEmail: string): Promise<void> {
        let helper = this.requestObject;
        helper.managerEmail = managerEmail;
        await this.setState({requestObject: helper});
    }

    private async setSelectedRentalOfficeIdAsync(selectedRentalOfficeId: string): Promise<void> {
        let helper = this.requestObject;
        helper.selectedRentalOfficeId = selectedRentalOfficeId;
        await this.setState({requestObject: helper});
    }

    private async setSelectedRentalOfficeAsync(selectedRentalOffice: DepotModel | null): Promise<void> {
        if (selectedRentalOffice !== null) {
            const selectedOfficeId = selectedRentalOffice.id;
            await this.setState({selectedRentalOffice});
            await this.setSelectedRentalOfficeIdAsync(selectedOfficeId);
        }
    }

    private async setIsAllConstructionSitesRequest(checked: boolean): Promise<void> {
        const newConstructionSites: string = (checked) ? Localizer.genericAll : this._constructionSitesInput.trim();

        await this.setState({
            requestObject: {
                ...this.requestObject,
                isAllConstructionSitesRequested: checked,
                constructionSites: newConstructionSites
            }
        });
    }

    private async setConstructionSites(constructionSites: string): Promise<void> {
        let request = this.requestObject;

        // Save construction sites input to be filled in case user disables checkbox
        this._constructionSitesInput = constructionSites;

        request.constructionSites = constructionSites;
        await this.setState({
            requestObject: request,
        });
    }

    protected get title(): string {
        return Localizer.applyCompanyAccountTitle;
    }

    public async trimInput(input: TextInput): Promise<void> {
        let req = this.requestObject;
        req.constructionSites = input.value.trim();
        await this.setState({
            requestObject: req
        });
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer id={styles.applyCompanyAccountPage} 
                           className={styles.applyCompanyAccountPage}
            >
                <PageHeader title={this.title}
                            subtitle={Localizer.applyCompanyAccountHelpText}
                />

                <PageRow>
                    <Form onSubmit={async () => await this.sendApplyRequestAsync(this.requestObject)}>

                        <TextInput required
                                   id={"vat_id"}
                                   label={Localizer.genericVatId}
                                   value={this.vatId}
                                   onChange={async (_, value) => {
                                       await this.setVatIdAsync((value));
                                   }}
                        />

                        <TextInput required
                                   id={"company_name"}
                                   label={Localizer.applyCompanyAccountDistrictCompany}
                                   value={this.districtCompany}
                                   onChange={async (_, value) => {
                                       await this.setDistrictCompanyAsync(value);
                                   }}
                        />

                        <TextInput required
                                   id={"manager_name"}
                                   label={Localizer.genericManagerName}
                                   value={this.managerName}
                                   onChange={async (_, value) => {
                                       await this.setManagerNameAsync(value);
                                   }}
                        />

                        <EmailInput required
                                    id={"manager_email"}
                                    label={Localizer.genericManagerEmail}
                                    value={this.managerEmail}
                                    onChange={async (_, value) => {
                                        await this.setManagerEmailAsync(value);
                                    }}
                        />

                        <Dropdown required
                                  id={"select_depot"}
                                  label={Localizer.myAccountClosestRentaOffice}
                                  selectType={DropdownSelectType.Background}
                                  selectedItem={this.selectedRentalOffice}
                                  items={this.rentalOfficeDropdown}
                                  onChange={async (_, item) => await this.setSelectedRentalOfficeAsync(item)}
                        />

                        <span> {Localizer.constructionsites} * </span>

                        <Checkbox id={"all_construction_sites"}
                                  className={styles.allSitesColumn}
                                  value={this.isAllConstructionSitesRequest}
                                  label={Localizer.genericAll}
                                  onChange={async (_, checked) => await this.setIsAllConstructionSitesRequest(checked)}
                        />

                        <TextInput required
                                   id={"construction_sites"}
                                   className={styles.constructionSitesColumn}
                                   label={Localizer.genericProjects}
                                   value={this.projects}
                                   readonly={this.isAllConstructionSitesRequest}
                                   onChange={async (_, value) => {
                                       await this.setConstructionSites(value);
                                   }}
                                   onBlur={async (sender) => {
                                       await this.trimInput(sender);
                                   }}
                        />

                        <Button submit
                                id={"form_submit"}
                                type={ButtonType.Orange}
                                label={Localizer.genericSave}
                                disabled={this.isSpinning()}
                        />

                    </Form>
                </PageRow>
            </PageContainer>
        );
    }
}