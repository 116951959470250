import Localizer from "../../../../localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";


describe('Desktop - Admin construction site details equipments test', () => {

    const constructionSiteId = 'e400a2c9-dd73-4c97-97c3-a69999d15051';
    const contractId = '31eaa6c0-42fa-40c2-a891-bcd16c367409';

    before(() => {
    });

    beforeEach(() => {
        cy.session(['admin_role_session'], cy.loginAndSelectAdminRole, {});
    });

    it('Construction site details has equipment list and equipment loads', () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', {fixture: 'constructionSiteDetailsEquipmentList.json'});

        onRedirect(() =>
            executeWithIntercept(() =>
                    cy.visit(Localizer.pageRoutesAdminConstructionSitesOverview),
                [
                    pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
                ]
            ));

        cy.get("#input_constructionSiteSearchInput").type("wilska");

        executeWithIntercept(() =>
                cy.get("#constructionSiteSearchButton").click(),
            [
                pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
            ]
        );


        GridHelper.getGridVisibleRows(pageData().admin.constructionSites.tableName())
            .should("have.length", 24);

        GridHelper.getGridContentByRowAndCell(pageData().admin.constructionSites.tableName(), 4, 1)
            .find("div")
            .find('.athenaeum-grid-link')
            .click();

        pageData().constructionSite.equipment.tab()
            .click();

        cy.get("#equipmentProductName")
            .should("contain", "NEUVOTTELUPÖYTÄ 120 X 100 TAITTOJ.");

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        pageData().constructionSite.equipment.buttons.requestMaintenance()
            .click();

        cy.get("#request_maintenance_actions")
            .find("span")
            .eq(0)
            .should("contain", Localizer.enumServiceTypeRefuel);

        cy.get("#equipmentGroupItem")
            .find("span")
            .eq(0)
            .should("contain", Localizer.constructionDetailsContract);

        cy.get("#equipmentGroupItem")
            .find("span")
            .eq(2)
            .should("contain", Localizer.constructionSiteDetailsProductsAvailableForReturn);

    });


    it('Construction site details, Invites a new user while returning products', () => {

        cy.intercept('POST', '/api/ConstructionSites/GetSchedules').as('schedulesRequest');

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' }).as('equipmentRequest');

        onRedirect(() =>
            executeWithIntercept(() =>
                    cy.visit(Localizer.pageRoutesAdminConstructionSitesOverview),
                [
                    pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
                ]
            ));

        cy.get("#input_constructionSiteSearchInput").type("wilska");

        executeWithIntercept(() =>
                cy.get("#constructionSiteSearchButton").click(),
            [
                pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
            ]
        );

        GridHelper.getGridVisibleRows(pageData().admin.constructionSites.tableName())
            .should("have.length", 24);

        GridHelper.getGridContentByRowAndCell(pageData().admin.constructionSites.tableName(), 4, 1)
            .find("div")
            .find('.athenaeum-grid-link')
            .click();

        cy.wait('@schedulesRequest').then(({ request }) => {
            expect(request.body).to.equal(constructionSiteId);
        });

        cy.wait('@equipmentRequest').then(({ request }) => {
            expect(request.body).to.equal(constructionSiteId);
        });

        // Add product to return

        cy.get("#return_items")
            .eq(0)
            .click();

        cy.get("#returnProductsAccordion")
            .click();

        cy.wait(200);

        // Assert user info fields visible
        cy.get("#return_use_customer_info").should('be.visible');
        cy.get("#return_fill_customer_info").should('be.visible');


        executeWithIntercept(() =>
                cy.get("#inviteNewUserButton").click(),
            [pageData().inviteUser.routes.getInvitationPageData as RouteData]
        );

        // Fill invite user form and save request
        pageData().inviteUser.inputs.firstname()
            .type("First name");

        pageData().inviteUser.inputs.lastname()
            .type("Last name");

        pageData().inviteUser.inputs.email()
            .type("email@email.email");

        pageData().inviteUser.inputs.telephone()
            .type("+456789012345");

        pageData().inviteUser.buttons.addUserButton()
            .click();

        // Fill return form and submit

        // Assert user info fields visible
        cy.get("#return_use_customer_info").should('be.exist');
        cy.get("#return_fill_customer_info").should('be.exist');

        pageData().inviteUser.inputs.address()
            .type("Address field");

        pageData().inviteUser.inputs.city()
            .type("Great city");

        pageData().inviteUser.inputs.postalcode()
            .type("12345");

        pageData().inviteUser.inputs.comment()
            .type("Information field");

        cy.intercept('POST', '/api/Users/InviteUser').as('inviteUser');

        cy.intercept('POST', '/api/ConstructionSites/ReturnProduct').as('returnProduct');

        cy.get(".athenaeum-layout-main")
            .find("form")
            .eq(2)
            .submit();

        cy.get('.athenaeum-confirmation-dialog-opened')
            .find('[id^=confirmation-dialog-confirm-]')
            .click();

        cy.wait('@returnProduct').then(({ request }) => {
            expect(request.body).to.have.property('organizationId', null);
            expect(request.body).to.have.property('constructionSiteId', constructionSiteId);
        });

        cy.wait('@inviteUser').then(({ request }) => {
            expect(request.body).to.have.property('accessToAllConstructionSites', true);
            expect(request.body).to.have.property('companyMainUserRole', false);
            expect(request.body).to.have.property('contractId', contractId);
        });
    });

    it('Construction site details, has equipment documents', () => {

        cy.intercept('POST', '/api/ConstructionSites/Equipment', { fixture: 'constructionSiteDetailsEquipmentList.json' });

        onRedirect(() =>
            executeWithIntercept(() =>
                cy.visit(Localizer.pageRoutesAdminConstructionSitesOverview),
                [
                    pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
                ]
            ));

        cy.get("#input_constructionSiteSearchInput").type("wilska");

        executeWithIntercept(() =>
            cy.get("#constructionSiteSearchButton").click(),
            [
                pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData,
            ]
        );

        GridHelper.getGridContentByRowAndCell("#table_adminConstructionSiteOverviewTable", 4, 1)
            .find("div")
            .find('.athenaeum-grid-link')
            .click();

        pageData().constructionSite.equipment.tab()
            .click();

        pageData().constructionSite.equipment.toggleIcon()
            .click();

        cy.intercept('POST', '/api/Product/GetMachineDocuments', { fixture: 'machineDocumentsData.json' });

        cy.get("#equipmentGroupItem")
            .find("div")
            .eq(2)
            .find("button")
            .eq(1)
            .click();

        GridHelper.getGridVisibleRows(pageData().support.requestTypeTableName())
            .should("have.length", 4);

        cy.assertTableCellValue(0, 0, "20-185 CE samsvar.pdf");

        cy.assertTableCellValue(0, 2, "0.9 MB");

        cy.assertTableCellValue(2, 0, "Manitou 180 ATJ.pdf");

        cy.assertTableCellValue(2, 2, "5.5 MB");

        GridHelper.getGridContentByRowAndCell("#table_requestTypeTable", 0, 3)
            .click();

        cy.get('.athenaeum-document-preview-modal-documentPreviewModal').should('have.css', 'display', 'block');
        cy.get('h5.modal-title').should('include.text', 'Document preview');
        cy.get('h6.modal-title').should('include.text', '20-185 CE samsvar.pdf');
        cy.get('.athenaeum-document-preview-modal-documentPreviewModal').find('i.fa-download').should('exist');
        cy.get('.athenaeum-document-preview-modal-documentPreviewModal').find('i.fa-xmark').should('exist');

    });
});