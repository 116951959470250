import React from 'react';
import styles from './SimpleTable.module.scss';
import Localizer from "@/localization/Localizer";

export type SimpleTableRowData = {
    [key: string]: any
}

export type SimpleTableHeader = {
    key: string;
    label: string;
    render?: (rowData: SimpleTableRowData) => React.JSX.Element | Element | string | number | null;
    className?: string;
}

export type SimpleTableProps = {
    headers: SimpleTableHeader[];
    data: SimpleTableRowData[];
    rowGridClassName: string;
}

const SimpleTable: React.FC<SimpleTableProps> = (props: SimpleTableProps) => {
    if (props.data.length === 0) {
        return <div>{Localizer.genericNoData}</div>;
    }

    return (
        <div className={styles.tableContainer}>
            <div className={`${styles.headerRow} ${props.rowGridClassName}`}>
                {props.headers.map((header) => (
                    <div key={header.key} className={styles.headerCell}>
                        {header.label}
                    </div>
                ))}
            </div>

            {props.data.map((row, rowIndex) => (
                <div key={rowIndex} className={`${styles.row} ${props.rowGridClassName}`}>
                    {props.headers.map((header) => (
                        <div key={`${rowIndex}-${header.key}`} className={`${styles.cell} ${header.className ?? ""}`}>
                            {header.render ? header.render(row) : row[header.key]}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default SimpleTable;