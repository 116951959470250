import React from "react";
import {AddressHelper, Button, ButtonType, GoogleApiResult, GoogleMap, IGoogleMapInfoWindow, IGoogleMapMarker, PageContainer} from "@renta-apps/athenaeum-react-components";
import {BaseAsyncComponent, ch, IBaseAsyncComponentState} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import UserContext from "@/models/server/UserContext";
import Autocomplete from "react-google-autocomplete";
import styles from "./ConstructionSiteEquipmentMap.module.scss";
import TrackUnitBaseModel from "@/models/server/TrackUnitBaseModel";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import SaveSiteLocationRequest from "@/models/server/Requests/SaveSiteLocationRequest";
import LocalizationHelper from "@/helpers/LocalizationHelper";

interface IConstructionSiteEquipmentMapProps {
    constructionSiteId: string;
}

interface IConstructionSiteEquipmentMapData {
}

interface IConstructionSiteEquipmentMapState extends IBaseAsyncComponentState<IConstructionSiteEquipmentMapData> {
    isLoading: boolean;
    editMode: boolean;
    constructionSiteLocation: GeoLocation | null;
    selectedSiteLocation: GeoLocation | null;
    products: TrackUnitBaseModel[];
    markers: IGoogleMapMarker[];
    startLongitude: number,
    startLatitude: number,
    data: IConstructionSiteEquipmentMapData | null;
    locationValue: string;
}

interface IConstructionSiteTrackingDevicesResponse {
    constructionSiteLocation: GeoLocation;
    machines: TrackUnitBaseModel[];
}

export default class ConstructionSiteEquipmentMap extends BaseAsyncComponent<IConstructionSiteEquipmentMapProps, IConstructionSiteEquipmentMapState, {}> {

    public state: IConstructionSiteEquipmentMapState = {
        isLoading: true,
        editMode: false,
        constructionSiteLocation: null,
        selectedSiteLocation: null,
        products: [],
        markers: [],
        startLongitude: RentaEasyConstants.defaultLongitude,
        startLatitude: RentaEasyConstants.defaultLatitude,
        data: null,
        locationValue: "",
    };

    private get constructionSiteMapInitialCenter(): google.maps.LatLngLiteral {
        if (this.state.constructionSiteLocation) {
            return {
                lat: this.state.constructionSiteLocation.lat,
                lng: this.state.constructionSiteLocation.lon,
            };
        }

        if (this.state.selectedSiteLocation) {
            return {
                lat: this.state.selectedSiteLocation.lat,
                lng: this.state.selectedSiteLocation.lon
            };
        }

        return {
            lat: RentaEasyConstants.defaultLatitude,
            lng: RentaEasyConstants.defaultLongitude,
        };
    }

    private get siteLocationMarker(): IGoogleMapMarker | null {
        if (this.constructionSiteLocation) {
            const icon: string = `/images/worksite-icon-green-white-bg.svg`;
            const iconImg = document.createElement('img');
            iconImg.src = icon;

            const position: google.maps.LatLngLiteral = {
                lat: this.constructionSiteLocation.lat,
                lng: this.constructionSiteLocation.lon
            };

            return {
                content: iconImg,
                position,
            };

        }
        return null;

    }

    private get products(): TrackUnitBaseModel[] {
        return this.state.products;
    }

    private get selectedSiteLocationMarker(): IGoogleMapMarker | null {
        if (!this.state.selectedSiteLocation) {
            return null;
        }

        const icon: string = `/images/worksite-icon-green-white-bg.svg`;
        const iconImg = document.createElement('img');
        iconImg.src = icon;

        const position: google.maps.LatLngLiteral = {
            lat: this.state.selectedSiteLocation.lat,
            lng: this.state.selectedSiteLocation.lon
        };

        return {
            content: iconImg,
            position,
        };
    }

    private get devicesAndSiteMarkers(): IGoogleMapMarker[] {
        if (this.products.length === 0) {
            if (this.selectedSiteLocationMarker) {
                return [this.selectedSiteLocationMarker];
            }

            if (this.siteLocationMarker) {
                return [this.siteLocationMarker];
            }

            return [];
        }

        const markers: IGoogleMapMarker[] = this.products
            .filter(p => p.productName)
            .map(
                product => {
                    const position: google.maps.LatLngLiteral = {
                        lat: product.location!.latitude,
                        lng: product.location!.longitude
                    };

                    let content: string = `<b>${product.productName}</b>`;

                    if (product.rentaId) {
                        content += ` (${product.rentaId})`;
                    }

                    if (product.orderedBy) {
                        content += `<br/><b>${Localizer.constructionDetailsOrdered}</b>: ${product.orderedBy}`;
                    }

                    if (product.customerPhone) {
                        content += `<br/><b>${Localizer.genericPhoneNumber}</b>: ${product.customerPhone}`;
                    }

                    if (product.rentaId && product.serialNumber) {
                        const productUrl: string = ConstructionSiteEquipmentMap.getTrackUnitDetailsLink(product);
                        content += `<br/><a href="${productUrl}"">${Localizer.genericOpenDetails}</a>`;
                    }

                    const icon: string = `/images/machine-icon-orange-white-bg.svg`;
                    const iconImg = document.createElement('img');
                    iconImg.src = icon;

                    const infoWindow: IGoogleMapInfoWindow = {
                        content,
                        position,
                        pixelOffset: new google.maps.Size(0, -60),
                    };

                    const marker: IGoogleMapMarker = {
                        content: iconImg,
                        title: product.id,
                        position,
                        infoWindow,
                    };

                    return marker;
                });

        if (this.selectedSiteLocationMarker) {
            markers.push(this.selectedSiteLocationMarker);
        }

        if (this.siteLocationMarker) {
            markers.push(this.siteLocationMarker);
        }

        return markers;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get editMode(): boolean {
        return this.state.editMode;
    }

    public get constructionSiteLocation(): GeoLocation | null {
        return this.state.constructionSiteLocation;
    }

    private static getTrackUnitDetailsLink(product: TrackUnitBaseModel): string {
        return `/${PageDefinitions.productLocation.pageName}?trackingSerialNumber=${product.serialNumber}&rentalObjectId=${product.id}&rentaId=${product.rentaId}`;
    }

    private async setEditModeAsync(): Promise<void> {
        await this.setState({editMode: true, selectedSiteLocation: this.constructionSiteLocation});
        await this.reRenderAsync();
    }

    private async exitEditModeAsync(): Promise<void> {
        await this.setState({editMode: false});
        await this.reRenderAsync();

    }

    private async onPlaceSelectedAsync(place: GoogleApiResult): Promise<void> {
        if (place.formatted_address !== AddressHelper.removeLatLon(this.state.locationValue)) {

            const geoLocation: GeoLocation = AddressHelper.getLocationFromGeocodeResult(place);

            if (geoLocation.lat === 0 && geoLocation.lon === 0) {
                return;
            }

            await this.setState({
                selectedSiteLocation: geoLocation,
                locationValue: place.formatted_address ?? ""
            });
        }
    }

    private async saveSiteLocationAsync(): Promise<void> {

        if (this.state.selectedSiteLocation) {
            const request: SaveSiteLocationRequest = {
                location: this.state.selectedSiteLocation!,
                constructionSiteId: this.props.constructionSiteId
            };

            await this.postAsync("/api/ConstructionSites/SaveSiteLocation", request);
            await this.setState({constructionSiteLocation: this.state.selectedSiteLocation, editMode: false});
            await this.reRenderAsync();
        }
    }

    protected getEndpoint(): string {
        return "";
    }

    public async loadProductsAsync(): Promise<void> {
        if (!this.props.constructionSiteId) {
            return;
        }

        const response: IConstructionSiteTrackingDevicesResponse = await this.postAsync("/api/ConstructionSites/ListConstructionSiteMachinesLocations", this.props.constructionSiteId);

        if (!response) {
            return;
        }

        await this.setState({
            products: response.machines ?? [],
            constructionSiteLocation: response.constructionSiteLocation,
            isLoading: false,
        });
    }

    public async initializeAsync(): Promise<void> {
        await this.loadProductsAsync();
    }

    render(): React.ReactNode {
        return (
            <PageContainer className={styles.productMapPage}>
                <div>
                    <h4>
                        {
                            (this.constructionSiteLocation || this.editMode) && (
                                Localizer.constructionSiteDetailsEquipmentMapTitle
                            )
                        }
                        {
                            ((!this.constructionSiteLocation && !this.isLoading)) && (
                                Localizer.constructionSiteDetailsEquipmentMapEditTitle
                            )
                        }

                    </h4>
                </div>

                <>
                    {((this.constructionSiteLocation) && !this.editMode) && (
                        <div>
                            <div className={styles.siteLocation}>
                                {`${this.constructionSiteLocation.address}, ${this.constructionSiteLocation.city}, ${this.constructionSiteLocation.postalCode}, ${this.constructionSiteLocation.country}`}
                            </div>
                            <Button label={Localizer.serviceDefinitionsEdit}
                                    id={"location-edit"}
                                    className={styles.saveButton}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.setEditModeAsync()}
                            />
                        </div>
                    )}

                    {
                        (!this.constructionSiteLocation || this.editMode) &&
                        (
                            <>
                                <Autocomplete
                                    className={this.css(styles.textInput, "form-control")}
                                    onPlaceSelected={async (place: GoogleApiResult) => await this.onPlaceSelectedAsync(place)}
                                    options={{
                                        types: ["address"],
                                        fields: ["address_components", "formatted_address", "geometry"],
                                        componentRestrictions: {country: LocalizationHelper.currentCountryCode}
                                    }}
                                />
                                <div>
                                    <Button label={Localizer.genericSave}
                                            id={"location-save"}
                                            className={styles.saveButton}
                                            type={ButtonType.Orange}
                                            disabled={!this.state.selectedSiteLocation}
                                            onClick={async () => await this.saveSiteLocationAsync()}
                                    />
                                    {(this.editMode) && (
                                        <Button label={Localizer.formCancel}
                                                id={"location-edit-cancel"}
                                                className={styles.saveButton}
                                                type={ButtonType.Default}
                                                onClick={async () => await this.exitEditModeAsync()}
                                        />
                                    )}
                                </div>
                            </>
                        )
                    }
                    {
                        (!this.isLoading && AddressHelper.isGoogleApiRegistered) && (
                            <GoogleMap autoCloseInfoWindows
                                       height={500}
                                       initialCenter={this.constructionSiteMapInitialCenter}
                                       initialZoom={this.constructionSiteLocation ? 9 : 10}
                                       markers={this.devicesAndSiteMarkers}
                            />
                        )
                    }

                </>

            </PageContainer>
        );
    }
}